define('ember-analytics-collector/initializers/performance-tracking', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.initialize = initialize;

  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var EmberRouter = _ember['default'].Router;
  var service = _ember['default'].inject.service;

  function initialize() {
    EmberRouter.reopen({
      interactivityTracking: service('interactivity-tracking'),
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        get(this, 'interactivityTracking').trackWillTransition(get(this, 'url').split('?')[0]);
      },

      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        run.scheduleOnce('routerTransitions', this, function () {
          get(this, 'interactivityTracking').trackDidTransition(get(this, 'url').split('?')[0]);
        });
      }
    });
  }

  exports['default'] = {
    name: 'performance-tracking',
    initialize: initialize
  };
});