define('ember-data-table/components/ui-pagination-showing/component', ['exports', 'ember', 'ember-data-table/components/ui-pagination-showing/template'], function (exports, _ember, _emberDataTableComponentsUiPaginationShowingTemplate) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiPaginationShowingTemplate['default'],
    tagName: 'span',
    firstCount: computed('current', 'steps', {
      get: function get() {
        return (this.get('current') - 1) * this.get('steps');
      }
    }),
    begin: computed('firstCount', {
      get: function get() {
        return Math.max(this.get('firstCount') + 1, 1);
      }
    }),
    end: computed('firstCount', 'count', 'steps', {
      get: function get() {
        var pseudoEnd = this.get('firstCount') + this.get('steps');
        return Math.min(pseudoEnd, this.get('count'));
      }
    })
  });
});