define('ember-authority/mixins/authority-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    privileges: [],
    abilites: function abilites() {
      return this.get('session.user.abilities') || this.privileges;
    },
    beforeModel: function beforeModel(transition) {
      if (this.hasPrivilege() || this.isAccessible()) {
        return this._super(transition);
      }
      this.unauthorizedRoute(transition);
    },
    unauthorizedRoute: function unauthorizedRoute(transition) {
      _ember['default'].Logger.error('unauthorized route. Transitioning to root url');
      transition.abort();
      this.transitionTo(this.router.get('rootURL'));
    },
    hasPrivilege: function hasPrivilege() {
      var abilites = this.abilites();
      var privileges = this.privileges;

      var commonPrivileges = privileges.filter(function (value) {
        return abilites.indexOf(value) !== -1;
      });
      return !(_ember['default'].isPresent(privileges) && _ember['default'].isEmpty(commonPrivileges));
    },
    isAccessible: function isAccessible() {
      return false;
    }
  });
});