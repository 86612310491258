define('ember-form/components/ui-error-msg/component', ['exports', 'ember', 'ember-form/components/ui-error-msg/template'], function (exports, _ember, _emberFormComponentsUiErrorMsgTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiErrorMsgTemplate['default'],
    tagName: 'span',
    classNames: ['error'],
    error: _ember['default'].computed('errors.@each', {
      get: function get() {
        console.log('ERRORS :: ', this.get('errors'));
        return this.get('errors.firstObject');
      }
    })
  });
});