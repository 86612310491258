define('ember-features/mixins/feature-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    features: [],
    currentFeatures: function currentFeatures() {
      return this.get('session.account.features') || this.features;
    },
    beforeModel: function beforeModel(transition) {
      var features = this.currentFeatures();
      var commonFeatures = this.features.filter(function (value) {
        return features.indexOf(value) !== -1;
      });
      if (_ember['default'].isPresent(this.features) && _ember['default'].isEmpty(commonFeatures)) {
        this.featureNotAvailableRoute(transition);
      }
      return this._super(transition);
    },
    featureNotAvailableRoute: function featureNotAvailableRoute(transition) {
      transition.abort();
      this.transitionTo(this.router.get('rootURL'));
    }
  });
});