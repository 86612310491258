define('ember-data-table/components/ui-list-header-item/component', ['exports', 'ember', 'ember-data-table/components/ui-list-header-item/template'], function (exports, _ember, _emberDataTableComponentsUiListHeaderItemTemplate) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiListHeaderItemTemplate['default'],
    tagName: 'th',
    classNameBindings: ['sortType', 'isActiveColumn:active'],

    bindSort: (function () {
      var sort = this.get('sort'),
          component = this;

      if (sort) {
        this.$().bind('click', function () {
          component.toggleOrderBy();
        });
      }

      // Initial Sort
      if (this.get('sortType')) {
        this.send('sortBy');
      }
    }).on('didInsertElement'),

    unBindSort: (function () {
      this.$().unbind('click');
    }).on('willDestroyElement'),

    // Computed Properties
    isActiveColumn: computed('parentView.activeColumn', {
      get: function get() {
        return this.get('parentView.activeColumn') === this;
      }
    }),

    toggleOrderBy: function toggleOrderBy() {
      var sortType = this.get('sortType') || 'asc';
      this.set('sortType', sortType === 'asc' ? 'desc' : 'asc');
      this.send('sortBy');
    },

    // Actions
    actions: {
      sortBy: function sortBy() {
        this.get('parentView').send('onSort', this);
      }
    }
  });
});