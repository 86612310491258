define('ember-unified-navbar-360/services/360-marketplace-adapter', ['exports', 'ember-unified-navbar-360/constants/marketplace', 'ember-unified-navbar-360/services/360-base-marketplace-adapter'], function (exports, _emberUnifiedNavbar360ConstantsMarketplace, _emberUnifiedNavbar360Services360BaseMarketplaceAdapter) {
  'use strict';

  var get = Ember.get,
      getProperties = Ember.getProperties,
      set = Ember.set,
      service = Ember.inject.service,
      Promise = Ember.RSVP.Promise,
      later = Ember.run.later,
      Evented = Ember.Evented;
  var _window = window,
      MarketplaceManager = _window.MarketplaceManager;

  exports['default'] = _emberUnifiedNavbar360Services360BaseMarketplaceAdapter['default'].extend(Evented, {
    s360fdAdapter: service('360-fd-marketplace-adapter'),
    mkpApp: service('marketplace-app'),
    mkpDataAPI: service(window.currentProduct === 'freshsales' ? 'marketplace-data' : '360-marketplace-data'),
    mkpInterfaceAPI: service(window.currentProduct === 'freshsales' ? 'marketplace-interface' : '360-marketplace-interface'),
    isMarketplaceScriptInitalized: false,
    loadGlobalApps: true,
    callInProgress: false,

    galleryMarketplaceManager: null,

    page: '',
    modelID: '',
    marketplaceManager: null,
    actionMap: _emberUnifiedNavbar360ConstantsMarketplace['default'].interfaceAttr.actionElementMap,

    init: function init() {
      this._super.apply(this, arguments);
      this.initializeMarketplace();
    },
    initializeMarketplace: function initializeMarketplace() {
      var _this = this;

      var mkpConfigData = this.get('mkpApp').getMKPAuthConfig();

      var _getProperties = getProperties(mkpConfigData, 'mkp_auth_config', 'csrf_token', 'account_id'),
          mkpConfig = _getProperties['mkp_auth_config'],
          csrf_token = _getProperties.csrf_token,
          userId = _getProperties['account_id'];

      var _getProperties2 = getProperties(mkpConfig, 'mkp_api_key', 'mkp_auth_token', 'mkp_region', 'product'),
          apiKey = _getProperties2.mkp_api_key,
          authToken = _getProperties2.mkp_auth_token,
          region = _getProperties2.mkp_region,
          product = _getProperties2.product;

      var mkpAdapter360 = Object.assign(this.getDefaultMkpConfig(), {
        csrfToken: csrf_token,
        apiKey: apiKey,
        authToken: authToken,
        region: region,
        product: product,

        productContext: this.get('mkpDataAPI').getProduct(),
        galleryURL: window.location.origin + 'crm/sales/integrations/applications',
        page: get(this, 'page'),
        modelID: get(this, 'modelID'),
        locations: _emberUnifiedNavbar360ConstantsMarketplace['default'].LOCATIONS,
        accountID: userId,
        interceptableEvents: _emberUnifiedNavbar360ConstantsMarketplace['default'].INTERCEPTABLE_EVENTS,
        events: _emberUnifiedNavbar360ConstantsMarketplace['default'].EVENTS,

        /**
         * getAttribute - trigger the getData method in marketplace-data service and returns a resolved / rejected promise
         */
        getAttribute: function getAttribute(attribute) {
          var response = get(_this, 'mkpDataAPI').getData(attribute, get(_this, 'page'), get(_this, 'modelID'));
          if (response.error) {
            return Promise.reject({ message: response.error });
          }
          return Promise.resolve(response);
        },

        /**
         * interface- triggers the methods declared in marketplace-interface service and returns a resolved / rejected promise
         */
        'interface': function _interface(args) {
          var lnbProductMarketplaceInterface = get(_this, 'lnbProductMarketplaceInterface');
          if (lnbProductMarketplaceInterface && lnbProductMarketplaceInterface.shouldFallbackToLnbInterface(args)) {
            return lnbProductMarketplaceInterface[args.method](args.options, args.instance);
          }
          return _this.handleInterface(args, get(_this, 'actionMap'));
        }
      });

      set(mkpAdapter360, 'bundleID', get(mkpConfig, 'bundle_id'));

      try {
        var marketplaceManager = new MarketplaceManager(mkpAdapter360);
        set(this, 'marketplaceManager', marketplaceManager);
        set(this, 'isMarketplaceScriptInitalized', true);
      } catch (e) {
        console.log('Failed to initialise marketplace script!', e.stack);
        set(this, 'isMarketplaceScriptInitalized', false);
      }

      set(this, 'lnbProductMarketplaceInterface', Ember.getOwner(this).lookup('service:lnb-product-marketplace-interface'));
    },
    initializeGalleryAdapter: function initializeGalleryAdapter(isCreate) {
      if (isCreate) {
        var currentRef = this;
        var flashMessages = get(this, 'flashMessages');
        var galleryAdapter = {
          eventHandler: function eventHandler() {
            var url = '/settings/integrations/marketplace_apps/clear_cache';
            currentRef.get('customStore').deleteRequest({
              url: url
            }).then(function (response) {
              if (response.success) {
                // To reinitialize the marketplace manager adapter, setting loadGlobalApps to false and again setting back to true
                set(currentRef, 'loadGlobalApps', false);
                currentRef.trigger('reloadGlobalApps');
                later(currentRef, function () {
                  set(currentRef, 'loadGlobalApps', true);
                  currentRef.trigger('reloadGlobalApps');
                  set(currentRef, 'galleryMarketplaceManager', null);
                }, 2);
              }
            }, function (error) {
              var msg = error.errors.message[0];
              flashMessages.danger(msg);
            });
          },

          iframeId: 'marketplaceGalleryIframe', // Id attribute of the Iframe element.
          type: 'galleryEventExchange'
        };
        var galleryMarketplaceManager = new MarketplaceManager(galleryAdapter);
        galleryMarketplaceManager.setupChannel();
        set(this, 'galleryMarketplaceManager', galleryMarketplaceManager);
      } else {
        set(this, 'galleryMarketplaceManager', null);
      }
    },

    /**
     * setAttribute An untily function which sets the attirbutes or all the marketplace code
     * @param attributeName
     * @param value
     */
    setAttribute: function setAttribute(attributeName, value) {
      set(this, attributeName, value);
    },

    /**
     * publishEvent - trigger the event api
     */
    publishEvent: function publishEvent(event) {
      if (event.type === 'calling') {
        var deskEvent = {
          type: 'cti.triggerDialer',
          data: { id: event.data.targetableId, number: event.data.phoneNumber }
        };
        this.get('s360fdAdapter').publishEvent(deskEvent);
      }
      return get(this, 'marketplaceManager').trigger(event);
    }
  });
});