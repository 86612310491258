define('ember-unified-navbar-360/services/360-marketplace-data', ['exports', 'ember-unified-navbar-360/constants/marketplace', 'ember-unified-navbar-360/services/360-base-marketplace-data'], function (exports, _emberUnifiedNavbar360ConstantsMarketplace, _emberUnifiedNavbar360Services360BaseMarketplaceData) {
  'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var get = Ember.get,
      assign = Ember.assign,
      isPresent = Ember.isPresent,
      service = Ember.inject.service;

  exports['default'] = _emberUnifiedNavbar360Services360BaseMarketplaceData['default'].extend({
    dataObjectsMap: _emberUnifiedNavbar360ConstantsMarketplace['default'].dataSources,
    globalDataAPIs: ['loggedInUser', 'domainName', 'currentProduct'],
    mkpApp: service('marketplace-app'),
    blockedAttributes: _emberUnifiedNavbar360ConstantsMarketplace['default'].blacklistedAttributes,
    additionalAttrs: {
      lead: [],
      contact: [],
      deal: [],
      'sales_account': []
    },

    /**
     * getLoggedInUser - returns the current user obj from session
     *
     * @return {object} currentUser object
     */
    getLoggedInUser: function getLoggedInUser() {
      var mkpConfig = this.get('mkpApp').getMKPAuthConfig();
      return get(mkpConfig, 'user_config');
    },

    /**
     * getCurrentProduct - returns the current product agent is active-in from window object
     *
     * @return {string} currentProduct
     */
    getCurrentProduct: function getCurrentProduct() {
      return window.currentProduct;
    },

    /**
     * getDomainName - returns the host name from window object
     *
     * @return {string} full_domain
     */
    getDomainName: function getDomainName() {
      return {
        'domainName': window.location.hostname,
        'productContext': this.getProduct()
      };
    },
    getEcommerce: function getEcommerce() {
      var mkpConfig = this.get('mkpApp').getMKPAuthConfig();
      return get(mkpConfig, 'onboarding_config');
    },

    /**
     * processRecord - converts the record to JSON if any
     *
     * @param  {object} record response object
     */
    processRecord: function processRecord(record) {
      return isPresent(record) ? record.toJSON() : null;
    },

    /**
     *The getAdditionalAttrs is used to get the Additional data for the given main data
     *
     * @param record
     * @param result
     * @param attribute
     * @returns
     */
    getAdditionalAttrs: function getAdditionalAttrs(record, result, attribute) {
      var additionalAttrs = this.additionalAttrs[attribute] || [];
      if (isPresent(record)) {
        for (var i = 0; i < additionalAttrs.length; i++) {
          var attr = additionalAttrs[i].camelize().capitalize();
          result[additionalAttrs[i]] = this['get' + attr](record);
        }
      }
      return result;
    },

    /**
     * getAPIResponse - fetches the entity data from the store and returns as object
     *
     * @param  {type} attribute entity info requested for
     * @param  {type} pageType  current route
     * @param  {type} id        entity id
     */
    getAPIResponse: function getAPIResponse(attribute, pageType, id) {
      var record = get(this, 'store').peekRecord(pageType, id);
      if (attribute !== pageType) {
        if (this.additionalAttrs[pageType].includes(attribute)) {
          return this['get' + attribute.capitalize()](record);
        } else {
          return;
        }
      }
      var result = { id: id };
      return assign({}, result, id ? { id: id } : {});
    },

    /**
     * getData - gets the data from freshsales and pass to external app
     *
     * @param  {type} attribute entity info requested for
     * @param  {type} pageType  current route
     * @param  {type} context   entity id
     * @return {object} returns the object with attribute as key
     */
    getData: function getData(attribute, pageType, context) {
      var result = {};
      if (this.globalDataAPIs.indexOf(attribute) !== -1) {
        result = this['get' + attribute.capitalize()]();
        if (attribute === 'domainName') {
          return result;
        }
      } else if (attribute === 'currentEntityInfo') {
        result = {
          currentEntityType: pageType,
          currentEntityId: Number(context)
        };
      } else {
        if (attribute === pageType) {
          result = { id: Number(context) };
        } else {
          // the below is added on purpose
          result = undefined;
        }
      }
      if (attribute === 'loggedInUser') {
        result = this.removeBlacklistedAttrs(result, attribute);
      }
      return _defineProperty({}, attribute, result);
    }
  });
});