define('ember-unified-navbar-360/mixins/recent-history', ['exports', 'ember-unified-navbar-360/utils/storage-keys'], function (exports, _emberUnifiedNavbar360UtilsStorageKeys) {
  'use strict';

  exports['default'] = Ember.Mixin.create({
    getRecentHistory: function getRecentHistory() {
      var recentHistory = (0, _emberUnifiedNavbar360UtilsStorageKeys.getLocalStorage)('launchpad.history');
      return recentHistory ? JSON.parse(recentHistory) : [];
    },
    setRecentHistory: function setRecentHistory(recentHistory, previousHistory, skipHistory) {

      if (previousHistory.link) {
        /* Remove duplicate history and push the last one to the top */
        var index = recentHistory.findIndex(function (rHistory) {
          return rHistory.link === previousHistory.link && (skipHistory || rHistory.type === previousHistory.type);
        });

        /* If duplicate entry found, remove that */
        if (index >= 0) {
          recentHistory.splice(index, 1);
        }

        if (!skipHistory) {
          var isDuplicateCommandExecuted = recentHistory[0] ? recentHistory[0].type === 'command_execution' && recentHistory[0].link === previousHistory.link : false;

          if (!isDuplicateCommandExecuted) {
            recentHistory.unshift(previousHistory);
          }
        }

        /* Only six history are needed */
        if (recentHistory.length > 6) {
          recentHistory.pop();
        }
        (0, _emberUnifiedNavbar360UtilsStorageKeys.setLocalStorage)('launchpad.history', JSON.stringify(recentHistory));
      }
    },
    capture: function capture() {
      var url = window.location.href.replace(window.location.origin, '');
      var previousHistory = {
        name: document.title + ' - ' + url,
        link: url,
        icon: 'link',
        type: 'route_change'
      };

      var recentHistory = this.getRecentHistory();
      this.setRecentHistory(recentHistory, previousHistory);
    }
  });
});