define('ember-unified-navbar-360/components/marketplace-list', ['exports', 'ember-unified-navbar-360/templates/components/marketplace-list', 'ember-unified-navbar-360/mixins/apps', 'ember-unified-navbar-360/constants/marketplace'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMarketplaceList, _emberUnifiedNavbar360MixinsApps, _emberUnifiedNavbar360ConstantsMarketplace) {
  'use strict';

  var service = Ember.inject.service,
      _get = Ember.get,
      oneWay = Ember.computed.oneWay,
      computed = Ember.computed,
      Component = Ember.Component,
      set = Ember.set,
      getProperties = Ember.getProperties;

  exports['default'] = Ember.Component.extend(_emberUnifiedNavbar360MixinsApps['default'], {
    layout: _emberUnifiedNavbar360TemplatesComponentsMarketplaceList['default'],
    tagName: '',

    mkpAdapter360: service(window.currentProduct === 'freshsales' ? 'marketplace-adapter' : '360-marketplace-adapter'),
    isMarketplaceScriptInitalized: oneWay('mkpAdapter360.isMarketplaceScriptInitalized'),

    /**
    *  isChatApp computed check if placeholder holds the value of chatApp which is left_nav_chat
    */
    isChatApp: computed('mkpPlaceholder', {
      get: function get() {
        return _emberUnifiedNavbar360ConstantsMarketplace['default'].chatApps.includes(_get(this, 'mkpPlaceholderPosition'));
      }
    }),

    /**
    *  isPhoneApp computed check if placeholder holds the value of chatApp which is left_nav_cti
    */
    isPhoneApp: computed('mkpPlaceholder', {
      get: function get() {
        return _emberUnifiedNavbar360ConstantsMarketplace['default'].phoneApps.includes(_get(this, 'mkpPlaceholderPosition'));
      }
    }),

    mkpPlaceholder: computed({
      get: function get() {
        return [_get(this, 'mkpPlaceholderPosition')];
      }
    }),

    mkpChatApp: computed('marketplaceApps.left_nav_chat.[]', {
      get: function get() {
        var data = (_get(this, 'marketplaceApps.left_nav_chat') || [])[0];
        return data;
      }
    }),

    mkpCTIApp: computed('marketplaceApps.left_nav_cti.[]', {
      get: function get() {
        var data = (_get(this, 'marketplaceApps.left_nav_cti') || [])[0];
        return data;
      }
    }),

    setCTIBooleanAsFalse: computed('isMarketplaceAppLoading', {
      get: function get() {
        this.setApp('mkpCTIApp', false);
      }
    }),

    setChatBooleanAsFalse: computed('isMarketplaceAppLoading', {
      get: function get() {
        this.setApp('mkpChatApp', false);
      }
    }),

    getToolTip: computed('mkpCTIApp', 'mkpChatApp', 'isPhoneApp', {
      get: function get() {
        var tooltip = void 0;

        var _getProperties = getProperties(this, 'isPhoneApp', 'mkpCTIApp', 'mkpChatApp'),
            isPhoneApp = _getProperties.isPhoneApp,
            mkpCTIApp = _getProperties.mkpCTIApp,
            mkpChatApp = _getProperties.mkpChatApp;

        if (isPhoneApp) {
          if (mkpCTIApp) {
            tooltip = mkpCTIApp.displayName;
          } else {
            tooltip = 'Phone';
          }
        } else {
          if (mkpChatApp) {
            tooltip = mkpChatApp.displayName;
          } else {
            tooltip = 'Chat';
          }
        }

        return tooltip;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.initMarketplaceApps();
      this.initMarketplaceGalleryEventListener();
    },

    /**
    * initMarketplaceGalleryEventListener - Listen for the gallery level changes(installation / uninstallation of apps) to notify global apps reload
    */
    initMarketplaceGalleryEventListener: function initMarketplaceGalleryEventListener() {
      var _this = this;

      _get(this, 'mkpAdapter360').on('reloadGlobalApps', function () {
        _this.updateMarketplaceApps();
      });
    },

    /**
    * updateMarketplaceApps - reload the marketplace apps by reseting the adapter and manager
    */
    updateMarketplaceApps: function updateMarketplaceApps() {
      if (!_get(this, 'mkpAdapter360.loadGlobalApps')) {
        set(_get(this, 'mkpAdapter360'), 'marketplaceManager', null);
        _get(this, 'mkpAdapter360').initializeMarketplace();
        this.resetMarketplaceApps();
      } else {
        this.initMarketplaceApps();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!_get(this, 'isMarketplaceScriptInitalized')) {
        var isPhoneApp = _get(this, 'isPhoneApp');
        isPhoneApp ? this.setApp('mkpCTIApp', false) : this.setApp('mkpChatApp', false);
        if (_get(this, 'flashMessages') && window.MarketplaceManager) {
          _get(this, 'flashMessages').danger('Marketplace Apps failed to load');
        }
      }
    }
  });
});