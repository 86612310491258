define('ember-unified-navbar-360/services/360-parent-modal-stack', ['exports', 'ember-unified-navbar-360/constants/freshchat-position'], function (exports, _emberUnifiedNavbar360ConstantsFreshchatPosition) {
  'use strict';

  var Service = Ember.Service,
      set = Ember.set,
      get = Ember.get,
      isPresent = Ember.isPresent;

  exports['default'] = Ember.Service.extend({
    isFilterSlideOpen: false,
    // Do not reset fchat and fcon button to initial position if there is a switch between fchat and fcon
    isFconFchatSwitch: false,
    isFconOpen: false,
    hasInjectedFreshchat: false,
    nativeWidgetState: null,
    marketplaceCTIWidgetActive: false,

    /**
     * moveFCWidget - Move the FC Widget based on the UI state of marketplaceCTIWidget
     */
    moveFCWidget: function moveFCWidget(state) {
      set(this, 'marketplaceCTIWidgetActive', state);
      var position = state ? _emberUnifiedNavbar360ConstantsFreshchatPosition['default'].shifted : _emberUnifiedNavbar360ConstantsFreshchatPosition['default']['default'];
      var _window = window,
          fcAgentWidget = _window.fcAgentWidget;

      if (fcAgentWidget && isPresent(get(this, 'nativeWidgetState'))) {
        fcAgentWidget.move({ 'leftOffset': position });
      }
    }
  });
});