define('ember-unified-navbar-360/utils/lnb-persistence-store-utils', ['exports'], function (exports) {
  'use strict';

  exports.updateResourceVersions = updateResourceVersions;
  exports.getResourceVersions = getResourceVersions;
  exports.getCachedData = getCachedData;
  var RSVP = Ember.RSVP,
      isPresent = Ember.isPresent,
      getProperties = Ember.getProperties;

  function updateResourceVersions(resourceInfo) {
    try {
      if (isPresent(resourceInfo)) {
        var resourceMap = getResourceVersions();

        var _getProperties = getProperties(resourceInfo, 'name', 'value'),
            name = _getProperties.name,
            value = _getProperties.value;

        resourceMap[name] = value;
        localStorage.setItem('lnb_data_resource_versions', JSON.stringify(resourceMap));
      }
    } catch (err) {
      console.error(err);
    }
  }

  function getResourceVersions() {
    var resourceVersions = void 0;

    try {
      resourceVersions = localStorage.getItem('lnb_data_resource_versions');

      resourceVersions = isPresent(resourceVersions) ? JSON.parse(resourceVersions) : {};
    } catch (err) {
      console.error(err);

      resourceVersions = {};
    }

    return resourceVersions;
  }

  function getCachedData(persistentStore, tableName) {
    return persistentStore.getAll(tableName);
  }
});