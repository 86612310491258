define('ember-data-table/components/ui-list-header/component', ['exports', 'ember', 'ember-data-table/components/ui-list-header/template'], function (exports, _ember, _emberDataTableComponentsUiListHeaderTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiListHeaderTemplate['default'],
    tagName: 'thead',
    activeColumn: null,
    resetSort: (function () {
      var children = this.get('childViews'),
          component = this;

      this.$(children).each(function (idx, elm) {
        if (component.get('activeColumn') !== elm) {
          elm.set('sortType', null);
        }
      });
    }).observes('activeColumn'),

    //Action Names
    onSort: 'onSort',

    actions: {
      onSort: function onSort(activeColumn) {
        this.set('activeColumn', activeColumn);

        // Sending to controller
        this.sendAction('onSort', {
          sort: this.get('activeColumn.sort'),
          sortType: this.get('activeColumn.sortType')
        });
      }
    }
  });
});