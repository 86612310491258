define('ember-cli-daterangepicker/components/date-range-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  var run = _ember['default'].run;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['form-group'],
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: undefined,
    end: undefined,
    minDate: undefined,
    maxDate: undefined,
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    datelimit: false,
    parentEl: 'body',

    format: 'MMM D, YYYY',
    serverFormat: 'YYYY-MM-DD',
    rangeText: computed('start', 'end', function () {
      var format = this.get('format');
      var serverFormat = this.get('serverFormat');
      var start = this.get('start');
      var end = this.get('end');
      if (!isEmpty(start) && !isEmpty(end)) {
        return (0, _moment['default'])(start, serverFormat).format(format) + this.get('separator') + (0, _moment['default'])(end, serverFormat).format(format);
      }
      return '';
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    buttonClasses: ['btn'],
    applyClass: null,
    cancelClass: null,
    ranges: {
      'Today': [(0, _moment['default'])(), (0, _moment['default'])()],
      'Yesterday': [(0, _moment['default'])().subtract(1, 'days'), (0, _moment['default'])().subtract(1, 'days')],
      'Last 7 Days': [(0, _moment['default'])().subtract(6, 'days'), (0, _moment['default'])()],
      'Last 30 Days': [(0, _moment['default'])().subtract(29, 'days'), (0, _moment['default'])()],
      'This Month': [(0, _moment['default'])().startOf('month'), (0, _moment['default'])().endOf('month')],
      'Last Month': [(0, _moment['default'])().subtract(1, 'month').startOf('month'), (0, _moment['default'])().subtract(1, 'month').endOf('month')]
    },
    daysOfWeek: _moment['default'].weekdaysMin(),
    monthNames: _moment['default'].monthsShort(),
    removeDropdownOnDestroy: false,
    cancelLabel: 'Cancel',
    applyLabel: 'Apply',
    customRangeLabel: 'Custom Range',
    fromLabel: 'From',
    toLabel: 'To',
    hideAction: null,
    applyAction: null,
    cancelAction: null,
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: false,
    firstDay: 0,

    // Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupPicker();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.setupPicker();
    },

    getOptions: function getOptions() {
      var momentStartDate = (0, _moment['default'])(this.get('start'), this.get('serverFormat'));
      var momentEndDate = (0, _moment['default'])(this.get('end'), this.get('serverFormat'));
      var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      var endDate = momentEndDate.isValid() ? momentEndDate : undefined;

      var momentMinDate = (0, _moment['default'])(this.get('minDate'), this.get('serverFormat'));
      var momentMaxDate = (0, _moment['default'])(this.get('maxDate'), this.get('serverFormat'));
      var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;

      var options = {
        alwaysShowCalendars: this.get('alwaysShowCalendars'),
        autoUpdateInput: this.get('autoUpdateInput'),
        autoApply: this.get('autoApply'),
        locale: {
          applyLabel: this.get('applyLabel'),
          cancelLabel: this.get('cancelLabel'),
          customRangeLabel: this.get('customRangeLabel'),
          fromLabel: this.get('fromLabel'),
          toLabel: this.get('toLabel'),
          format: this.get('format'),
          firstDay: this.get('firstDay'),
          daysOfWeek: this.get('daysOfWeek'),
          monthNames: this.get('monthNames')
        },
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate,
        timePicker: this.get('timePicker'),
        buttonClasses: this.get('buttonClasses'),
        applyClass: this.get('applyClass'),
        cancelClass: this.get('cancelClass'),
        separator: this.get('separator'),
        singleDatePicker: this.get('singleDatePicker'),
        drops: this.get('drops'),
        opens: this.get('opens'),
        timePicker24Hour: this.get('timePicker24Hour'),
        timePickerSeconds: this.get('timePickerSeconds'),
        timePickerIncrement: this.get('timePickerIncrement'),
        showWeekNumbers: this.get('showWeekNumbers'),
        showDropdowns: this.get('showDropdowns'),
        linkedCalendars: this.get('linkedCalendars'),
        dateLimit: this.get('dateLimit'),
        parentEl: this.get('parentEl')
      };

      if (!this.get('singleDatePicker')) {
        options.ranges = this.get('ranges');
      }

      return options;
    },

    setupPicker: function setupPicker() {
      run.cancel(this._setupTimer);
      this._setupTimer = run.scheduleOnce('afterRender', this, this._setupPicker);
    },

    _setupPicker: function _setupPicker() {
      this.$('.daterangepicker-input').daterangepicker(this.getOptions());
      this.attachPickerEvents();
    },

    attachPickerEvents: function attachPickerEvents() {
      var _this = this;

      this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        var start = picker.startDate.format(_this.get('serverFormat'));
        var end = picker.endDate.format(_this.get('serverFormat'));
        var applyAction = _this.get('applyAction');

        if (applyAction) {
          _ember['default'].assert('applyAction for date-range-picker must be a function', typeof applyAction === 'function');
          _this.sendAction('applyAction', start, end);
        } else {
          _this.setProperties({
            start: start, end: end
          });
        }
      });

      this.$('.daterangepicker-input').on('hide.daterangepicker', function (ev, picker) {
        var start = picker.startDate.format(_this.get('serverFormat'));
        var end = picker.endDate.format(_this.get('serverFormat'));
        var hideAction = _this.get('hideAction');

        if (hideAction) {
          _ember['default'].assert('hideAction for date-range-picker must be a function', typeof hideAction === 'function');
          _this.sendAction('hideAction', start, end);
        } else {
          _this.setProperties({
            start: start, end: end
          });
        }
      });

      this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
        var cancelAction = _this.get('cancelAction');

        if (cancelAction) {
          _ember['default'].assert('cancelAction for date-range-picker must be a function', typeof cancelAction === 'function');
          _this.sendAction('cancelAction');
        } else {
          _this.setProperties({
            start: _this.get('start'),
            end: _this.get('end')
          });
        }
      });
    },

    // Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      run.cancel(this._setupTimer);

      if (this.get('removeDropdownOnDestroy')) {
        _ember['default'].$('.daterangepicker').remove();
      }
    }
  });
});