define("ember-unified-navbar-360/utils/difference-in-days", ["exports"], function (exports) {
  "use strict";

  exports["default"] = differenceInDays;

  function differenceInDays(date) {
    var parsedDate = new Date(date);
    var date1 = new Date();
    var date2 = parsedDate && Object.prototype.toString.call(parsedDate) === "[object Date]" && !isNaN(parsedDate) ? parsedDate : 0;
    var diffTime = date1 < date2 ? Math.abs(date2 - date1) : 0;
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 24hrs
    return diffDays;
  }
});