define('ember-unified-navbar-360/mixins/form', ['exports'], function (exports) {
  'use strict';

  var Mixin = Ember.Mixin,
      computed = Ember.computed,
      getWithDefault = Ember.getWithDefault,
      service = Ember.inject.service,
      get = Ember.get;

  exports['default'] = Mixin.create({
    // i18n: service('i18n'),
    mkpAdapter: service('360-marketplace-adapter'),

    modalData: computed.alias('modal.model.data'),

    saveLabel: computed('data.saveLabel', {
      get: function get() {
        return 'Ok'; // getWithDefault(this, 'data.saveLabel', get(this, 'i18n').t('common.ok'));
      }
    }),

    cancelLabel: computed('data.cancelLabel', {
      get: function get() {
        return 'Cancel'; // getWithDefault(this, 'data.cancelLabel', get(this, 'i18n').t('common.cancel'));
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.subscribeMarketplaceEvents();
    },
    willDestroyElement: function willDestroyElement() {
      this.unsubscribeMarketplaceEvents();
      this._super.apply(this, arguments);
    },

    /**
     * subscribeMarketplaceEvents - initiate the events for the iframe
     * Instance close will be triggered in Interface method of adapter
     */
    subscribeMarketplaceEvents: function subscribeMarketplaceEvents() {
      if (get(this, 'modalData.iFrame')) {
        get(this, 'mkpAdapter').on('closeInstance_' + get(this, 'modalData.iFrame').id, this._closePane.bind(this));
      }
    },

    /**
     * unsubscribeMarketplaceEvents - destroy the events on the iframe after the iframe is closed
     */
    unsubscribeMarketplaceEvents: function unsubscribeMarketplaceEvents() {
      if (get(this, 'modalData.iFrame')) {
        get(this, 'mkpAdapter').off('closeInstance_' + get(this, 'modalData.iFrame').id);
      }
    },

    /**
     * _closePane - cancels the modal and pass the action to close method
     */
    _closePane: function _closePane() {
      if (get(this, 'modalData.cancel')) {
        get(this, 'modalData.cancel')(get(this, 'data'), get(this, 'cancelLabel'));
      }
      this.send('close');
    },

    actions: {
      /**
       * cancel - calls the _closePane();
       */
      cancel: function cancel() {
        this._closePane();
      },

      /**
       * save - saves the modal and pass the action to close method
       */
      save: function save() {
        if (get(this, 'modalData.save')) {
          get(this, 'modalData.save')(get(this, 'modalData'), get(this, 'saveLabel'));
        }
        this.send('close');
      }
    }

  });
});