define("ember-unified-navbar-360/components/main-logo-workspace-expanded", ["exports", "ember-unified-navbar-360/templates/components/main-logo-workspace-expanded"], function (exports, _emberUnifiedNavbar360TemplatesComponentsMainLogoWorkspaceExpanded) {
  "use strict";

  var isPresent = Ember.isPresent;

  exports["default"] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMainLogoWorkspaceExpanded["default"],
    actions: {
      selectWorkspace: function selectWorkspace(id, e) {
        var submenu = Ember.$(e.target).closest(".unified360-submenus-dropdown")[0] || Ember.$(e.target).closest(".unified360-more-list-container")[0];
        if (submenu) {
          submenu.classList.add("unified360-hide-menu");
          Ember.run.later(function () {
            submenu.classList.remove("unified360-hide-menu");
          }, 200);
        }
        this.sendAction('changeWorkspace', id);
      }
    }
  });
});