define('ember-unified-navbar-360/services/s360-transformation', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Service.extend({
    sethighlightMenuValue: function sethighlightMenuValue(val) {
      Ember.set(this, 'highlightMenuValue', val);
    },
    gethighlightMenuValue: function gethighlightMenuValue() {
      return Ember.get(this, 'highlightMenuValue');
    },

    getAlias: function getAlias() {
      return window.location.pathname.split('/')[1];
    },
    ajaxReq: function ajaxReq(url, data) {
      /* jshint -W117 */
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        /* jshint +W117 */
        xhr.withCredentials = true;
        xhr.open('GET', url);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader('USER_AGENT', 'FreshMarketer_sales_360');
        xhr.responseType = 'json';
        if (data) {
          xhr.send(JSON.stringify(data));
        } else {
          xhr.send();
        }
        xhr.onload = function () {
          if (xhr.status === 200) {
            return resolve(xhr.response.left_nav_bar || xhr.response);
          } else {
            return reject(new Error('LEFT_NAV_API_ERROR'));
          }
        };
        xhr.onerror = function () {
          return reject(new Error('LEFT_NAV_ERROR'));
        };
      });
    },
    getBundleData: function getBundleData(domain, bundle, endpoint) {
      var url = this.buildUrl(domain, bundle, endpoint);
      return this.ajaxReq(url);
    },
    buildUrl: function buildUrl(domain, bundle, endpoint) {
      var _window$location = window.location,
          origin = _window$location.origin,
          protocol = _window$location.protocol;

      var leftnavOrigin = domain ? (protocol || 'https:') + '//' + domain : origin;
      if (bundle) {
        var alias = this.getAlias();
        leftnavOrigin = origin + '/' + alias + '/sales';
      }
      return leftnavOrigin + endpoint;
    }
  });
});