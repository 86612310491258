define("ember-unified-navbar-360/helpers/s360-eql", ["exports"], function (exports) {
  "use strict";

  exports.s360Eql = s360Eql;

  function s360Eql(params /* , hash*/) {
    return params[0] === params[1];
  }

  exports["default"] = Ember.Helper.helper(s360Eql);
});