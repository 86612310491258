define('ember-data-table/components/ui-list/component', ['exports', 'ember', 'ember-data-table/components/ui-list/template'], function (exports, _ember, _emberDataTableComponentsUiListTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiListTemplate['default'],
    tagName: 'table',
    classNames: ['table', 'table-hover'],

    actions: {
      updateModel: function updateModel(params) {
        console.log(params);
        this.sendAction();
      },
      bulkDelete: function bulkDelete() {}
    }
  });
});