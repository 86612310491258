define('ember-unified-navbar-360/services/360-base-marketplace-data', ['exports'], function (exports) {
  'use strict';

  var Service = Ember.Service,
      get = Ember.get,
      service = Ember.inject.service;

  exports['default'] = Service.extend({
    mkpApp: service('marketplace-app'),

    getProduct: function getProduct() {
      var mkpConfig = get(this, 'mkpApp').getMKPAuthConfig();

      return {
        url: 'https://' + window.location.hostname + '/crm',
        name: get(mkpConfig, 'mkp_auth_config.product')
      };
    },

    /**
     * removeBlacklistedAttrs - remove unwanted and secure data from response of entity details requested for
     *
     * @param  {type} data      response data of entity
     * @param  {type} attribute entity requested for
     */
    removeBlacklistedAttrs: function removeBlacklistedAttrs(data, attribute) {
      var blacklistAttrs = get(this, 'blockedAttributes')[attribute] || [];
      return blacklistAttrs.reduce(function (acc, key) {
        delete acc[key];
        return acc;
      }, data);
    }
  });
});