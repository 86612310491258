define("ember-unified-navbar-360/components/unified-sidenav", ["exports", "ember-unified-navbar-360/templates/components/unified-sidenav", "ember-unified-navbar-360/mixins/highlight-current-menu", "ember-unified-navbar-360/mixins/recent-history", "ember-unified-navbar-360/mixins/video-player", "ember-unified-navbar-360/utils/add-svgxml-to-dom", "ember-unified-navbar-360/mixins/apps", "ember-unified-navbar-360/constants/workspace-switcher"], function (exports, _emberUnifiedNavbar360TemplatesComponentsUnifiedSidenav, _emberUnifiedNavbar360MixinsHighlightCurrentMenu, _emberUnifiedNavbar360MixinsRecentHistory, _emberUnifiedNavbar360MixinsVideoPlayer, _emberUnifiedNavbar360UtilsAddSvgxmlToDom, _emberUnifiedNavbar360MixinsApps, _emberUnifiedNavbar360ConstantsWorkspaceSwitcher) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var getProperties = Ember.getProperties,
      get = Ember.get,
      computed = Ember.computed,
      Promise = Ember.RSVP.Promise,
      emberArray = Ember.A,
      jQuery = Ember.$,
      observer = Ember.observer;

  var configIconHeight = 44;
  var SKUIconHeight = 56;
  var activityTimerInterval = 60000;
  exports["default"] = Ember.Component.extend(_emberUnifiedNavbar360MixinsHighlightCurrentMenu["default"], _emberUnifiedNavbar360MixinsRecentHistory["default"], _emberUnifiedNavbar360MixinsVideoPlayer["default"], _emberUnifiedNavbar360MixinsApps["default"], {
    layout: _emberUnifiedNavbar360TemplatesComponentsUnifiedSidenav["default"],
    elementId: "unified360-sidenav-id",
    classNames: "unified360-sidenav",
    tagName: "nav",
    currentProduct: "",
    allNavValues: "", // all menus to render more menu options
    isBundleProduct: true, // true for bundle product,
    loading: true,
    s360Transformation: Ember.inject.service(),
    launchPad: Ember.inject.service(),
    mkpApp: Ember.inject.service("marketplace-app"),
    mkpInterface: Ember.inject.service('360-marketplace-interface'),
    videoPlayerService: Ember.inject.service("video-player"),
    persistentStore: Ember.inject.service('lnb-persistence-store'),
    fdBootstrap: Ember.inject.service('360-fd-bootstrap'),
    workspaceService: Ember.inject.service('workspace-switcher'),
    anchorProductDomain: "",
    callerModal: false,
    scriptInjected: false,
    chatScriptInjected: '',
    callerScriptInjected: '',
    omnibarScriptInjected: '',
    lazyLoadWidgets: false,
    freshchatContent: null,
    showVideoModal: false,
    mkpPlaceholder: ['full_page_app'],
    lastActivityDateTime: new Date(),
    triggerAgentHeartbeatAPI: true,
    timeoutTimer: null,
    isWorkspaceEnabled: null,
    newWorkspace: null,
    sideNavValuesForSwitcher: null,
    availableWorkspace: [],
    finalSideNavValuesExpanded: null,
    firstAvailableNodeURL: null,
    workspaceItems: computed('isWorkspaceEnabled', 'finalSideNavValuesExpanded', function () {
      var _getProperties = getProperties(this, 'isWorkspaceEnabled', 'finalSideNavValuesExpanded', 'allNavValues'),
          isWorkspaceEnabled = _getProperties.isWorkspaceEnabled,
          finalSideNavValuesExpanded = _getProperties.finalSideNavValuesExpanded,
          allNavValues = _getProperties.allNavValues;

      return isWorkspaceEnabled ? finalSideNavValuesExpanded : allNavValues;
    }),
    showSwitcher: computed('availableWorkspace', function () {
      var availableWorkspace = this.get('availableWorkspace');
      return availableWorkspace && availableWorkspace.length > 1;
    }),
    showMainLogo: computed('isWorkspaceEnabled', function () {
      var isWorkspaceEnabled = this.get('isWorkspaceEnabled');
      return Ember.isPresent(isWorkspaceEnabled);
    }),
    // using observer as we introducing side effect here
    workspaceServiceObserver: observer('workspaceService.currentWorkSpace', function () {
      if (Ember.isPresent(this.get("sideNavValuesForSwitcher"))) {
        this.set("finalSideNavValues", this.get("sideNavValuesForSwitcher"));
        this.send("displayMenus");
      }
    }),

    filterMenusBasedOnWorkspace: function filterMenusBasedOnWorkspace(menus, workspaceId) {
      var _this2 = this;

      var filteredMenus = [];

      menus.forEach(function (menu) {
        if (menu.workspaces.includes(workspaceId)) {
          var filteredSubMenus = [];
          if (menu.sub_menus) {
            filteredSubMenus = _this2.filterMenusBasedOnWorkspace(menu.sub_menus, workspaceId);
          }
          filteredMenus.push(Object.assign({}, menu, { sub_menus: filteredSubMenus }));
        }
      });

      return filteredMenus;
    },
    setFirstNodeUrls: function setFirstNodeUrls(menus, logo) {
      var findMenuByName = function findMenuByName(menuList, name) {
        return menuList.find(function (menu) {
          return menu.name.includes(name);
        });
      };
      var URLs = {};
      var DASHBOARD_MENU = findMenuByName(menus, _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.DASHBOARD_PAGE_NAME);
      Object.entries(_emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE_CONFIG).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            val = _ref2[1];

        if (logo && logo.workspaces.includes(Number(key))) {
          URLs["" + val.id] = logo.url;
        } else {
          URLs["" + val.id] = DASHBOARD_MENU.url;
        }
      });
      this.set('firstAvailableNodeURL', URLs);
    },
    renderMenus: function renderMenus(values, renderType) {
      var _this3 = this;

      // renderType to use previous icon select value
      // render menus based on screensize
      // only compute workspace data if it's false
      this.setupWorkspaceData(values);
      var allMenus = JSON.parse(JSON.stringify(values));
      if (get(this, 'isWorkspaceEnabled')) {
        allMenus.menus = this.filterMenusBasedOnWorkspace(allMenus.menus, _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE[get(this, 'selectedWorkspace').id]);
        this.setFirstNodeUrls(allMenus.menus, values.logo_object);
      }
      allMenus.menus.forEach(function (menu) {
        if (menu.name === 's360_fsa_launch_pad') {
          _this3.set('showLaunchPad', true);
          var shortcutKey = navigator.userAgent.indexOf('Mac OS X') !== -1 ? '⌘K' : 'Ctrl + K';
          menu.label = menu.label + "\xA0\xA0" + shortcutKey;
        }
      });
      // To show banner on top with full width of window, LNB container's top is set to height of the banner.
      // Conditional check for HTML element is required because, the API might execute in the 'init' flow also (before rendering).
      var sidenavContainer = document.getElementsByClassName("unified360-side-nav-container")[0];
      var widgetsContainer = document.querySelector('.unified360-menu-list-container + .unified360-widgets-container');
      var widgetsContainerHeight = void 0,
          heightToSubtract = void 0;
      if (widgetsContainer) {
        widgetsContainerHeight = widgetsContainer.offsetHeight;
        // Menu container height is calc(100% - 200), hence minimum 200px has to be subtracted
        heightToSubtract = widgetsContainerHeight + SKUIconHeight < 200 ? 200 : widgetsContainerHeight + SKUIconHeight;
      } else {
        var fsuccessConfigHeight = allMenus.freshsuccess_config && allMenus.freshsuccess_config.fsu_enabled ? configIconHeight : 0;
        var fdeskTicketsConfigHeight = allMenus.freshdesk_config && allMenus.freshdesk_config.enabled ? configIconHeight : 0;
        heightToSubtract = 200 + fsuccessConfigHeight + fdeskTicketsConfigHeight;
      }

      var getNavListHeight = window.innerHeight - (sidenavContainer ? sidenavContainer.offsetTop : 0) - heightToSubtract;
      var noOfMenusDisplayed = Math.floor(getNavListHeight / configIconHeight);
      var allMenusExpanded = JSON.parse(JSON.stringify(allMenus));
      this.set('finalSideNavValuesExpanded', allMenusExpanded);
      if (allMenus.menus && allMenus.menus.length) {
        if (noOfMenusDisplayed < allMenus.menus.length) {
          var moreMenus = {
            name: "more",
            label: "more",
            icon: "s360_ellipsis_horizontal",
            sub_menus: []
          };
          var splitMenuArray = noOfMenusDisplayed > 2 ? allMenus.menus.splice(noOfMenusDisplayed - 1) : allMenus.menus.splice(2);
          moreMenus.sub_menus = splitMenuArray;
          allMenus.menus.push(moreMenus);
          this.set("finalSideNavValues", allMenus);
          this.notifyPropertyChange("finalSideNavValues");
        } else if (noOfMenusDisplayed >= allMenus.menus.length) {
          this.set("finalSideNavValues", allMenus);
          this.notifyPropertyChange("finalSideNavValues");
        }
      }
      Ember.run.scheduleOnce("afterRender", this, function () {
        Ember.run.later(this, function () {
          this.addActiveClass(renderType ? this.get("s360Transformation").gethighlightMenuValue() : "");
          if (Ember.$(".s360-caller").hasClass("active")) {
            Ember.$(".s360-caller").addClass("active");
          }
          if (Ember.$(".s360-chat").hasClass("active")) {
            Ember.$(".s360-chat").addClass("active");
          }
        });
      });
    },
    addScriptDynamically: function addScriptDynamically(url, type) {
      var newScript = document.createElement("script");
      newScript.src = url;
      var self = this;
      newScript.addEventListener('load', function (event) {
        self.set(type + "ScriptInjected", 'INJECTED');
      });
      newScript.addEventListener('error', function (event) {
        self.set(type + "ScriptInjected", 'INJECTED');
      });
      var target = document.body;
      target.appendChild(newScript);
    },
    addCssDynamically: function addCssDynamically(url) {
      var link = document.createElement("link");
      link.href = url;
      link.type = "text/css";
      link.rel = "stylesheet";
      var target = document.head;
      target.appendChild(link);
    },
    injectWidgetScripts: function injectWidgetScripts() {
      this.set("scriptInjected", true);
      // inject chat and caller scripts only for bundle products
      if (this.get("isBundleProduct")) {
        // inject widgetScripts
        if (this.get("finalSideNavValues.freshchat_config") && this.get("finalSideNavValues.freshchat_config.host") && !this.get("finalSideNavValues.freshchat_config.fd_messaging_enabled")) {
          var url = this.get("finalSideNavValues.freshchat_config.host") + "/js/agent-widget.js";
          this.addScriptDynamically(url, 'chat');
          this.fcNotificationEvent();
        } else {
          this.set('chatScriptInjected', 'NOT_REQUIRED');
        }
        if (this.get("finalSideNavValues.freshcaller_config") && this.get("finalSideNavValues.freshcaller_config").assetUrl) {
          this.addScriptDynamically(this.get("finalSideNavValues.freshcaller_config").assetUrl, 'caller');
        } else {
          this.set('callerScriptInjected', 'NOT_REQUIRED');
        }
        // omnibar scripts
        var omnibarConfig = this.get("finalSideNavValues.freshid_config");
        if (omnibarConfig && omnibarConfig.disabled !== true) {
          this.set("finalSideNavValues.freshid_config.product", this.get("currentProduct"));
          this.addCssDynamically(omnibarConfig.assets_css);
          this.addScriptDynamically(omnibarConfig.assets_js, 'omnibar');
          var self = this;
          if (self.get("logoutFunc")) {
            window.freshworksOmnibar = {
              /**
               * During session state change, trying to attempt to relogin.
               */
              onSessionStateChanged: function callback() {
                self.logoutFunc();
              }
            };
          }
        } else {
          this.set('omnibarScriptInjected', 'NOT_REQUIRED');
        }
      }
    },
    fcNotificationEvent: function fcNotificationEvent() {
      var _this4 = this;

      if (window.fcAgentWidget) {
        window.fcAgentWidget.on("FS:UnreadNotification", function (data) {
          if (data.unreadCount > 0) {
            Ember.set(_this4, "freshchatContent", {
              content: data.unreadCount
            });
          }
        });
      } else {
        setTimeout(function () {
          _this4.fcNotificationEvent();
        }, 500);
      }
    },
    resetFcNotificationCount: function resetFcNotificationCount() {
      Ember.set(this, "freshchatContent", {
        content: null
      });
    },
    setupLaunchpad: function setupLaunchpad(sideNavValues) {
      var _this = this;
      var launchPad = this.get('launchPad');
      launchPad.injectCommandBar(sideNavValues, launchPadCallBack);

      /* This is the callback function that needs to passed to commandBar injection */
      function launchPadCallBack() {
        function launchpadComputedContext() {
          var user = _this.get('user');

          user['email_domain'] = user['email'].split('@')[1]; // add email domain to user obj

          var activation = _this.get('sideNavValues.account_config.account_confirmed');
          user['activation'] = activation ? 'activated' : 'not activated'; // add ac activation status

          var fieldsToDelete = ['email', 'from', 'reply_to']; // These prop should be deleted from user object. should not be exposed to command bar.
          fieldsToDelete.forEach(function (field) {
            delete user[field];
          });

          return user;
        }

        var user = launchpadComputedContext();
        var meta = _this.get('lnb_meta');
        var productName = _this.get('finalSideNavValues.product_name');
        var userId = user.id;
        var contextData = Object.assign({}, user, meta);
        var launchpadPromise = launchPad.boot("" + userId, JSON.parse(JSON.stringify(contextData)));
        launchpadPromise.then(function () {
          //sends out an event after boot is completed
          window.dispatchEvent(new CustomEvent("launchpadLoaded"));
          launchPad.addEventHandler(handler);
          launchPad.setup(productName);
          // Lazy load recent section
          launchPad.addRecords('recents', []);

          var recordAction = {
            text: 'Visit link',
            name: 'visit_link',
            template: {
              type: 'link',
              value: '{{record.link}}',
              operation: 'self', // open the page in the same tab
              icon: '{{record.icon}}'
            }
          };
          function routerFn() {
            /* This is a placeholder for router function, so that videos link can be configured as router links in commandbar */
          }

          launchPad.addRecords('recents', _this.getRecentHistory, { 'labelKey': 'name' });
          launchPad.addRecordAction('recents', recordAction);
          window.CommandBar.addRouter(routerFn);

          function pushEventsForAnalytics(analyticsProperties) {
            var heapId = analyticsProperties.heapId,
                appCuesId = analyticsProperties.appCuesId,
                properties = analyticsProperties.properties;

            if (window.heap) {
              window.heap.track(heapId, properties);
            }
            if (window.Appcues) {
              window.Appcues.track(appCuesId);
            }
          }

          function handler(eventName, eventData) {
            var analyticsProperties = void 0;
            switch (eventName) {
              case 'opened':
                analyticsProperties = {
                  heapId: 'launch-pad-opened',
                  appCuesId: 'freshsales_launch-pad-opened'
                };
                pushEventsForAnalytics(analyticsProperties);
                break;
              case 'closed':
                window.dispatchEvent(new CustomEvent('commandbar-closed'));
                break;
              case 'command_execution':
                window.dispatchEvent(new CustomEvent('commandbar-command-executed', {
                  detail: {
                    commandId: eventData.command
                  }
                }));
                if (eventData.commandType === 'link' && eventData.command !== 'visit_link') {
                  // if command url has wistia, open the video player
                  if (eventData.commandDetails.url.includes('fast.wistia.net')) {
                    var videoProperties = {
                      url: eventData.commandDetails.url,
                      title: eventData.commandText
                    };
                    _this.openVideoPlayerModal(videoProperties);
                  }

                  var recentHistory = _this.getRecentHistory();
                  var previousHistory = {
                    name: eventData.commandText,
                    link: eventData.commandDetails.url,
                    icon: eventData.commandDetails.icon,
                    type: 'command_execution'
                  };
                  _this.setRecentHistory(recentHistory, previousHistory, true);
                }
                analyticsProperties = {
                  heapId: 'launch-pad-command-exec',
                  appCuesId: 'freshsales_launch-pad-command-exec',
                  properties: {
                    commandText: eventData.commandText,
                    category: eventData.category,
                    inputText: eventData.inputText,
                    command: eventData.command,
                    url: eventData.commandDetails.url
                  }
                };
                pushEventsForAnalytics(analyticsProperties);
                break;
              case 'abandoned_search':
                analyticsProperties = {
                  heapId: 'launch-pad-deadend',
                  appCuesId: 'freshsales_launch-pad-deadend',
                  properties: {
                    inputText: eventData.inputText
                  }
                };
                pushEventsForAnalytics(analyticsProperties);
                break;
            }
          }
        });
      }
    },

    // updates the props to commandbar
    addContextToLaunchpad: function addContextToLaunchpad(data) {
      if (!data) return;
      for (var key in data) {
        window.CommandBar.addContext(key, data[key]);
      }
    },
    fetchBundleData: function fetchBundleData(endPoint) {
      return this.get("s360Transformation").getBundleData(this.get("anchorProductDomain"), this.get("isBundleProduct"), endPoint);
    },
    updateUserPropsToLaunchpad: function updateUserPropsToLaunchpad() {
      var _this5 = this;

      var ENDPOINT = '/launchpad/properties';
      this.fetchBundleData(ENDPOINT).then(function (_ref3) {
        var account = _ref3.account;

        _this5.addContextToLaunchpad(account);
      })["catch"](function (e) {});
    },
    updateEnrichmentDataToLaunchpad: function updateEnrichmentDataToLaunchpad() {
      var _this6 = this;

      var ENDPOINT = '/launchpad/enriched_properties';
      this.fetchBundleData(ENDPOINT).then(function (data) {
        _this6.addContextToLaunchpad(data);
      })["catch"](function (e) {});
    },
    updateWebActivityDataToLaunchpad: function updateWebActivityDataToLaunchpad() {
      var _this7 = this;

      var ENDPOINT = '/launchpad/web_activities';
      this.fetchBundleData(ENDPOINT).then(function (data) {
        _this7.addContextToLaunchpad(data);
      })["catch"](function (e) {});
    },
    updateDataToLaunchpad: function updateDataToLaunchpad() {
      this.updateUserPropsToLaunchpad();
      this.updateEnrichmentDataToLaunchpad();
      this.updateWebActivityDataToLaunchpad();
    },
    initiateWatchersForVideoPlayers: function initiateWatchersForVideoPlayers() {
      var _this8 = this;

      this.get('videoPlayerService').on('open-video-modal', function (videoProperties) {
        _this8.setProperties({
          'showVideoModal': true,
          videoProperties: videoProperties
        });
      });

      this.get('videoPlayerService').on('close-video-modal', function () {
        _this8.set('showVideoModal', false);
      });
    },
    initSpriteDOM: function initSpriteDOM() {
      var _getProperties2 = getProperties(this, 'finalSideNavValues.lnb_icon_file_version', 'finalSideNavValues.lnb_icon_asset_url'),
          redisDataVersion = _getProperties2['finalSideNavValues.lnb_icon_file_version'],
          lnbIconAssetUrl = _getProperties2['finalSideNavValues.lnb_icon_asset_url'];

      if (Ember.isPresent(redisDataVersion)) {
        (0, _emberUnifiedNavbar360UtilsAddSvgxmlToDom.addSvgxmlToDom)(get(this, 'persistentStore'), redisDataVersion, lnbIconAssetUrl);
      }
    },

    /**
     * loadMarketplaceDevApp - callback function called from initMarketplaceApps with
     * full_page_app as argument. If marketplace app is present, it appends the marketplace
     * app to allNavValues menu items and calls renderMenus function
     */
    loadMarketplaceDevApp: function loadMarketplaceDevApp(app) {
      var marketplaceApp = (app || [])[0];
      if (!marketplaceApp) return;

      var allMenus = get(this, 'allNavValues.menus');

      var _getProperties3 = getProperties(marketplaceApp, 'id', 'appInstance.versionId'),
          appId = _getProperties3.id,
          appVersionId = _getProperties3['appInstance.versionId'];

      var uniqueAppId = appId + "_" + appVersionId;
      if (allMenus.findBy('uniqueAppId', uniqueAppId)) return;

      var lnbMenuItem = {
        icon: 's360_full_page_app',
        icon_url: marketplaceApp.iconURL,
        label: marketplaceApp.displayName,
        name: marketplaceApp.displayName,
        product: 'freshsales',
        uniqueAppId: uniqueAppId,
        url: get(this, 'allNavValues.paths.freshsales') + "/apps/" + appId + "?dev=true",
        url_as_route: {
          params: appId,
          query_params: { dev: "true" },
          route_name: 'marketplace-full-page'
        }
      };

      var menus = [].concat(_toConsumableArray(allMenus), [lnbMenuItem]);
      Ember.set(this, 'allNavValues.menus', emberArray(menus));
      this.renderMenus(this.get("allNavValues"), true);
    },

    isLocalDevApp: computed(function () {
      if (typeof URLSearchParams === 'function') {
        var queryParams = new URLSearchParams(window.location.search);
        return queryParams && queryParams.get('dev') === 'true';
      }
    }),

    setupWorkspaceData: function setupWorkspaceData(lnbData) {
      var _lnbData$workspace_en = lnbData.workspace_enabled,
          is_workspace_enabled = _lnbData$workspace_en === undefined ? false : _lnbData$workspace_en,
          workspaces = lnbData.workspaces;

      this.set('isWorkspaceEnabled', is_workspace_enabled);
      if (is_workspace_enabled) {
        var wspace = this.get('workspaceService').getWorkSpace();
        var availableWorkspace = workspaces.map(function (id) {
          return _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE_CONFIG[id];
        });
        this.setProperties({
          availableWorkspace: availableWorkspace,
          selectedWorkspace: _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE_CONFIG[wspace]
        });
      }
    },

    // function to check if a user has moved a mouse or pressed a key
    handleUserActivity: function handleUserActivity() {
      Ember.set(this, 'lastActivityDateTime', new Date());
    },
    disableActivityCheck: function disableActivityCheck() {
      // In case of error in API, Disable the click and mousemove event listeners
      Ember.set(this, 'triggerAgentHeartbeatAPI', false);
      jQuery(window).off('click.userActivityClickHandler keydown.userActivityClickHandler');
      jQuery(window).off('mousemove.userActivityMouseMoveHandler');
    },
    checkActivity: function checkActivity() {
      var currentTime = new Date();

      var _getProperties4 = getProperties(this, 'lastActivityDateTime', 'isBundleProduct', 'triggerAgentHeartbeatAPI', 's360Transformation'),
          lastActivityDateTime = _getProperties4.lastActivityDateTime,
          isBundleProduct = _getProperties4.isBundleProduct,
          triggerAgentHeartbeatAPI = _getProperties4.triggerAgentHeartbeatAPI,
          s360Transformation = _getProperties4.s360Transformation;

      var diff = currentTime.getTime() - lastActivityDateTime.getTime();
      var _disableActivityCheck = this.disableActivityCheck.bind(this);

      if (isBundleProduct && triggerAgentHeartbeatAPI && diff <= activityTimerInterval) {
        // if the user is recently active, we will send a request to the server to notifiy the user is active
        var endPointUrl = s360Transformation.buildUrl(this.get('anchorProductDomain'), isBundleProduct, '/settings/agent_heartbeat');
        jQuery.ajax({
          type: 'PUT',
          url: endPointUrl,
          contentType: 'application/json; charset=UTF-8'
        }).then(function (resp) {
          if (resp && resp.status !== 200) {
            _disableActivityCheck();
          }
        }, function (err) {
          console.error(err);
          _disableActivityCheck();
        });
      }
      if (isBundleProduct && triggerAgentHeartbeatAPI) {
        var _checkActivity = this.checkActivity.bind(this);
        Ember.set(this, 'clickHandlerTimerFunc', Ember.run.later(_checkActivity, activityTimerInterval));
      } else {
        var clickHandlerTimerFunc = this.get('clickHandlerTimerFunc');
        if (Ember.isPresent(clickHandlerTimerFunc)) {
          Ember.run.cancel(clickHandlerTimerFunc);
        }
      }
    },
    setupWorkspaceSwitcher: function setupWorkspaceSwitcher(sideNavValues) {
      var _getProperties5 = getProperties(sideNavValues, 'workspace_enabled', 'workspaces'),
          workSpaceEnabled = _getProperties5['workspace_enabled'],
          workspaces = _getProperties5.workspaces;

      var workspaceService = get(this, 'workspaceService');
      workspaceService.setAvailableWorkSpaces(workspaces);
      if (workSpaceEnabled) {
        if (workspaces.length > 1) {
          workspaceService.setWorkSpaceOnRouteChange(workspaces[0]);
        } else if (workspaces.length === 1) {
          workspaceService.setWorkSpace(workspaces[0]);
        } else {
          workspaceService.setWorkSpace(_emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE.freshsales);
        }
      }
    },
    init: function init() {
      var _this9 = this;

      this._super.apply(this, arguments);
      var persistentStore = get(this, 'persistentStore');
      persistentStore.initialize();
      this._resizeHandler = function () {
        _this9.renderMenus(_this9.get("allNavValues"), true);
      };
      this._initWidgetScriptsHandler = function () {
        _this9.injectWidgetScripts();
      };
      window.addEventListener("resize", this._resizeHandler);
      window.addEventListener("lnbDimensionsChangedExternally", this._resizeHandler);
      window.addEventListener("initWidgetScripts", this._initWidgetScriptsHandler, { once: true });
      if (Ember.isPresent(this.get("sideNavValues"))) {
        this.setupWorkspaceSwitcher(this.get('sideNavValues'));
        this.setupSideNav(this.get('sideNavValues'));
      } else {
        this.get("s360Transformation").getBundleData(this.get("anchorProductDomain"), this.get("isBundleProduct"), '/left_nav_bar').then(function (res) {
          var sideNavValues = typeof res === "string" ? JSON.parse(res) : res;
          _this9.setupWorkspaceSwitcher(sideNavValues);
          _this9.setupSideNav(sideNavValues);
        });
      }
      window.currentProduct = this.get("currentProduct");
      this.set("doc", document);
      this.initiateWatchersForVideoPlayers();
      // eslint-disable-next-line no-constant-condition
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _checkActivity = this.checkActivity.bind(this);
      var _handleUserActivity = this.handleUserActivity.bind(this);

      Ember.set(this, 'clickHandlerTimerFunc', Ember.run.later(_checkActivity, activityTimerInterval));

      _handleUserActivity();
      jQuery(window).on('click.userActivityClickHandler keydown.userActivityClickHandler', _handleUserActivity);
      jQuery(window).on('mousemove.userActivityMouseMoveHandler', _handleUserActivity);
    },
    setupSideNav: function setupSideNav(sideNavValues) {
      this.get('mkpApp').setMKPAuthConfig(sideNavValues);
      var fdBootstrap = this.get('fdBootstrap');
      // In freshsales, in some places we are opening the fcAgentWidget.
      // If fdMessagingEnabled equals true we dont initialize fcAgentWidget, so it can't be opened.
      // for differentiating this usecase we are setting this flag to window object.
      window.fdMessagingEnabled = sideNavValues.freshchat_config.fd_messaging_enabled;

      if (fdBootstrap.canLoadDeskFeatures(sideNavValues.user_config)) {
        fdBootstrap.setupDeskApps(sideNavValues).then(function (deskApps) {
          if (deskApps && Array.isArray(deskApps)) {
            sideNavValues.menus = [].concat(_toConsumableArray(sideNavValues.menus), _toConsumableArray(deskApps));
          }
        })["catch"](function (error) {
          return console.error(error);
        })["finally"](this.handleSideNavData.bind(this, sideNavValues));
      } else {
        this.handleSideNavData(sideNavValues);
      }
    },
    handleSideNavData: function handleSideNavData(sideNavValues) {
      this.set('finalSideNavValues', sideNavValues);
      this.set('sideNavValuesForSwitcher', sideNavValues);
      this.send('displayMenus');
      this.set('user', sideNavValues.user_config);
      this.set('lnb_meta', sideNavValues.meta);
      if (this.get('showLaunchPad')) {
        this.setupLaunchpad(sideNavValues);
        this.updateDataToLaunchpad();
      }
      this.initSpriteDOM();
    },
    processSideNavValues: function processSideNavValues() {
      if (Ember.isPresent(this.get("sideNavValues"))) {
        this.set("finalSideNavValues", this.get("sideNavValues"));
        this.set("sideNavValuesForSwitcher", this.get("sideNavValues"));
        this.send("displayMenus");
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.processSideNavValues();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
    },
    isActiveCallGoing: function isActiveCallGoing() {
      return window.callInProgress || get(this, 'mkpInterface.isCTICallInProgress');
    },
    getFirstNodeURL: function getFirstNodeURL(id) {
      return get(this, 'firstAvailableNodeURL')[id];
    },

    actions: {
      displayMenus: function displayMenus() {
        this.set("allNavValues", this.get("finalSideNavValues"));
        this.get("mkpApp").setMKPAuthConfig(this.get("finalSideNavValues"));
        this.renderMenus(this.get("allNavValues"), true);
        this.set("loading", false);

        // load marketplaceapp only in Dev mode and render menus in callback function
        if (get(this, "isLocalDevApp")) {
          this.initMarketplaceApps(this.loadMarketplaceDevApp.bind(this));
        }

        if (!this.get("scriptInjected")) {
          if (this.get("lazyLoadWidgets")) {
            /* jshint -W117 */
            window.dispatchEvent(new CustomEvent("LeftNavLoaded"));
            /* jshint +W117 */
          } else {
              this.injectWidgetScripts();
            }
        }
      },
      showSubmenus: function showSubmenus(name, e) {
        if (name === "more") {
          var doc = this.get("doc");

          var checkedMenus = doc.querySelectorAll(".unified360-collapsible-toggle:checked");
          if (checkedMenus && checkedMenus.length > 0) {
            checkedMenus.forEach(function (element) {
              element.checked = false;
            });
          }

          // reset scroll
          var moreDiv = doc.getElementsByClassName("unified360-more-menu-list-container")[0];
          moreDiv.scrollTop = 0;

          var getActiveElement = moreDiv.getElementsByClassName("unified360-submenu-item active")[0];
          if (getActiveElement) {
            var labelElem = Ember.$(getActiveElement).closest(".unified360-collapsible-content-wrapper");
            window.setTimeout(function () {
              getActiveElement.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }, 800);
            if (labelElem.length) {
              var labelActive = labelElem[0].previousElementSibling;
              labelActive.classList.add("unified360-active-menu");
              labelActive.previousElementSibling.checked = true;
            }
          }
        } else {
          // rendering submenus based on screen size
          var element = e.target;
          var availableHeight = window.innerHeight - document.getElementsByClassName("unified360-side-nav-container")[0].offsetTop - element.offsetTop;
          var dropdownElement = element.getElementsByClassName("unified360-submenus-dropdown")[0];
          if (Ember.isPresent(dropdownElement)) {
            var dropdownHeight = dropdownElement.offsetHeight;
            if (availableHeight < dropdownHeight) {
              var extraHeight = dropdownHeight - availableHeight;
              dropdownElement.setAttribute("style", "position: relative");
              if (element.offsetTop - 20 > extraHeight) {
                dropdownElement.style.top = -(extraHeight + 10) + "px";
              } else {
                dropdownElement.style.top = -(element.offsetTop - 10) + "px";
                dropdownElement.style["max-height"] = window.innerHeight - 20 + "px";
                dropdownElement.style["overflow-y"] = "scroll";
              }
            }
          }
        }
      },
      callerModalAction: function callerModalAction(url) {
        this.set("changeUrl", url);
        this.set("callerModal", true);
      },
      cancelRedirect: function cancelRedirect() {
        this.setProperties({
          callerModal: false,
          newWorkspace: null
        });
      },
      proceedRedirect: function proceedRedirect() {
        var _getProperties6 = getProperties(this, 'newWorkspace', 'workspaceService', 'changeUrl'),
            newWorkspace = _getProperties6.newWorkspace,
            workspaceService = _getProperties6.workspaceService,
            changeUrl = _getProperties6.changeUrl;

        if (Ember.isPresent(newWorkspace)) {
          workspaceService.setWorkSpace(newWorkspace);
        }
        this.setProperties({
          callerModal: false,
          newWorkspace: null,
          loading: true
        });
        window.location.href = changeUrl;
      },
      closeSubmenu: function closeSubmenu(e) {
        var submenu = Ember.$(e.target).closest(".unified360-submenus-dropdown")[0] || Ember.$(e.target).closest(".unified360-more-list-container")[0];
        if (submenu) {
          submenu.classList.add("unified360-hide-menu");
          window.setTimeout(function () {
            submenu.classList.remove("unified360-hide-menu");
          }, 200);
        }
      },
      removeActiveMenuClass: function removeActiveMenuClass(e) {
        this.removeActiveClass("unified360-active-menu", e);
      },
      changeWorkspace: function changeWorkspace(id) {
        var _getProperties7 = getProperties(this, 'selectedWorkspace', 'workspaceService', 'showSwitcher'),
            selectedWorkspace = _getProperties7.selectedWorkspace,
            workspaceService = _getProperties7.workspaceService,
            showSwitcher = _getProperties7.showSwitcher;

        var firstNodeURL = this.getFirstNodeURL(id);
        if (showSwitcher) {
          if (selectedWorkspace.id === id) return;
          var newWorkspace = _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE[id];
          if (this.isActiveCallGoing()) {
            Ember.set(this, 'newWorkspace', newWorkspace);
            this.send('callerModalAction', firstNodeURL);
          } else {
            workspaceService.setWorkSpace(newWorkspace);
            Ember.set(this, 'loading', true);
            window.location.href = firstNodeURL;
          }
        } else {
          if (this.isActiveCallGoing()) {
            this.send('callerModalAction', firstNodeURL);
          } else {
            window.location.href = firstNodeURL;
          }
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener("resize", this._resizeHandler);
      window.removeEventListener("lnbDimensionsChangedExternally", this._resizeHandler);
      window.removeEventListener("initWidgetScripts", this._initWidgetScriptsHandler);
      var clickHandlerTimerFunc = this.get('clickHandlerTimerFunc');
      jQuery(window).off('click.userActivityClickHandler keydown.userActivityClickHandler');
      jQuery(window).off('mousemove.userActivityMouseMoveHandler');
      if (Ember.isPresent(clickHandlerTimerFunc)) {
        Ember.run.cancel(clickHandlerTimerFunc);
      }
    }
  });
});