define('ember-attachable/mixins/attachable', ['exports', 'ember', 'ember-data', 'ic-ajax', 'ember-attachable/lib/serializer-response'], function (exports, _ember, _emberData, _icAjax, _emberAttachableLibSerializerResponse) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    attachmentAs: null,
    saveWithAttachment: function saveWithAttachment() {
      var jqXHRHeaders = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      return this.createWithAttachment(jqXHRHeaders);
    },
    createWithAttachment: function createWithAttachment(jqXHRHeaders) {
      var adapter = undefined,
          attachmentKey = undefined,
          data = undefined,
          formData = undefined,
          promise = undefined,
          root = undefined,
          serializer = undefined,
          url = undefined,
          _this = this;
      adapter = this.store.adapterFor(this.constructor.modelName);
      serializer = this.store.serializerFor(this._modelName());
      attachmentKey = this.get('attachmentAs');
      data = _ember['default'].copy(this.serialize());

      // For each key set on `attachmentName` we add that key to `data` array
      // with its conrresponding BLOB file.
      _ember['default'].makeArray(attachmentKey).forEach(function (key) {
        data[key] = this.get(key);
      }, this);

      formData = new FormData();
      root = this._rootKey();
      Object.keys(data).forEach(function (key) {
        if (!_ember['default'].isNone(data[key])) {
          if (_ember['default'].isArray(data[key])) {
            return _this._recursiveArrayAppend(formData, "" + root + "[" + key + "]", data, key);
          } else if (Object.prototype.toString.call(data[key]) === '[object Object]') {
            return _this._recursiveObjectAppend(formData, "" + root + "[" + key + "]", data, key);
          } else {
            return formData.append("" + root + "[" + key + "]", data[key]);
          }
        }
      });

      if (this.get('isNew')) {
        url = adapter.buildURL(this._modelName(), this.get('id'), [], 'createRecord');
      } else {
        url = adapter.buildURL(this._modelName(), this.get('id'), [], 'updateRecord');
      }

      if (this._oldEmberData()) {
        this.adapterWillCommit();
      } else {
        this._internalModel.adapterWillCommit();
      }
      promise = (0, _icAjax.request)(url, {
        type: this._requestType(),
        data: formData,
        dataType: 'json',
        headers: adapter.get('headers'),
        processData: false,
        contentType: false,
        beforeSend: function beforeSend(jqXHR) {
          if (jqXHRHeaders) {
            for (var headerKey in jqXHRHeaders) {
              jqXHR.setRequestHeader(headerKey, jqXHRHeaders[headerKey]);
            }
          }
        },
        xhr: function xhr() {
          var xhr;
          xhr = _ember['default'].$.ajaxSettings.xhr();
          xhr.upload.onprogress = function (evt) {
            return _this.set('uploadProgress', evt.loaded / evt.total * 100);
          };
          return xhr;
        }
      });
      return this._commitWithAttachment(promise, adapter, serializer);
    },
    _recursiveArrayAppend: function _recursiveArrayAppend(formData, appendRoot, data, key) {
      for (var qey = 0; qey < data[key].length; qey++) {
        if (!_ember['default'].isNone(data[key][qey])) {
          if (Object.prototype.toString.call(data[key][qey]) === '[object Object]') {
            this._recursiveObjectAppend(formData, appendRoot + "[]", data[key], qey);
          } else if (_ember['default'].isArray(data[key][qey])) {
            this._recursiveArrayAppend(formData, appendRoot + "[]", data[key], qey);
          } else {
            formData.append(appendRoot + "[]", data[key][qey]);
          }
        }
      }
    },
    _recursiveObjectAppend: function _recursiveObjectAppend(formData, appendRoot, data, key) {
      for (var qey in data[key]) {
        if (!_ember['default'].isNone(data[key][qey])) {
          if (Object.prototype.toString.call(data[key][qey]) === '[object Object]') {
            this._recursiveObjectAppend(formData, appendRoot + "[" + qey + "]", data[key], qey);
          } else if (_ember['default'].isArray(data[key][qey])) {
            this._recursiveArrayAppend(formData, appendRoot + "[" + qey + "]", data[key], qey);
          } else {
            formData.append(appendRoot + "[" + qey + "]", data[key][qey]);
          }
        }
      }
    },
    _rootKey: function _rootKey() {
      return _ember['default'].String.underscore(_ember['default'].String.decamelize(this._modelName()));
    },
    _requestType: function _requestType() {
      if (this.get('isNew')) {
        return 'POST';
      } else {
        return 'PUT';
      }
    },
    _commitWithAttachment: function _commitWithAttachment(promise, adapter, serializer) {
      var operation, record, store, type, oldEmberData;
      oldEmberData = this._oldEmberData();
      store = this.store;
      record = this;
      type = record.constructor;
      operation = '';
      if (_ember['default'].get(record, 'isNew')) {
        operation = 'createRecord';
      } else if (_ember['default'].get(record, 'isDeleted')) {
        operation = 'deleteRecord';
      } else {
        operation = 'updateRecord';
      }
      return promise.then(function (adapterPayload) {
        if (oldEmberData) {
          var payload;
          payload = void 0;
          if (adapterPayload) {
            payload = serializer.extract(store, type, adapterPayload, _ember['default'].get(record, "id"), operation);
          } else {
            payload = adapterPayload;
          }
          store.didSaveRecord(record, payload);
        } else {
          store._adapterRun(function () {
            var payload, data;
            var internalModel = record._internalModel;
            if (adapterPayload) {
              payload = (0, _emberAttachableLibSerializerResponse.normalizeResponseHelper)(serializer, store, type, adapterPayload, _ember['default'].get(record, "id"), operation);
              if (payload.included) {
                store.push({ data: payload.included });
              }
              data = (0, _emberAttachableLibSerializerResponse.convertResourceObject)(payload.data);
            } else {
              data = adapterPayload;
            }
            store.didSaveRecord(internalModel, (0, _emberAttachableLibSerializerResponse._normalizeSerializerPayload)(internalModel.type, data));
          });
        }
        return record;
      }, function (reason) {
        var error;
        if (adapter.ajaxError) {
          error = adapter.ajaxError(reason.jqXHR);
          if (error instanceof _emberData['default'].InvalidError) {
            store.recordWasInvalid(record, error.errors);
          } else if (error.status === 422) {
            store.recordWasInvalid(record, error.responseJSON.errors);
          } else {
            store.recordWasError(record);
          }
        } else if (reason.jqXHR) {
          error = adapter.handleResponse(reason.jqXHR.status, record._parseResponseHeaders(reason.jqXHR.getAllResponseHeaders()), adapter.parseErrorResponse(reason.jqXHR.responseText));
          if (error instanceof _emberData['default'].InvalidError) {
            var stateToTransition = record.get('isNew') ? 'created.uncommitted' : 'updated.uncommitted';
            record.transitionTo(stateToTransition);
            var recordModel = record.adapterDidInvalidate ? record : record._internalModel;
            store.recordWasInvalid(recordModel, _emberData['default'].errorsArrayToHash(error.errors));
          }
        }
        throw reason;
      }, "Uploading file with attachment");
    },
    _parseResponseHeaders: function _parseResponseHeaders(headerStr) {
      var headers = {};
      if (!headerStr) {
        return headers;
      }

      var headerPairs = headerStr.split('\r\n');
      for (var i = 0; i < headerPairs.length; i++) {
        var headerPair = headerPairs[i];
        // Can't use split() here because it does the wrong thing
        // if the header value has the string ": " in it.
        var index = headerPair.indexOf(': ');
        if (index > 0) {
          var key = headerPair.substring(0, index);
          var val = headerPair.substring(index + 2);
          headers[key] = val;
        }
      }

      return headers;
    },
    _oldEmberData: function _oldEmberData() {
      return !_ember['default'].isNone(this.adapterWillCommit);
    },
    // Provide backwards compatible modelName implementation
    _modelName: function _modelName() {
      if (_ember['default'].isNone(this.constructor.typeKey)) {
        return this.constructor.modelName;
      } else {
        return this.constructor.typeKey;
      }
    }
  });
});