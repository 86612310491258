define('ember-unified-navbar-360/utils/lnb-application-config-utils', ['exports', 'ember-unified-navbar-360/utils/lnb-persistence-store-utils'], function (exports, _emberUnifiedNavbar360UtilsLnbPersistenceStoreUtils) {
  'use strict';

  exports.getApplicationConfigForRoute = getApplicationConfigForRoute;
  exports.getDataFromStore = getDataFromStore;
  exports.getDataViaAPI = getDataViaAPI;

  var get = Ember.get,
      Promise = Ember.RSVP.Promise,
      isPresent = Ember.isPresent;

  function getApplicationConfigForRoute(persistentStore, redisDataVersion, apiUrl, tableName) {
    var formRequest = void 0;
    return new Promise(function (resolve, reject) {
      try {
        var resourceVersionsMap = (0, _emberUnifiedNavbar360UtilsLnbPersistenceStoreUtils.getResourceVersions)();
        var indexedDBDataVersion = get(resourceVersionsMap, tableName);

        if (isPresent(indexedDBDataVersion) && isPresent(redisDataVersion) && redisDataVersion === indexedDBDataVersion) {
          formRequest = getDataFromStore(persistentStore, redisDataVersion, apiUrl, tableName);
        } else {
          formRequest = getDataViaAPI(persistentStore, redisDataVersion, apiUrl, tableName);
        }
        formRequest.then(function (data) {
          resolve(data);
        });
      } catch (e) {
        console.error(e, 'App Config data fetch error');
        reject(e);
      }
    });
  }

  function getDataFromStore(persistentStore, redisDataVersion, apiUrl, tableName) {
    return new Promise(function (resolve, reject) {
      try {
        var apiResponse = '';
        (0, _emberUnifiedNavbar360UtilsLnbPersistenceStoreUtils.getCachedData)(persistentStore, tableName).then(function (response) {
          if (isPresent(response) && isPresent(apiResponse = response.find(function (object) {
            return object.apiName === tableName;
          }))) {
            resolve(apiResponse.config);
          } else {
            var formRequest = getDataViaAPI(persistentStore, redisDataVersion, apiUrl, tableName);
            formRequest.then(function (data) {
              resolve(data);
            }, function (e) {
              reject(e);
            });
          }
        }, function (e) {
          console.error(e, 'App config data indexedDb fetch error');
          var formRequest = getDataViaAPI(persistentStore, redisDataVersion, apiUrl, tableName);
          formRequest.then(function (data) {
            resolve(data);
          }, function (e) {
            reject(e);
          });
        });
      } catch (e) {
        console.error(e, 'App Config data indexedDb processing error');
        getDataViaAPI(persistentStore, redisDataVersion, apiUrl, tableName).then(function (data) {
          resolve(data);
        }, function (e) {
          reject(e);
        });
      }
    });
  }

  function getDataViaAPI(persistentStore, redisDataVersion, apiUrl, tableName) {
    return new Promise(function (resolve, reject) {
      try {
        var loadXML = new XMLHttpRequest();
        loadXML.open('GET', apiUrl, true);
        loadXML.send();
        loadXML.onload = function () {
          var response = loadXML.responseText;
          persistentStore.addOrUpdate(tableName, {
            apiName: tableName,
            config: response
          }, {
            name: tableName,
            value: redisDataVersion
          });
          resolve(response);
        };
        loadXML.onerror = function (err) {
          reject(err);
        };
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
});