define('ember-form/mixins/focus-field', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var isEqual = _ember['default'].isEqual;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Mixin.create({
    becomeFocused: (function () {
      var _self = this;
      /*
       * Ember.run.later to ensure the field gets focussed after some time delay. Without this, the focus will work only
       * for the first time it is inserted into the DOM
       */
      run.later(function () {
        if (isEqual(_self.get('autofocus'), true)) {
          _self.$().focus();
        }
      }, 100);
    }).on('didInsertElement')
  });
});