define('ember-unified-navbar-360/components/more-item', ['exports', 'ember-unified-navbar-360/templates/components/more-item'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMoreItem) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMoreItem['default'],
    classNames: ['unified360-expanded-container'],
    actions: {
      closeSubmenudropdown: function closeSubmenudropdown(e) {
        this.closeSubmenu(e, 'expanded');
      },
      removeActiveMenuClass: function removeActiveMenuClass(e) {
        this.removeActiveMenuClass(e);
      },
      showTooltip: function showTooltip(e) {
        var element = e.target;
        var hintElement = element.getElementsByClassName('unified360-hint--right')[0];
        if (Ember.isPresent(hintElement)) {
          var postitionTop = element.offsetTop + (element.offsetHeight / 2 - 16) + 'px'; // 32(height of tooltip)/2 = 16
          hintElement.style.setProperty('--hover-top', postitionTop);
        }
      }
    }
  });
});