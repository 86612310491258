define('ember-analytics-collector/components/interactivity-beacon/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var ROUTE_END_MARK = 'PerformanceTracking:RouteInteractive';
  var HERO_UPDATE_START_MARK = 'PerformanceTracking:HeroUpdateStart';
  var HERO_UPDATE_END_MARK = 'PerformanceTracking:HeroUpdateEnd';
  var INTERACTIVE_LABEL = 'InteractiveNow';

  var Component = _ember['default'].Component;
  var assert = _ember['default'].assert;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  var performance = window.performance;

  exports['default'] = Component.extend({
    tagName: '',
    interactivityTracking: service(),
    routeInteractive: false,
    withinRoute: false,
    reportingName: null,
    init: function init() {
      this._super.apply(this, arguments);
      get(this, 'interactivityTracking').registerBeaconAddition();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (get(this, 'interactivityTracking.analyticsEnabled')) {
        run.schedule('afterRender', get(this, 'interactivityTracking'), 'resolveBeaconAddition');
        run.scheduleOnce('afterRender', this, this.reportInteractive);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (get(this, 'interactivityTracking.analyticsEnabled')) {
        /*
          The following check is to avoid marking hero update starts when
          beacons from prior views are getting queued for destruction.
          Hero updates are re-renders of the critical components that
          happen after the initial render of these components and the
          resultant metrics have been reported.
        */
        if (get(this, 'interactivityTracking.inRouteMeaningfulPaints') > 0) {
          performance.mark(HERO_UPDATE_START_MARK);
        }
      }
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      if (get(this, 'interactivityTracking.analyticsEnabled')) {
        /*
          The deregistration of a beacon (i.e. marking the expectation
          that a beacon will be removed) is done right away, while the
          marking of the actual removal of the beacon is deferred to
          the 'afterRender' queue in the run loop. If the beacon is marked
          for removal and immediately marked as removed in the same run
          loop, by the time the 'afterRender' queue executes, the number
          of beacons to be removed and the number of beacons removed will
          match. Each call of this hook will end up calling the service's
          reportMetricsIfPossible method and that method will check for
          the above resolution and proceed to send a metrics payload for
          each invocation. To avoid this, the beacons to be removed are
          counted fully during the current run queue, while each beacon is
          marked as removed right before the this.reportInteractive method
          (which calls the service's reportMetricsIfPossible method) is
          called in the 'afterRender' queue. This ensures that the service
          method detects the resolution only during the last beacon's call,
          thus ensuring that reporting is done only once.
        */
        get(this, 'interactivityTracking').registerBeaconRemoval();

        run.scheduleOnce('afterRender', this, function () {
          get(this, 'interactivityTracking').resolveBeaconRemoval();
          this.reportInteractive();
        });
      }
    },
    reportInteractive: function reportInteractive() {
      if (!get(this, 'interactivityTracking.analyticsEnabled')) {
        return;
      }

      var routeInteractive = get(this, 'routeInteractive');
      var reportingName = get(this, 'reportingName');
      var withinRoute = get(this, 'withinRoute');

      assert('\'routeInteractive\' : ' + routeInteractive + ' and \'reportingName\' : ' + reportingName + ' cannot be used together.', routeInteractive === false || reportingName === null);
      assert("Either 'routeInteractive' or 'reportingName' has to be used.", routeInteractive === true || reportingName != null);

      if (routeInteractive) {
        performance.mark(ROUTE_END_MARK);
        performance.mark(HERO_UPDATE_END_MARK);
        get(this, 'interactivityTracking').reportMetricsIfPossible();
      } else {
        performance.mark(INTERACTIVE_LABEL + ':' + reportingName);
        get(this, 'interactivityTracking').trackBeacon(reportingName, withinRoute);
      }
    }
  });
});