define('ember-unified-navbar-360/components/mkp-app-frame', ['exports', 'ember-unified-navbar-360/templates/components/mkp-app-frame'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMkpAppFrame) {
  'use strict';

  var Component = Ember.Component,
      get = Ember.get,
      setProperties = Ember.setProperties,
      isPresent = Ember.isPresent,
      computed = Ember.computed,
      getOwner = Ember.getOwner,
      service = Ember.inject.service;

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMkpAppFrame['default'],
    classNames: ['unified360-marketplace-app-wrapper'],

    mkpAdapter360: service('360-marketplace-adapter'),

    previousAttrs: {},

    contextData: computed.alias('pageContext'),

    // entity: computed({
    //   get() {
    //     let routeName = getOwner(this).lookup('controller:application').currentPath;
    //     return entityType(MarketplaceConstants.ROUTE_PAGE_TYPE_MAPPER[routeName]) ? entityType(MarketplaceConstants.ROUTE_PAGE_TYPE_MAPPER[routeName]).singularize() : null;
    //   }
    // }),

    init: function init() {
      this._super.apply(this, arguments);
      var adapter = get(this, 'mkpAdapter360');
      if (get(this, 'entity')) {
        adapter.setAttribute('page', get(this, 'entity'));
      }
      if (get(this, 'contextData')) {
        adapter.setAttribute('modelID', get(this, 'contextData'));
      }
    },

    /**
     * didUpdateAttrs - calls when entity and context changes
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var adapter = get(this, 'mkpAdapter360');
      var entity = get(this, 'entity');
      var contextData = get(this, 'contextData');
      var oldAttrs = get(this, 'previousAttrs');

      if (isPresent(oldAttrs) && (oldAttrs.entity !== entity || oldAttrs.contextData !== contextData)) {
        adapter.setAttribute('page', entity);
        adapter.setAttribute('modelID', contextData);
      }
      setProperties(oldAttrs, {
        entity: entity,
        contextData: contextData
      });
    },

    /**
     * willDestroyElement - Trigger derender event to the app Instance
     */
    willDestroyElement: function willDestroyElement() {
      var app = get(this, 'app');
      app.trigger({ type: 'app.derender' });
      this._super.apply(this, arguments);
    }
  });
});