define('ember-form/components/ui-radio-group/component', ['exports', 'ember', 'ember-form/components/ui-radio-group/template'], function (exports, _ember, _emberFormComponentsUiRadioGroupTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiRadioGroupTemplate['default'],
    init: function init() {
      if (this.get('parentView.forAttribute')) {
        this.set('elementId', this.get('parentView.forAttribute'));
      }
      if (!this.get('optionValuePath')) {
        this.set('optionValuePath', 'content.value');
      }
      if (!this.get('optionLabelPath')) {
        this.set('optionLabelPath', 'content.label');
      }

      this._super();
    },
    optionsClass: 'radio-group-item',
    tagName: 'Div',
    classNames: ['form-group']
  });
});