define('ember-unified-navbar-360/helpers/s360-check-func', ['exports'], function (exports) {
  'use strict';

  exports.s360CheckFunc = s360CheckFunc;

  function s360CheckFunc(params /* , hash*/) {
    return typeof params[0] === 'function';
  }

  exports['default'] = Ember.Helper.helper(s360CheckFunc);
});