define('ember-unified-navbar-360/constants/fd-marketplace', ['exports'], function (exports) {
  'use strict';

  var BLOCKED_ATTRIBUTES = {
    loggedInUser: ['autorefresh_user_hash', 'collision_user_hash']
  };

  exports.BLOCKED_ATTRIBUTES = BLOCKED_ATTRIBUTES;
  var ALLOWED_ABILITIES = ['manage_canned_responses', 'view_admin', 'publish_solution', 'edit_topic', 'edit_conversation', 'manage_supervisor_rules', 'manage_account', 'forward_ticket', 'view_contacts', 'edit_time_entries', 'edit_ticket_properties', 'manage_solutions', 'edit_note', 'reply_ticket', 'manage_users', 'view_forums', 'manage_contacts', 'manage_own_scenario_automation_rules', 'manage_scenario_automation_rules', 'delete_contact', 'manage_dispatch_rules', 'delete_ticket', 'manage_forums', 'view_time_entries', 'merge_or_split_ticket', 'view_reports', 'create_topic', 'view_solutions', 'delete_solution', 'delete_topic', 'export_tickets', 'edit_private_note', 'import_customers', 'export_customers', 'export_reports', 'create_solution_draft', 'view_social', 'manage_ticket_list_views', 'compose_email', 'manage_tags', 'manage_skills', 'manage_bots', 'manage_segments', 'manage_proactive_outreaches', 'edit_ticket_skill', 'manage_dashboard', 'view_analytics', 'create_tags', 'manage_freddy_answers', 'forums_admin_moderator', 'forums_admin_moderator', 'manage_ticket_templates', 'spam_ticket', 'manage_availability', 'manage_companies', 'delete_company'];

  exports.ALLOWED_ABILITIES = ALLOWED_ABILITIES;
  var INTERFACE_ACTIONS = {
    showNotify: {},
    showConfirm: {},
    showModal: {},
    showDialog: {},
    resize: {},
    closeInstance: {},
    click: ['ticket', 'contact'],
    hide: {},
    show: {},
    publishCallState: {}
  };

  exports.INTERFACE_ACTIONS = INTERFACE_ACTIONS;
  var HIDE_SHOW_ACTIONS = ['softphone', 'missedCall'];

  var ACTIONS_ELEMENT_MAP = {
    show: HIDE_SHOW_ACTIONS,
    hide: HIDE_SHOW_ACTIONS,
    click: ['ticket', 'contact']
  };
  exports.ACTIONS_ELEMENT_MAP = ACTIONS_ELEMENT_MAP;
});