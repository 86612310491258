define('ember-power-calendar/services/power-calendar', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Service.extend({
    date: null,

    // Methods
    getDate: function getDate() {
      return this.get('date') || new Date();
    }
  });
});