define('ember-data-table/mixins/pagination/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Mixin.create({
    queryParams: ['page', 'per_page'],
    page: 1,
    perPage: 25,
    per_page: alias('perPage'),

    // TODO-EMBER - Hack - move this to route and reevaluate the pagiation plugin
    onPageChange: (function () {
      _ember['default'].$('.viewport').scrollTop(0);
    }).observes('page'),

    totalCount: computed('model', {
      get: function get() {
        var model = this.get('model');
        var modelName = model.type.modelName;
        var modelMeta = this.store.metadataFor(modelName);
        return modelMeta.total;
      }
    }),

    totalPages: computed('totalCount', 'perPage', {
      get: function get() {
        return Math.ceil(this.get('totalCount') / this.get('perPage'));
      }
    })

  });
});