define('ember-unified-navbar-360/components/menu-item', ['exports', 'ember-unified-navbar-360/templates/components/menu-item'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMenuItem) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMenuItem['default'],
    actions: {
      closeSubmenudropdown: function closeSubmenudropdown(e) {
        this.closeSubmenu(e);
      }
    }
  });
});