define('ember-unified-navbar-360/mixins/video-player', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Mixin.create({

    videoPlayerService: Ember.inject.service('video-player'),

    invokeVideoPlayer: function invokeVideoPlayer(videoId) {
      window.CommandBar.execute(videoId);
    },
    openVideoPlayerModal: function openVideoPlayerModal(videoProperties) {
      this.get('videoPlayerService').openVideoModal(videoProperties);
    },
    closeVideoPlayerModal: function closeVideoPlayerModal() {
      this.get('videoPlayerService').closeVideoModal();
    }
  });
});