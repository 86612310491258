define("ember-unified-navbar-360/templates/components/link-identifier", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createAttrMorph(element4, 'data-s360product-info');
          morphs[2] = dom.createMorphAt(element4, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "itemClassNames", ["loc", [null, [2, 14], [2, 28]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-s360product-info", ["get", "linkIdentifier.product", ["loc", [null, [2, 55], [2, 77]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [3, 2], [3, 11]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 0
              },
              "end": {
                "line": 9,
                "column": 0
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("	");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n		");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n	");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createAttrMorph(element3, 'data-s360product-info');
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["get", "itemClassNames", ["loc", [null, [6, 14], [6, 28]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-s360product-info", ["get", "linkIdentifier.product", ["loc", [null, [6, 55], [6, 77]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [7, 2], [7, 11]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 0
                },
                "end": {
                  "line": 17,
                  "column": 0
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("	");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "javascript:void(0)");
              var el2 = dom.createTextNode(" \n		");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n	");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element2, 'class');
              morphs[1] = dom.createAttrMorph(element2, 'target');
              morphs[2] = dom.createAttrMorph(element2, 'data-s360product-info');
              morphs[3] = dom.createElementMorph(element2);
              morphs[4] = dom.createMorphAt(element2, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["get", "itemClassNames", ["loc", [null, [12, 9], [12, 23]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "target", ["get", "customTarget", ["loc", [null, [13, 10], [13, 22]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-s360product-info", ["get", "linkIdentifier.product", ["loc", [null, [14, 25], [14, 47]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["redirectToCallback", ["get", "linkIdentifier", ["loc", [null, [11, 31], [11, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 1], [11, 47]]], 0, 0], ["content", "yield", ["loc", [null, [15, 2], [15, 11]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 0
                  },
                  "end": {
                    "line": 25,
                    "column": 0
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "href", "javascript:void(0)");
                var el2 = dom.createTextNode(" \n    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n	");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element1, 'class');
                morphs[1] = dom.createAttrMorph(element1, 'target');
                morphs[2] = dom.createAttrMorph(element1, 'data-s360product-info');
                morphs[3] = dom.createElementMorph(element1);
                morphs[4] = dom.createMorphAt(element1, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["subexpr", "concat", [["get", "itemClassNames", ["loc", [null, [20, 19], [20, 33]]], 0, 0, 0, 0], " ", ["get", "pillClass", ["loc", [null, [20, 38], [20, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [20, 49]]], 0, 0], 0, 0, 0, 0], ["attribute", "target", ["get", "customTarget", ["loc", [null, [21, 13], [21, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-s360product-info", ["get", "linkIdentifier.product", ["loc", [null, [22, 28], [22, 50]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["callbackAction", ["get", "linkIdentifier.callback_name", ["loc", [null, [19, 30], [19, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 4], [19, 60]]], 0, 0], ["content", "yield", ["loc", [null, [23, 4], [23, 13]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 1
                    },
                    "end": {
                      "line": 30,
                      "column": 1
                    }
                  },
                  "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("	  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  var el2 = dom.createTextNode("\n			");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n	  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(6);
                  morphs[0] = dom.createAttrMorph(element0, 'href');
                  morphs[1] = dom.createAttrMorph(element0, 'class');
                  morphs[2] = dom.createAttrMorph(element0, 'target');
                  morphs[3] = dom.createAttrMorph(element0, 'data-s360product-info');
                  morphs[4] = dom.createElementMorph(element0);
                  morphs[5] = dom.createMorphAt(element0, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "href", ["get", "linkIdentifier.url", ["loc", [null, [27, 13], [27, 31]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "itemClassNames", ["loc", [null, [27, 42], [27, 56]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "target", ["get", "customTarget", ["loc", [null, [27, 68], [27, 80]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-s360product-info", ["get", "linkIdentifier.product", ["loc", [null, [27, 107], [27, 129]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["checkCaller", ["get", "linkIdentifier.product", ["loc", [null, [27, 155], [27, 177]]], 0, 0, 0, 0], ["get", "linkIdentifier.url", ["loc", [null, [27, 178], [27, 196]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 132], [27, 198]]], 0, 0], ["content", "yield", ["loc", [null, [28, 3], [28, 12]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 31,
                        "column": 2
                      },
                      "end": {
                        "line": 33,
                        "column": 2
                      }
                    },
                    "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("			");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "yield", ["loc", [null, [32, 3], [32, 12]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 1
                    },
                    "end": {
                      "line": 37,
                      "column": 1
                    }
                  },
                  "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "link-to", [], ["params", ["subexpr", "@mut", [["get", "queryParams", ["loc", [null, [31, 20], [31, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "itemClassNames", ["loc", [null, [31, 38], [31, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "current-when", ["subexpr", "@mut", [["get", "activeRoutes", ["loc", [null, [31, 66], [31, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "data-s360product-info", ["subexpr", "@mut", [["get", "linkIdentifier.product", ["loc", [null, [31, 101], [31, 123]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [31, 2], [33, 14]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 0
                  },
                  "end": {
                    "line": 38,
                    "column": 0
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "s360-not-eql", [["get", "currentProduct", ["loc", [null, [26, 21], [26, 35]]], 0, 0, 0, 0], ["get", "linkIdentifier.product", ["loc", [null, [26, 36], [26, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 7], [26, 59]]], 0, 0]], [], 0, 1, ["loc", [null, [26, 1], [37, 8]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 0
                },
                "end": {
                  "line": 38,
                  "column": 0
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "linkIdentifier.callback_name", ["loc", [null, [17, 10], [17, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [17, 0], [38, 0]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 0
              },
              "end": {
                "line": 38,
                "column": 0
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "s360-check-func", [["get", "linkIdentifier.callback", ["loc", [null, [9, 27], [9, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 10], [9, 51]]], 0, 0]], [], 0, 1, ["loc", [null, [9, 0], [38, 0]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "notALink", ["loc", [null, [5, 10], [5, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 0], [38, 0]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/link-identifier.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "linkIdentifier.disabled", ["loc", [null, [1, 6], [1, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [38, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});