define('ember-data-table/mixins/pagination/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    queryParams: {
      page: {
        refreshModel: true
      }
    }
  });
});