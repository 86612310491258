define('ember-unified-navbar-360/services/360-base-marketplace-adapter', ['exports', 'ember-unified-navbar-360/constants/marketplace'], function (exports, _emberUnifiedNavbar360ConstantsMarketplace) {
  'use strict';

  var Service = Ember.Service,
      get = Ember.get,
      Promise = Ember.RSVP.Promise,
      jQuery = Ember.$;

  exports['default'] = Service.extend({
    getDefaultMkpConfig: function getDefaultMkpConfig() {
      return {
        Promise: Promise,
        ajax: function ajax(options) {
          return new Promise(function (resolve, reject) {
            var request = jQuery.ajax(options);
            request.done(resolve);
            request.fail(reject);
          });
        },
        domain: window.location.origin,
        interceptTimeout: _emberUnifiedNavbar360ConstantsMarketplace['default'].INTERFACE_API_TIMEOUT,
        overlays: _emberUnifiedNavbar360ConstantsMarketplace['default'].OVERLAYS
      };
    },
    handleInterface: function handleInterface(args, actions) {
      if (!Object.prototype.hasOwnProperty.call(actions, args.method)) {
        return Promise.reject({ error: get(this, 'i18n').t('marketplace.errors.invalid_attribute') });
      }
      var response = get(this, 'mkpInterfaceAPI')[args.method](args.options, args.instance);
      if (response && typeof response.then === 'function') {
        return response;
      }
      if (response && response.error) {
        return Promise.reject({ message: response.error });
      }
      return Promise.resolve(response);
    },

    /**
     * getApps - get the list of apps from marketplaceManager
     *
     * @param  {object} filter holds the location of placeholder
     * @return {array} list of apps
     */
    getApps: function getApps(filter) {
      return this.get('marketplaceManager').getApps(filter);
    }
  });
});