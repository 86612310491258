define("ember-unified-navbar-360/templates/components/main-logo-workspace-expanded", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 10
              },
              "end": {
                "line": 36,
                "column": 10
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace-expanded.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "unified360-list-item-container");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "unified360-submenus-dropdown-item");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el5 = dom.createElement("svg");
            dom.setAttribute(el5, "class", "unified360-icon workspace-logo-expanded");
            dom.setAttribute(el5, "viewBox", "0 0 32 32");
            var el6 = dom.createTextNode("\n                      ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("use");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.setNamespace(null);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5, "class", "unified360-submenus-dropdown-item-label unified360-text-ellipsis m-l-8");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [1, 1]);
            var element6 = dom.childAt(element5, [1]);
            var element7 = dom.childAt(element6, [1, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element4, 'onClick');
            morphs[1] = dom.createAttrMorph(element5, 'aria-label');
            morphs[2] = dom.createAttrMorph(element5, 'title');
            morphs[3] = dom.createAttrMorph(element5, 'data-unified360-addon');
            morphs[4] = dom.createAttrMorph(element6, 'class');
            morphs[5] = dom.createAttrMorph(element7, 'xlink:href', 'http://www.w3.org/1999/xlink');
            morphs[6] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "onClick", ["subexpr", "action", ["selectWorkspace", ["get", "workspace.id", ["loc", [null, [23, 51], [23, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [23, 65]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["subexpr", "s360-totitlecase", [["get", "workspace.name", ["loc", [null, [25, 94], [25, 108]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [25, 110]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "s360-totitlecase", [["get", "workspace.name", ["loc", [null, [26, 43], [26, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [26, 59]]], 0, 0], 0, 0, 0, 0], ["attribute", "data-unified360-addon", ["get", "workspace.name", ["loc", [null, [26, 84], [26, 98]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["unified360-submenu-item unified360-pl-35 ", ["subexpr", "if", [["subexpr", "s360-eql", [["get", "workspace.id", ["loc", [null, [27, 86], [27, 98]]], 0, 0, 0, 0], ["get", "selectedWorkspace.id", ["loc", [null, [27, 99], [27, 119]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 76], [27, 120]]], 0, 0], "selected-workspace-expanded"], [], ["loc", [null, [27, 71], [27, 152]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "xlink:href", ["concat", ["#", ["get", "workspace.icon", ["loc", [null, [29, 42], [29, 56]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "s360-totitlecase", [["get", "workspace.name", ["loc", [null, [31, 124], [31, 138]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 105], [31, 140]]], 0, 0]],
          locals: ["workspace"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace-expanded.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "unified360-flex-container unified360-wspace-logo-list-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "class", "unified360-collapsible-toggle");
          dom.setAttribute(el3, "type", "checkbox");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3, "class", "unified360-collapsible-label unified360-list-item-container m-12");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "unified360-submenus-dropdown-item");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "unified360-submenu-item");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el6 = dom.createElement("svg");
          dom.setAttribute(el6, "class", "unified360-icon workspace-logo-expanded workspace-icon");
          dom.setAttribute(el6, "viewBox", "0 0 32 32");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("use");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.setNamespace(null);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "unified360-submenus-dropdown-item-label unified360-text-ellipsis");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el6 = dom.createElement("svg");
          dom.setAttribute(el6, "class", "unified360-icon unified360-arrow");
          dom.setAttribute(el6, "viewBox", "0 0 32 32");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("use");
          dom.setAttributeNS(el7, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_arrow");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.setNamespace(null);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "unified360-collapsible-content-wrapper");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4, "class", "unified360-flex-container unified360-collapsible-content");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1, 1]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element8, [3]);
          var element11 = dom.childAt(element10, [1]);
          var element12 = dom.childAt(element11, [1]);
          var element13 = dom.childAt(element12, [1, 1]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element9, 'id');
          morphs[1] = dom.createAttrMorph(element10, 'for');
          morphs[2] = dom.createAttrMorph(element11, 'aria-label');
          morphs[3] = dom.createAttrMorph(element11, 'title');
          morphs[4] = dom.createAttrMorph(element11, 'data-unified360-addon');
          morphs[5] = dom.createAttrMorph(element13, 'xlink:href', 'http://www.w3.org/1999/xlink');
          morphs[6] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element8, [5, 1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "id", ["concat", ["collapsible_selector_", ["get", "selectedWorkspace.name", ["loc", [null, [4, 40], [4, 62]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "for", ["concat", ["collapsible_selector_", ["get", "selectedWorkspace.name", ["loc", [null, [5, 41], [5, 63]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["subexpr", "s360-totitlecase", [["get", "selectedWorkspace.name", ["loc", [null, [6, 85], [6, 107]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [6, 109]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "s360-totitlecase", [["get", "selectedWorkspace.name", ["loc", [null, [7, 35], [7, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [7, 59]]], 0, 0], 0, 0, 0, 0], ["attribute", "data-unified360-addon", ["get", "selectedWorkspace.name", ["loc", [null, [7, 84], [7, 106]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "xlink:href", ["concat", ["#", ["get", "selectedWorkspace.icon", ["loc", [null, [10, 34], [10, 56]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "s360-totitlecase", [["get", "selectedWorkspace.name", ["loc", [null, [13, 14], [13, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 91], [13, 38]]], 0, 0], ["block", "each", [["get", "availableWorkspace", ["loc", [null, [22, 18], [22, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 10], [36, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 56,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace-expanded.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "unified360-flex-container unified360-wspace-logo-list-container pd-12");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "unified360-submenus-dropdown-item bg-initial");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "unified360-submenu-item");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el5 = dom.createElement("svg");
          dom.setAttribute(el5, "class", "unified360-icon workspace-logo-expanded  workspace-icon");
          dom.setAttribute(el5, "viewBox", "0 0 32 32");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("use");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.setNamespace(null);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "unified360-submenus-dropdown-item-label unified360-text-ellipsis");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [1, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'onClick');
          morphs[1] = dom.createAttrMorph(element1, 'aria-label');
          morphs[2] = dom.createAttrMorph(element1, 'title');
          morphs[3] = dom.createAttrMorph(element1, 'data-unified360-addon');
          morphs[4] = dom.createAttrMorph(element3, 'xlink:href', 'http://www.w3.org/1999/xlink');
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "onClick", ["subexpr", "action", ["selectWorkspace", ["get", "selectedWorkspace.id", ["loc", [null, [43, 43], [43, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [43, 65]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["subexpr", "s360-totitlecase", [["get", "selectedWorkspace.name", ["loc", [null, [44, 94], [44, 116]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [44, 118]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "s360-totitlecase", [["get", "selectedWorkspace.name", ["loc", [null, [45, 33], [45, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [45, 57]]], 0, 0], 0, 0, 0, 0], ["attribute", "data-unified360-addon", ["get", "selectedWorkspace.name", ["loc", [null, [45, 82], [45, 104]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "xlink:href", ["concat", ["#", ["get", "selectedWorkspace.icon", ["loc", [null, [48, 32], [48, 54]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "s360-totitlecase", [["get", "selectedWorkspace.name", ["loc", [null, [51, 12], [51, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 89], [51, 36]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace-expanded.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showSwitcher", ["loc", [null, [1, 6], [1, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [56, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});