define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "modules/ember-power-calendar/templates/components/power-calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "calendar", ["loc", [null, [6, 12], [6, 20]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 4], [6, 22]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 10,
                "column": 2
              }
            },
            "moduleName": "modules/ember-power-calendar/templates/components/power-calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["content", "calendar.nav", ["loc", [null, [8, 4], [8, 20]]], 0, 0, 0, 0], ["content", "calendar.days", ["loc", [null, [9, 4], [9, 21]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "modules/ember-power-calendar/templates/components/power-calendar.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [5, 8], [5, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 2], [10, 9]]]]],
        locals: ["calendar"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "modules/ember-power-calendar/templates/components/power-calendar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "with", [["subexpr", "assign", [["get", "publicAPI", ["loc", [null, [1, 16], [1, 25]]], 0, 0, 0, 0], ["subexpr", "hash", [], ["nav", ["subexpr", "component", [["get", "navComponent", ["loc", [null, [2, 17], [2, 29]]], 0, 0, 0, 0]], ["calendar", ["subexpr", "readonly", [["get", "publicAPI", ["loc", [null, [2, 49], [2, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 39], [2, 59]]], 0, 0], "onCenterChange", ["subexpr", "readonly", [["get", "onCenterChange", ["loc", [null, [2, 85], [2, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 75], [2, 100]]], 0, 0]], ["loc", [null, [2, 6], [2, 101]]], 0, 0], "days", ["subexpr", "component", [["get", "daysComponent", ["loc", [null, [3, 18], [3, 31]]], 0, 0, 0, 0]], ["calendar", ["subexpr", "readonly", [["get", "publicAPI", ["loc", [null, [3, 51], [3, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 41], [3, 61]]], 0, 0], "onSelect", ["subexpr", "readonly", [["get", "onSelect", ["loc", [null, [3, 81], [3, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 71], [3, 90]]], 0, 0]], ["loc", [null, [3, 7], [3, 91]]], 0, 0]], ["loc", [null, [1, 26], [4, 1]]], 0, 0]], [], ["loc", [null, [1, 8], [4, 2]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});