define('ember-telephone-keypad/components/keypad-component/component', ['exports', 'ember-telephone-keypad/components/keypad-component/template'], function (exports, _emberTelephoneKeypadComponentsKeypadComponentTemplate) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberTelephoneKeypadComponentsKeypadComponentTemplate['default'],
    classNames: ['keypad-component'],
    keys: Ember.computed({
      get: function get() {
        return {
          1: '',
          2: 'ABC',
          3: 'DEF',
          4: 'GHI',
          5: 'JKL',
          6: 'MNO',
          7: 'PQRS',
          8: 'TUV',
          9: 'WXYZ'
        };
      }
    }),
    focusDisplay: Ember.computed({
      get: function get() {},
      set: function set(key, value) {
        if (this.get('showDisplay') && value) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            this.$('input').focus();
          });
        }
        return value;
      }
    }),

    displayKeypressHandler: function displayKeypressHandler(event) {
      var pattern = this.get('usageIntention') === 'DIAL' ? /[\d*+#]/ : /[\d*#]/;

      event.preventDefault();

      if (pattern.test(event.key)) {
        this.send('onKeyPress', event.key);
      }
    },
    pasteHandler: function pasteHandler(event) {
      event.preventDefault();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('showDisplay')) {
        this.set('_displayKeypressHandler', this.displayKeypressHandler.bind(this));
        this.set('_pasteHandler', this.pasteHandler.bind(this));

        this.$('input').on('keypress', this._displayKeypressHandler).on('paste', this._pasteHandler);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('showDisplay')) {
        this.$('input').off('keypress', this._displayKeypressHandler).off('paste', this._pasteHandler);
      }
    },

    actions: {
      onKeyPress: function onKeyPress(key) {
        if (this.get('showDisplay')) {
          this.set('displayValue', (this.get('displayValue') || '') + key);
          this.$('input').focus();
        }

        if (this.get('targetDisplay')) {
          var targetDisplay = document.querySelector(this.get('targetDisplay'));
          targetDisplay.value = (targetDisplay.value || '') + key;

          if (this.get('targetEvent')) {
            targetDisplay.dispatchEvent(new Event(this.get('targetEvent'), { bubbles: true }));
          }

          targetDisplay.focus();
        }

        this.keyPressCallback(key);
      },
      onKeypadClose: function onKeypadClose() {
        this.keypadCloseCallback();
      }
    }
  });
});