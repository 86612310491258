define('ember-unified-navbar-360/components/main-logo-workspace', ['exports', 'ember-unified-navbar-360/templates/components/main-logo-workspace'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMainLogoWorkspace) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMainLogoWorkspace['default'],
    classNames: ['unified360-main-workspace-logo'],
    classNameBindings: ['conditionalClassName'],
    conditionalClassName: Ember.computed('showSwitcher', function () {
      var condition = this.get('showSwitcher');
      return condition ? 'unified360-main-workspace-logo show-switcher' : 'unified360-main-workspace-logo';
    }),

    actions: {
      onIconClick: function onIconClick(id) {
        if (this.get('showSwitcher')) return;
        this.send('selectWorkspace', id);
      },
      selectWorkspace: function selectWorkspace(id) {
        var submenusContainer = document.querySelector('.unified360-workspace-logo-submenus-container');
        if (submenusContainer) {
          submenusContainer.classList.add('hideWorkspaceSubMenu');
          Ember.run.later(function () {
            submenusContainer.classList.remove('hideWorkspaceSubMenu');
          }, 200);
        }
        this.sendAction('changeWorkspace', id);
      }
    }
  });
});