define('ember-emojipalette/components/emoji-palette/component', ['exports', 'ember-emojipalette/components/emoji-palette/template', 'ember-emojipalette/utils/emojidex', 'ember-emojipalette/utils/category-icons'], function (exports, _emberEmojipaletteComponentsEmojiPaletteTemplate, _emberEmojipaletteUtilsEmojidex, _emberEmojipaletteUtilsCategoryIcons) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberEmojipaletteComponentsEmojiPaletteTemplate['default'],
    closeOnEsc: true,
    closeOnBackdropClick: false,

    // Event Handlers
    handleKeyDown: function handleKeyDown(e) {
      var code = e.keyCode || e.which;
      if (code === 27 && this.get('closeOnEsc')) {
        this.get('onClose')();
      }
    },
    handleClick: function handleClick(e) {
      if (this.get('closeOnBackdropClick') && (e.target === this.element || !e.target.closest('.emojidex-palette-wrapper'))) {
        this.get('onClose')();
      }
    },

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.set('keyDownHandler', this.get('handleKeyDown').bind(this));
      this.set('clickHandler', this.get('handleClick').bind(this));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.later(function () {
        document.addEventListener('keydown', _this.get('keyDownHandler'));
        document.addEventListener('click', _this.get('clickHandler'));
      }, 1);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('keyDown', this.get('keyDownHandler'));
      document.removeEventListener('click', this.get('clickHandler'));
    },

    // Component Properties
    emojidex: _emberEmojipaletteUtilsEmojidex.emojidex,
    emojis: Ember.computed(function () {
      return this.get('emojiVersion') ? _emberEmojipaletteUtilsEmojidex.emojidex.filteredEmojiList(this.get('emojiVersion')) : _emberEmojipaletteUtilsEmojidex.emojidex.emojilist;
    }),
    categoryNames: _emberEmojipaletteUtilsEmojidex.emojidex.getCategoryNames(),
    categorySVG: _emberEmojipaletteUtilsCategoryIcons.categoryIcons,
    searchTerm: '',
    searchPlaceholder: 'search for emoji',

    // Computed Properties
    isCompleteList: Ember.computed.alias('hideCategories'),
    isSearchResult: Ember.computed.notEmpty('searchResults'),
    currentListType: Ember.computed(function () {
      return this.get('isCompleteList') ? 'all' : 'people';
    }),
    allowedCategories: Ember.computed(function () {
      var _this2 = this;

      var excludedCategories = this.get('excludedCategories');
      var categoryList = Object.keys(this.get('categoryNames'));
      return excludedCategories ? categoryList.filter(function (category) {
        return !_this2.excludedCategories.includes(category);
      }) : categoryList;
    }),
    emojiList: Ember.computed('searchResults', 'currentListType', function () {
      return this.get('searchEnabled') && this.get('isSearchResult') ? this.get('searchResults') : this.get('isCompleteList') ? this.get('emojis') : this.get('emojis')[this.get('currentListType')];
    }),

    // Actions
    actions: {
      searchEmojis: function searchEmojis() {
        var searchTerm = this.get('searchTerm');
        if (searchTerm.trim() === "") return;
        var searchResults = this.get('emojidex').searchEmojis(searchTerm);
        if (searchResults) {
          this.set('searchResults', searchResults);
        } else {
          this.set('searchResults', 'none');
        }
      },
      changeCategory: function changeCategory(category) {
        this.set('currentListType', category);
      },
      selectEmoji: function selectEmoji(emoji) {
        this.set('selectedEmoji', emoji);
      }
    }
  });
});