define('ember-toastr/services/toast', ['exports'], function (exports) {
  'use strict';

  var proxyGenerator = function proxyGenerator(name) {
    return function () {
      var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var toasts = this.get('toasts');
      var toast = void 0;
      if (window && window.toastr) {
        toast = window.toastr[name](msg.toString(), title.toString(), options);

        if (toast) {
          toasts.pushObject(toast);
        }
      }

      return toast;
    };
  };

  exports['default'] = Ember.Service.extend({
    success: proxyGenerator('success'),
    info: proxyGenerator('info'),
    warning: proxyGenerator('warning'),
    error: proxyGenerator('error'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.toasts = Ember.A([]);

      // Auto remove toasts when hidden
      if (window && window.toastr) {
        window.toastr.options.onHidden = Ember.run.bind(this, function () {
          var toasts = _this.get('toasts');
          var notVisible = toasts.filter(function (item) {
            return !item.is(':visible');
          });
          toasts.removeObjects(notVisible);
        });
      }
    },
    clear: function clear(toastElement) {
      if (window && window.toastr) {
        window.toastr.clear(toastElement);
        if (toastElement) {
          this.get('toasts').removeObject(toastElement);
        } else {
          this.set('toasts', Ember.A([]));
        }
      }
    },
    remove: function remove(toastElement) {
      if (toastElement) {
        this.get('toasts').removeObject(toastElement);
        toastElement.remove();
      } else {
        this.set('toasts', Ember.A([]));
      }
      if (window && window.toastr) {
        window.toastr.remove(toastElement);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.remove();
    }
  });
});