define('ember-unified-navbar-360/services/360-marketplace-interface', ['exports', 'ember-unified-navbar-360/constants/marketplace', 'ember-unified-navbar-360/services/360-base-marketplace-interface'], function (exports, _emberUnifiedNavbar360ConstantsMarketplace, _emberUnifiedNavbar360Services360BaseMarketplaceInterface) {
  'use strict';

  var get = Ember.get,
      set = Ember.set,
      emberArray = Ember.A,
      isPresent = Ember.isPresent,
      Promise = Ember.RSVP.Promise,
      setProperties = Ember.setProperties,
      computed = Ember.computed,
      service = Ember.inject.service,
      capitalize = Ember.String.capitalize;

  exports['default'] = _emberUnifiedNavbar360Services360BaseMarketplaceInterface['default'].extend({
    mkpAdapter360: service(window.currentProduct === 'freshsales' ? 'marketplace-adapter' : '360-marketplace-adapter'),
    isChatAppActive: false,
    isPhoneAppActive: false,
    mkpRedirectURL: window.location.origin + '/crm/sales/',

    popupStack: emberArray(),

    init: function init() {
      this._super.apply(this, arguments);
      /**
       * Modal related constants
       */
      setProperties(this, {
        'modalConfig': _emberUnifiedNavbar360ConstantsMarketplace['default'].interfaceAttr.modalConfig,
        'marketplaceConstants': _emberUnifiedNavbar360ConstantsMarketplace['default']
      });
    },

    isCTICallInProgress: computed('mkpAdapter360.callInProgress', function () {
      return get(this, 'mkpAdapter360.callInProgress');
    }),

    /**
     * @public Open AddDeal Page. Made this as seperate functions Since the implementation will vary in future.
     * @param options
     */
    openDeal: function openDeal() {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=deal';
    },

    /**
     * @public Open AddAccount Page. Made this as seperate functions Since the implementation will vary in future.
     * @param options
     */
    openAccount: function openAccount() {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=account';
    },

    /**
     *Add Lead modal form will be opened
     */
    openLead: function openLead() {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=lead';
    },

    /**
     * Add contact form will be opened
     * @returns {*}
     */
    openContact: function openContact() {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=contact';
    },

    /**
     * Add calllog form will be opened
     * @returns {*}
     */
    openCalllog: function openCalllog() {
      return;
    },
    editLead: function editLead(id) {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=lead&entityId=' + id;
    },
    editContact: function editContact(id) {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=contact&entityId=' + id;
    },
    editAccount: function editAccount(id) {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=account&entityId=' + id;
    },
    editDeal: function editDeal(id) {
      window.location.href = get(this, 'mkpRedirectURL') + '?mkpCB=deal&entityId=' + id;
    },

    /**
     * showAction - show Action is the central method for all the show actions which will be transfer the route to repective modules.
     * @param options
     * @returns {*|Promise.resolve}
     */
    showAction: function showAction(options) {
      if (isPresent(options.value)) {
        window.location.href = '' + get(this, 'mkpRedirectURL') + options.id.pluralize() + '/' + options.value;
      } else {
        window.location.href = '' + get(this, 'mkpRedirectURL') + options.id.pluralize();
      }
      return Promise.resolve({ success: get(this, 'i18n').t('marketplace.success.interface_success') });
    },

    /**
     * showAccount - Independent Account route redirection code
     * @param options
     * @returns {Promise.resolve}
     */
    showAccount: function showAccount(options) {
      return this.showAction(options);
    },

    /**
     * showDeal - Independent Account route redirection code
     * @param options
     * @returns {Promise.resolve}
     */
    showDeal: function showDeal(options) {
      return this.showAction(options);
    },

    /**
     * showContact - Independent Account route redirection code
     * @param options
     * @returns {Promise.resolve}
     */
    showContact: function showContact(options) {
      return this.showAction(options);
    },

    /**
     * showLead - Independent Account route redirection code
     * @param options
     * @returns {Promise.resolve}
     */
    showLead: function showLead(options) {
      return this.showAction(options);
    },

    /**
     * Common method to call all those sub
     * @param options
     * @returns Promise.resolve on succes
     */
    open: function open(options) {
      return this.parseAction('open', options);
    },
    publishCallState: function publishCallState(options) {
      var CTI_CALL_STATES = _emberUnifiedNavbar360ConstantsMarketplace['default'].CTI_CALL_STATES;
      if (options && options.callState) {
        switch (options.callState) {
          case CTI_CALL_STATES.IN_PROGRESS:
            set(this, 'mkpAdapter360.callInProgress', true);
            window.ctiCallInProgress = true;
            break;
          case CTI_CALL_STATES.DISCONNECTED:
            set(this, 'mkpAdapter360.callInProgress', false);
            window.ctiCallInProgress = false;
            break;
          default:
            set(this, 'mkpAdapter360.callInProgress', false);
            window.ctiCallInProgress = false;
        }
      }
    },

    /**
     * Common method to check whether requested action is valid or not and call the corresponding functions.
     * @param options
     * @returns Promise.resolve on succes
     */
    parseAction: function parseAction(methodName, options) {
      if (options.id && get(this, 'marketplaceConstants.interfaceAttr.actionElementMap')['' + methodName].includes(options.id)) {
        if (get(this, 'marketplaceConstants.interfaceAttr.ctiMap').includes(options.id)) {
          get(this, 'mkpAdapter360').trigger('' + methodName + capitalize(options.id));
          return {};
        }
        return this['' + methodName + capitalize(options.id)](options);
      }
      return Promise.reject({ error: get(this, 'i18n').t('marketplace.errors.invalid_attribute') });
    }
  });
});