define('ember-unified-navbar-360/constants/freshsales-env', ['exports'], function (exports) {
  'use strict';

  var FreshsalesEnvironments = {
    1: 'latest',
    2: 'int',
    3: 'staging',
    4: 'production'
  };

  exports['default'] = FreshsalesEnvironments;
});