define('ember-unified-navbar-360/services/lnb-persistence-store', ['exports', 'ember-unified-navbar-360/utils/lnb-persistence-store-utils', 'ember-unified-navbar-360/persistence-store/migrations'], function (exports, _emberUnifiedNavbar360UtilsLnbPersistenceStoreUtils, _emberUnifiedNavbar360PersistenceStoreMigrations) {
  'use strict';

  var isBlank = Ember.isBlank,
      set = Ember.set,
      get = Ember.get,
      Promise = Ember.RSVP.Promise;
  var _window = window,
      indexedDB = _window.indexedDB;

  exports['default'] = Ember.Service.extend({
    dbInstance: null,

    /**
     * The indexedDB connection will be opened to initiate the migrations if any
     */
    initialize: function initialize() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.getConnection().then(function (dbInstance) {
          set(_this, 'dbInstance', dbInstance);
          resolve();
        })['catch'](function (err) {
          console.error('Failed to initialise indexDB connection', err);
          reject(err);
        });
      });
    },
    getConnection: function getConnection() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        try {
          if (isBlank(indexedDB)) {
            reject('This browser doesn\'t support IndexedDB');
          } else {
            var dbInstance = get(_this2, 'dbInstance');
            if (dbInstance) {
              return resolve(dbInstance);
            }
          }
          var dbConnection = indexedDB.open('lnb-store', _emberUnifiedNavbar360PersistenceStoreMigrations['default'].dbVersion);

          /**
          * The indexedDB migrations if any will get triggered if the current db version
          * is lower than the target db version
          */
          dbConnection.onupgradeneeded = function (e) {
            _emberUnifiedNavbar360PersistenceStoreMigrations['default'].migrate(e.currentTarget);
          };

          dbConnection.onerror = function () {
            reject(dbConnection.error);
          };

          dbConnection.onsuccess = function () {
            var db = dbConnection.result;

            /**
            * Triggered if a new connection is attempted along with an onversionchange.
            * This can block the DB connection resulting in DB corruption if not handled appropriately.
            * Hence disconnecting the database to free up the database for migration and version upgrade.
            */
            db.onversionchange = function () {
              db.close();
              set(_this2, 'dbInstance', null);
            };
            resolve(db);
          };

          dbConnection.onblocked = function () {
            reject('Connection blocked');
          };
        } catch (err) {
          reject(err);
        }
      });
    },
    addOrUpdate: function addOrUpdate(key, value, resourceInfo) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.getConnection().then(function (persistenceStore) {
          var persistenceStoreTransaction = persistenceStore.transaction(key, 'readwrite');
          var objectStore = persistenceStoreTransaction.objectStore(key);
          var request = objectStore.put(value);

          request.onsuccess = function () {
            (0, _emberUnifiedNavbar360UtilsLnbPersistenceStoreUtils.updateResourceVersions)(resourceInfo);
            resolve(request.result);
          };

          request.onerror = function () {
            reject(request.error);
          };
        })['catch'](function (err) {
          reject({
            error: 'Store disconnected',
            details: err
          });
        });
      });
    },
    getAll: function getAll(key) {
      var _this4 = this;

      return new Promise(function (resolve, reject) {
        _this4.getConnection().then(function (persistenceStore) {
          var persistenceStoreTransaction = persistenceStore.transaction(key, 'readonly');
          var objectStore = persistenceStoreTransaction.objectStore(key);

          var request = objectStore.getAll();

          request.onsuccess = function () {
            resolve(request.result);
          };

          request.onerror = function () {
            reject(request.error);
          };
        })['catch'](function (err) {
          reject({
            error: 'Store disconnected',
            details: err
          });
        });
      });
    }
  });
});