define("ember-unified-navbar-360/templates/components/menu-item", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 6
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/menu-item.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "each-menu-item-expanded", [], ["eachItem", ["subexpr", "@mut", [["get", "submenuValues", ["loc", [null, [14, 45], [14, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "isBundleProduct", ["subexpr", "@mut", [["get", "isBundleProduct", ["loc", [null, [14, 75], [14, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "anchorProductDomain", ["subexpr", "@mut", [["get", "anchorProductDomain", ["loc", [null, [14, 111], [14, 130]]], 0, 0, 0, 0]], [], [], 0, 0], "currentProduct", ["subexpr", "@mut", [["get", "currentProduct", ["loc", [null, [14, 146], [14, 160]]], 0, 0, 0, 0]], [], [], 0, 0], "fromCollapsedView", true, "callerModalAction", ["subexpr", "@mut", [["get", "callerModalAction", ["loc", [null, [14, 204], [14, 221]]], 0, 0, 0, 0]], [], [], 0, 0], "disabledTooltipText", ["subexpr", "if", [["get", "submenuValues.disabled_tooltip_text", ["loc", [null, [14, 246], [14, 281]]], 0, 0, 0, 0], ["get", "submenuValues.disabled_tooltip_text", ["loc", [null, [14, 282], [14, 317]]], 0, 0, 0, 0], ["get", "disabledTooltipText", ["loc", [null, [14, 318], [14, 337]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 242], [14, 338]]], 0, 0]], ["loc", [null, [14, 10], [14, 340]]], 0, 0]],
        locals: ["submenuValues"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/menu-item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "class", "unified360-icon unified360-valign unified360-ellipsis-vertical");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("use");
        dom.setAttributeNS(el2, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_ellipsis_vertical");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "unified360-submenus-container unified360-submenus-container-top");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "unified360-submenus-dropdown");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "unified360-submenus-dropdown-title-item");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "unified360-submenu-item unified360-text-ellipsis unified360-block");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "unified360-p-8 unified360-flex-container");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'onClick');
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["content", "item.label", ["loc", [null, [8, 8], [8, 22]]], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["closeSubmenudropdown"], [], ["loc", [null, [null, null], [11, 98]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "item.sub_menus", ["loc", [null, [12, 14], [12, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 6], [16, 15]]]], ["content", "yield", ["loc", [null, [20, 0], [20, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});