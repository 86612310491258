define('ember-unified-navbar-360/services/360-fd-marketplace-adapter', ['exports', 'ember-unified-navbar-360/constants/fd-marketplace', 'ember-unified-navbar-360/services/360-base-marketplace-adapter'], function (exports, _emberUnifiedNavbar360ConstantsFdMarketplace, _emberUnifiedNavbar360Services360BaseMarketplaceAdapter) {
  'use strict';

  var get = Ember.get,
      Promise = Ember.RSVP.Promise,
      service = Ember.inject.service,
      notEmpty = Ember.computed.notEmpty,
      Evented = Ember.Evented;

  exports['default'] = _emberUnifiedNavbar360Services360BaseMarketplaceAdapter['default'].extend(Evented, {
    mkpDataAPI: service('360-fd-marketplace-data'),
    mkpInterfaceAPI: service('360-fd-marketplace-interface'),
    fdBootstrap: service('360-fd-bootstrap'),
    isMkpInitalized: false,
    isMkpInitializing: false,
    ctiApp: null,
    isDeskCTIAppEnabled: notEmpty('ctiApp'),
    isCTIAppOpen: false,
    marketplaceManager: null,

    setCTIAppOpenState: function setCTIAppOpenState(state) {
      if (!this.ctiApp) {
        return;
      }
      this.set('isCTIAppOpen', state);
      var type = state === true ? 'app.activated' : 'app.deactivated';
      this.ctiApp.trigger({ type: type });
    },
    toggleCTIAppstate: function toggleCTIAppstate() {
      this.setCTIAppOpenState(!this.isCTIAppOpen);
    },
    setupFDMarketplace: function setupFDMarketplace(config) {
      if (!this.isMkpInitalized && !this.isMkpInitializing) {
        this.set('isMkpInitializing', true);
        return this.initializeMarketplace(config);
      }
      return Promise.resolve();
    },
    getApiToken: function getApiToken() {
      return fetch('/crm/ticketing/api/_/marketplace/token').then(function (response) {
        if (response.ok) {
          return response.json().then(function (data) {
            return data.marketplace;
          });
        }
      });
    },
    initializeMarketplace: function initializeMarketplace(config) {
      var _this = this;

      var _get$userData = this.get('fdBootstrap').userData,
          meta = _get$userData.meta,
          agent = _get$userData.agent;
      var account = this.get('fdBootstrap').accountData.account;

      var mkpAdapter = Object.assign(this.getDefaultMkpConfig(), {
        csrfToken: meta.csrf_token,
        apiKey: account.marketplace_settings.data_pipe_key,
        authToken: meta.marketplace_auth_token,
        region: account.marketplace_settings.awol_region,
        page: '',
        product: 'freshdesk',
        productContext: this.get('mkpDataAPI').getProduct(),
        locations: {
          'cti_global_sidebar': {
            services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
          },
          'full_page_app': {
            services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
          }
        },
        accountID: account.ref_id,
        bundleID: config.bundleId,

        /**
         * getAttribute - trigger the getData method in marketplace-data service and returns a resolved / rejected promise
         */
        getAttribute: function getAttribute(attribute) {
          return get(_this, 'mkpDataAPI').get(attribute);
        },

        events: ['cti.triggerDialer'],
        interceptableEvents: [],

        /**
         * getAttribute - validates and trigger the interface APIs implemented in marketplace-interface-api and returns a resolved / rejected promise
         */
        'interface': function _interface(args) {
          return _this.handleInterface(args, _emberUnifiedNavbar360ConstantsFdMarketplace.INTERFACE_ACTIONS);
        },

        // galleryURL: window.location.origin + '/crm/ticketing/admin/marketplace/gallery',
        tenant: account.marketplace_settings.tenant_id,
        account: account.ref_id,
        pod: account.marketplace_settings.pod,
        locale: agent.contact.language,
        iframeId: 'gallery-frame',
        isAdmin: agent.abilities.includes('admin_tasks'),
        authentication: meta.marketplace_authentication,
        /**
         * getApiToken - will get the new auth token for MAPI
         */
        getApiToken: get(this, 'getApiToken').bind(this)
      });

      try {
        var marketplaceManager = new window.MarketplaceManager(mkpAdapter);
        this.set('marketplaceManager', marketplaceManager);
        this.fetchAndSetCTIApp().then(function () {
          _this.setProperties({
            isMkpInitalized: true,
            isMkpInitializing: false
          });
        });
      } catch (e) {
        console.error('Failed to initialise Freshdesk marketplace script!', e.stack);
        this.setProperties({
          isMkpInitalized: false,
          isMkpInitializing: false
        });
      }
    },
    fetchAndSetCTIApp: function fetchAndSetCTIApp() {
      var _this2 = this;

      return this.getApps({ location: 'cti_global_sidebar' }).then(function (response) {
        if (response && Array.isArray(response)) {
          _this2.set('ctiApp', response[0]);
        }
      });
    },

    /**
     * publishEvent - trigger the event api
     */
    publishEvent: function publishEvent(event) {
      var mkp = get(this, 'marketplaceManager');
      return mkp && mkp.trigger(event);
    }
  });
});