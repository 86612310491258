define('ember-telephone-keypad/components/keypad-component/key-component/component', ['exports', 'ember-telephone-keypad/components/keypad-component/key-component/template'], function (exports, _emberTelephoneKeypadComponentsKeypadComponentKeyComponentTemplate) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberTelephoneKeypadComponentsKeypadComponentKeyComponentTemplate['default'],
    tagName: 'li',
    classNames: ['key-component'],

    click: function click() {
      if (this.get('number') === '0' && this.get('usageIntention') === 'DIAL') {
        if (this.get('isLongPressed')) {
          this.onPress('+');
          return;
        }
      }
      this.onPress(this.get('number'));
    },
    mouseDown: function mouseDown() {
      this.set('pressStart', Date.now());
    },
    mouseUp: function mouseUp() {
      this.set('pressEnd', Date.now());
      this.set('isLongPressed', this.get('pressEnd') - this.get('pressStart') < 500 ? false : true);
    }
  });
});