define('ember-analytics-collector/instance-initializers/analytics-collector', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.initialize = initialize;

  var typeOf = _ember['default'].typeOf;
  var RSVP = _ember['default'].RSVP;
  var isPresent = _ember['default'].isPresent;

  function initialize() {
    var FW_ANALYTICS_COLLECTOR = window.FW_ANALYTICS_COLLECTOR;

    if (isPresent(FW_ANALYTICS_COLLECTOR) && typeof FW_ANALYTICS_COLLECTOR.flushSchedulerQueue === 'function') {
      (function () {
        var _originalOnError = _ember['default'].onerror;

        _ember['default'].onerror = function (error) {
          FW_ANALYTICS_COLLECTOR.flushSchedulerQueue();
          if (typeof _originalOnError === 'function') {
            _originalOnError.call(_ember['default'], error);
          }
        };

        RSVP.on('error', function (reason) {
          // Once the issue https://github.com/emberjs/ember.js/issues/12505 is resolved we can remove this ignored error.
          if (reason && typeOf(reason) === 'error' && reason.name !== 'TransitionAborted') {
            FW_ANALYTICS_COLLECTOR.flushSchedulerQueue();
          }
        });
      })();
    }
  }

  exports['default'] = {
    name: 'analytics-collector',
    initialize: initialize
  };
});