define('ember-unified-navbar-360/services/360-fd-marketplace-data', ['exports', 'ember-unified-navbar-360/constants/fd-marketplace', 'ember-unified-navbar-360/services/360-base-marketplace-data'], function (exports, _emberUnifiedNavbar360ConstantsFdMarketplace, _emberUnifiedNavbar360Services360BaseMarketplaceData) {
  'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var get = Ember.get,
      Promise = Ember.RSVP.Promise,
      service = Ember.inject.service;

  exports['default'] = _emberUnifiedNavbar360Services360BaseMarketplaceData['default'].extend({
    fdBootstrap: service('360-fd-bootstrap'),
    mkpApp: service('marketplace-app'),
    globalDataAPIs: ['loggedInUser', 'domainName'],
    blockedAttributes: _emberUnifiedNavbar360ConstantsFdMarketplace.BLOCKED_ATTRIBUTES,

    getLoggedInUser: function getLoggedInUser() {
      var agent = get(this, 'fdBootstrap').userData.agent;

      return agent;
    },
    getDomainName: function getDomainName() {
      var account = get(this, 'fdBootstrap').accountData.account;

      return account.full_domain;
    },
    abilitiesWhitelistedAttrs: function abilitiesWhitelistedAttrs(data) {
      var allowedAbilities = _emberUnifiedNavbar360ConstantsFdMarketplace.ALLOWED_ABILITIES || [];
      var intersection = data.abilities.filter(function (x) {
        return allowedAbilities.includes(x);
      });
      data.abilities = intersection;
      return data;
    },
    get: function get(attribute) {
      var result = {};
      if (this.globalDataAPIs.indexOf(attribute) !== -1) {
        result = this.removeBlacklistedAttrs(this['get' + attribute.capitalize()](), attribute);
        if (attribute === 'loggedInUser' && result.abilities) {
          result = this.abilitiesWhitelistedAttrs(result);
        }
      }
      return Promise.resolve(_defineProperty({}, attribute, result));
    }
  });
});