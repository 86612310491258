define("ember-emojipalette/components/emoji-list/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 6
                },
                "end": {
                  "line": 8,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-emojipalette/components/emoji-list/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "role", "image");
              dom.setAttribute(el1, "class", "emojidex-list-item");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(element2, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["selectEmoji", ["get", "emoji", ["loc", [null, [7, 77], [7, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 54], [7, 84]]], 0, 0], ["content", "emoji", ["loc", [null, [7, 85], [7, 94]]], 0, 0, 0, 0]],
            locals: ["emoji"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 10,
                "column": 2
              }
            },
            "moduleName": "modules/ember-emojipalette/components/emoji-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "emojidex-list-category-label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element3, 'class');
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["inline", "get", [["get", "categoryNames", ["loc", [null, [4, 54], [4, 67]]], 0, 0, 0, 0], ["get", "category", ["loc", [null, [4, 68], [4, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 48], [4, 78]]], 0, 0], ["attribute", "class", ["concat", ["emojidex-emoji-list emojidex-", ["get", "category", ["loc", [null, [5, 47], [5, 55]]], 0, 0, 0, 0], "-list ", ["subexpr", "if", [["get", "isCompleteList", ["loc", [null, [5, 68], [5, 82]]], 0, 0, 0, 0], "emojidex-complete-list"], [], ["loc", [null, [5, 63], [5, 109]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["subexpr", "get", [["get", "emojiList", ["loc", [null, [6, 19], [6, 28]]], 0, 0, 0, 0], ["get", "category", ["loc", [null, [6, 29], [6, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 14], [6, 38]]], 0, 0]], [], 0, null, ["loc", [null, [6, 6], [8, 15]]]]],
          locals: ["category"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "modules/ember-emojipalette/components/emoji-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "categories", ["loc", [null, [3, 10], [3, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [10, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 15,
                "column": 4
              }
            },
            "moduleName": "modules/ember-emojipalette/components/emoji-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "role", "image");
            dom.setAttribute(el1, "class", "emojidex-list-item");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["selectEmoji", ["get", "emoji", ["loc", [null, [14, 75], [14, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 52], [14, 82]]], 0, 0], ["content", "emoji", ["loc", [null, [14, 83], [14, 92]]], 0, 0, 0, 0]],
          locals: ["emoji"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "modules/ember-emojipalette/components/emoji-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["emojidex-emoji-list emojidex-", ["get", "listType", ["loc", [null, [12, 45], [12, 53]]], 0, 0, 0, 0], "-list"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "emojiList", ["loc", [null, [13, 12], [13, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 4], [15, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "modules/ember-emojipalette/components/emoji-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["emojidex-icon-palette ", ["subexpr", "unless", [["get", "searchEnabled", ["loc", [null, [1, 43], [1, 56]]], 0, 0, 0, 0], "emojidex-top-component"], [], ["loc", [null, [1, 34], [1, 83]]], 0, 0], " ", ["subexpr", "if", [["get", "isCompleteList", ["loc", [null, [1, 89], [1, 103]]], 0, 0, 0, 0], "emojidex-bottom-component"], [], ["loc", [null, [1, 84], [1, 133]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isCompleteList", ["loc", [null, [2, 6], [2, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 0], [17, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});