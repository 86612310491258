define('ember-unified-navbar-360/services/360-fd-marketplace-interface', ['exports', 'ember-unified-navbar-360/constants/fd-marketplace', 'ember-unified-navbar-360/services/360-base-marketplace-interface'], function (exports, _emberUnifiedNavbar360ConstantsFdMarketplace, _emberUnifiedNavbar360Services360BaseMarketplaceInterface) {
  'use strict';

  var get = Ember.get,
      Promise = Ember.RSVP.Promise,
      capitalize = Ember.String.capitalize,
      service = Ember.inject.service;

  exports['default'] = _emberUnifiedNavbar360Services360BaseMarketplaceInterface['default'].extend({
    router: service(),
    mkpAdapter360: service('360-fd-marketplace-adapter'),
    mkpInterface360: service('360-marketplace-interface'),
    mkpDeskRedirectURL: window.location.origin + '/crm/ticketing',

    /**
     * Open the contact page in new tab
     * @returns {*}
     */
    clickContact: function clickContact(options) {
      if (window.currentProduct === 'freshdesk') {
        get(this, 'router').transitionTo('helpdesk.contacts.show', options.value);
      } else {
        window.open(get(this, 'mkpDeskRedirectURL') + '/a/contacts/' + options.value, '_blank');
      }
    },

    /**
     * Open the ticket details page in new tab
     * @returns {*}
     */
    clickTicket: function clickTicket(options) {
      if (window.currentProduct === 'freshdesk') {
        get(this, 'router').transitionTo('helpdesk.tickets.show', options.value);
      } else {
        window.open(get(this, 'mkpDeskRedirectURL') + '/a/tickets/' + options.value, '_blank');
      }
    },

    /**
     * Common method to call all those sub
     * @param options
     * @returns Promise.resolve on succes
     */
    click: function click(options) {
      return this.parseAction('click', options);
    },
    showSoftphone: function showSoftphone() {
      return get(this, 'mkpAdapter360').setCTIAppOpenState(true);
    },
    hideSoftphone: function hideSoftphone() {
      return get(this, 'mkpAdapter360').setCTIAppOpenState(false);
    },
    showMissedCall: function showMissedCall() {
      return;
    },
    hideMissedCall: function hideMissedCall() {
      return;
    },

    /**
    * @public Freshdesk dialog handler
    * @params {DOM Element} element - The IFrame which has the service binded
    * @params {Object} options  - includes Title opted by the app, if app skipps default from marketplace will be used, product can opt for their tile in modalCOnfig.modal.title
    * @return - promise.resolve
    */
    showDialog: function showDialog(options, instance) {
      return;
    },

    /**
     * publishCallState - publishes the call state
     * here we depend on CRM publishCallState. This event
     * will be triggered from CTI apps. We'll listen it here
     * and based on call states we'll open link in same tab or
     * new tab.
     */
    publishCallState: function publishCallState(options) {
      get(this, 'mkpInterface360').publishCallState(options);
    },

    /**
     * Common method to check whether requested action is valid or not and call the corresponding functions.
     * @param options
     * @returns Promise.resolve on succes
     */
    parseAction: function parseAction(methodName, options) {
      var actionsElement = _emberUnifiedNavbar360ConstantsFdMarketplace.ACTIONS_ELEMENT_MAP['' + methodName] || [];
      if (options.id && actionsElement.includes(options.id)) {
        return this['' + methodName + capitalize(options.id)](options);
      }
      return Promise.reject({ error: get(this, 'i18n').t('marketplace.errors.invalid_attribute') });
    }
  });
});