define("ember-unified-navbar-360/utils/storage-keys", ["exports"], function (exports) {
  "use strict";

  function getStorageKey(key) {
    var regex = /\/(?:sales|marketer|messaging|phone)/;
    var rootURL = window.location.pathname.match(regex);
    if (rootURL) {
      var bundleName = window.location.pathname.slice(1, rootURL["index"]);
      return bundleName + '.' + key;
    }
    return key;
  }

  function setLocalStorage(key, value) {
    var storageKey = getStorageKey(key);
    localStorage.setItem(storageKey, value);
  }

  function getLocalStorage(key) {
    var storageKey = getStorageKey(key);
    return localStorage.getItem(storageKey) || localStorage.getItem(key);
  }

  exports.getStorageKey = getStorageKey;
  exports.setLocalStorage = setLocalStorage;
  exports.getLocalStorage = getLocalStorage;
});