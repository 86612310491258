define('ember-form/components/ui-textarea/component', ['exports', 'ember', 'ember-form/components/ui-textarea/template', 'ember-form/mixins/focus-field'], function (exports, _ember, _emberFormComponentsUiTextareaTemplate, _emberFormMixinsFocusField) {
  'use strict';

  exports['default'] = _ember['default'].TextArea.extend(_emberFormMixinsFocusField['default'], {
    layout: _emberFormComponentsUiTextareaTemplate['default'],
    init: function init() {
      if (this.get('parentView.forAttribute')) {
        this.set('elementId', this.get('parentView.forAttribute'));
      }
      this._super(arguments);
    },
    classNames: ['form-control']
  });
});