define('ember-form/components/ui-checkbox/component', ['exports', 'ember', 'ember-form/components/ui-checkbox/template'], function (exports, _ember, _emberFormComponentsUiCheckboxTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiCheckboxTemplate['default'],
    init: function init() {
      this._super();

      if (this.get('parentView.sanitizedID')) {
        this.set('elementId', this.get('parentView.sanitizedID'));
      }
    }
  });
});