define('ember-data-table/mixins/selectall-checkbox', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Mixin.create({
    toggles: computed({
      get: function get() {
        return _ember['default'].A([]);
      }
    }),

    allChecked: computed('toggles.@each.isChecked', {
      get: function get() {
        var toggles = this.get('toggles');
        return toggles.length > 0 && toggles.isEvery('isChecked');
      },
      set: function set(key, value) {
        this.get('toggles').setEach('isChecked', value);
        return value;
      }
    }),

    allSelectedItems: computed.filterBy('toggles', 'isChecked', true),

    totalSelectedCount: computed.alias('allSelectedItems.length'),

    allSelectedItemsIds: computed.mapBy('allSelectedItems', 'id'),

    isBulkSelection: computed.gt('totalSelectedCount', 0),

    actions: {
      registerToggle: function registerToggle(toggle) {
        this.get('toggles').addObject(toggle);
      },
      deregisterToggle: function deregisterToggle(toggle) {
        this.get('toggles').removeObject(toggle);
      },
      removeSelected: function removeSelected() {// model obj path {eg: 'model.tasks'}
        // var allSelectedItems = this.get('allSelectedItems').mapBy('content');
        // this.get('model').removeObjects(allSelectedItems);
      }
    }
  });
});