define('ember-unified-navbar-360/services/marketplace-app', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Service.extend({
    setMKPAuthConfig: function setMKPAuthConfig(config) {
      Ember.set(this, 'mkpAuthConfig', config);
    },
    getMKPAuthConfig: function getMKPAuthConfig() {
      return Ember.get(this, 'mkpAuthConfig');
    }
  });
});