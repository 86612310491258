define('ember-form/components/ui-button/component', ['exports', 'ember', 'ember-form/components/ui-button/template', 'ember-form/components/ui-form/component'], function (exports, _ember, _emberFormComponentsUiButtonTemplate, _emberFormComponentsUiFormComponent) {
  'use strict';

  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiButtonTemplate['default'],
    formModel: computed({
      get: function get() {
        return this.nearestOfType(_emberFormComponentsUiFormComponent['default']);
      }
    }),
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['animateSaving:btn-saving'],
    attributeBindings: ['disabled', 'typeAttribute:type'],

    typeAttribute: computed('type', {
      get: function get() {
        return isEqual(this.get('type'), 'save') ? 'submit' : 'button';
      }
    }),

    animateSaving: computed('formModel.for.isSaving', {
      get: function get() {
        if (isEqual(this.get('type'), "save")) {
          return this.get('formModel.for.isSaving');
        }
        return;
      }
    }),
    disabled: computed.oneWay('formModel.for.isSaving'),

    click: function click(event) {
      event.preventDefault();
      var buttonType = this.get('type');

      this.send(buttonType && buttonType === 'cancel' ? 'cancel' : 'formSubmit');
    },
    actions: {
      formSubmit: function formSubmit() {
        this.get('formModel').send('validate', this.get('action'));
      },
      cancel: function cancel() {
        this.get('formModel').send('cancel', this.get('action'));
      }
    }
  });
});