define('ember-unified-navbar-360/components/main-logo', ['exports', 'ember-unified-navbar-360/templates/components/main-logo'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMainLogo) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMainLogo['default'],
    expanded: false,
    classNames: ['unified360-main-logo'],
    s360Valign: Ember.computed(function () {
      return this.get('expanded') ? '' : 'unified360-valign';
    }),
    logoObject: Ember.computed('finalSideNavValues', function () {
      var logoObject = this.get('finalSideNavValues.logo_object') || JSON.parse(JSON.stringify(this.get('finalSideNavValues.menus')[0]));
      if (!logoObject.url && logoObject.sub_menus && logoObject.sub_menus.length) {
        logoObject.url = logoObject.sub_menus[0].url;
        logoObject.url_as_route = logoObject.sub_menus[0].url_as_route;
      }
      return logoObject;
    }),
    actions: {
      removeClass: function removeClass(e) {
        this.removeActiveMenuClass(e);
        if (this.get('expanded')) {
          this.closeSubmenudropdown(e);
        }
      },
      callerModalAction: function callerModalAction(url) {
        this.sendAction('callerModalAction', url);
      }
    }
  });
});