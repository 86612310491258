define('ember-unified-navbar-360/mixins/apps', ['exports'], function (exports) {
  'use strict';

  var Mixin = Ember.Mixin,
      computed = Ember.computed,
      service = Ember.inject.service,
      Promise = Ember.RSVP.Promise,
      get = Ember.get,
      set = Ember.set,
      setProperties = Ember.setProperties;

  exports['default'] = Mixin.create({
    mkpAdapter360: service(window.currentProduct === 'freshsales' ? 'marketplace-adapter' : '360-marketplace-adapter'),
    isMarketplaceAppLoading: true,

    marketplaceApps: computed({
      get: function get() {
        return {};
      }
    }),

    mkpPlaceholder: computed('mkpPlaceholderPosition', {
      get: function get() {
        return [];
      }
    }),

    /**
     * initMarketplaceApps - called during the initialization of controller/component wherever this mixin is used
     * sets the isMarketplaceAppLoading and marketplaceApps with the boolean and array of marketplace apps respectively.
     */
    initMarketplaceApps: function initMarketplaceApps(callback) {
      var _this = this;

      if (get(this, 'mkpAdapter360.isMarketplaceScriptInitalized')) {
        var mkpPlaceholder = get(this, 'mkpPlaceholder');
        Promise.all(mkpPlaceholder.map(function (item) {
          return _this._getAppsByPlaceholder({ location: item }).then(function (apps) {
            set(_this, 'marketplaceApps.' + item, apps);
            if (typeof callback === 'function') callback(get(_this, 'marketplaceApps.full_page_app'));
          });
        })).then(function () {
          set(_this, 'isMarketplaceAppLoading', false);
        }, function () {
          setProperties(_this, {
            'marketplaceApps': {},
            'isMarketplaceAppLoading': false
          });
        });
      } else {
        setProperties(this, {
          'marketplaceApps': {},
          'isMarketplaceAppLoading': false
        });
      }
    },

    /**
     * resetMarketplaceApps - reset the marketplace apps and set the loading true
     */
    resetMarketplaceApps: function resetMarketplaceApps() {
      setProperties(this, {
        'marketplaceApps': [],
        'isMarketplaceAppLoading': true
      });
    },

    /**
     * _getAppsByPlaceholder - method to get the list of marketplace apps
     *
     * @param  {object} filter object holds the location key where apps will be loaded
     * @return {array} array of marketplace apps
     */
    _getAppsByPlaceholder: function _getAppsByPlaceholder(filter) {
      return get(this, 'mkpAdapter360').getApps(filter).then(function (apps) {
        return apps.map(function (app) {
          app.isLoading = true;
          // Wait for the app(iframe) to finish loading then trigger activate event
          app.triggerActivate = function () {
            if (app.isLoading) {
              app.isActivate = true;
            } else {
              app.trigger({ type: 'app.activated' });
            }
          };
          app.element.onload = function () {
            app.isLoading = false;
            if (app.isActivate) {
              app.trigger({ type: 'app.activated' });
            }
          };
          return app;
        });
      });
    }
  });
});