define('ember-unified-navbar-360/components/link-item-tag', ['exports', 'ember-unified-navbar-360/templates/components/link-item-tag'], function (exports, _emberUnifiedNavbar360TemplatesComponentsLinkItemTag) {
  'use strict';

  var readOnly = Ember.computed.readOnly,
      computed = Ember.computed,
      _get = Ember.get,
      set = Ember.set,
      getProperties = Ember.getProperties,
      isPresent = Ember.isPresent;

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsLinkItemTag['default'],
    tagName: 'span',
    classNameBindings: ['tagClassName', 'dynamicClassNames'],
    tagClassName: readOnly('tag.className'),
    s360Transformation: Ember.inject.service(),

    dynamicClassNames: computed('dynamicContent', {
      get: function get() {
        if (isPresent(_get(this, 'dynamicContent'))) {
          return 'unified360-list-item-notifier-tag';
        }
        return '';
      }
    }),

    /**
     * fetchBundleData - triggers getBundleData method
     * _fetchBundleDataMemCache in window object used to cache the callback function
     * and prevents duplicate calls
    */
    fetchBundleData: function fetchBundleData(endPoint, cb) {
      if (!window._fetchBundleDataMemCache) {
        window._fetchBundleDataMemCache = {};
      }

      if (!window._fetchBundleDataMemCache[endPoint]) {
        window._fetchBundleDataMemCache[endPoint] = [];
      }

      window._fetchBundleDataMemCache[endPoint].push(cb);

      if (window._fetchBundleDataMemCache[endPoint].length > 1) {
        return;
      }

      _get(this, 's360Transformation').getBundleData(_get(this, 'anchorProductDomain'), _get(this, 'isBundleProduct'), endPoint).then(function (resp) {
        window._fetchBundleDataMemCache[endPoint].forEach(function (cb) {
          return cb(resp);
        });
      })['finally'](function () {
        // Clear once resolved, so that avaialble for next calls
        delete window._fetchBundleDataMemCache[endPoint];
      });
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'tag.content_event', 'tag.content_path'),
          contentEvent = _getProperties['tag.content_event'],
          contentPath = _getProperties['tag.content_path'];

      if (contentEvent) {
        this._contentHandler = function (data) {
          set(_this, 'dynamicContent', data.detail.display_value);
        };
        window.addEventListener(contentEvent, this._contentHandler);
      }

      if (contentPath) {
        this.fetchBundleData(contentPath, function (response) {
          /**
           * During lnb config update this component renderes multiple times for various use cases,
           * to avoid duplicate api calls, we used memory cache. Due to async operation, when component
           * rerenders, the callback response tries to set value to destroyed object.
           * Added below condition to check if the component is active after async operation is completed
           * */
          if (!(_get(_this, 'isDestroyed') || _get(_this, 'isDestroying'))) {
            set(_this, 'dynamicContent', response.display_value);
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var contentEvent = _get(this, 'tag.content_event');

      if (contentEvent) {
        window.removeEventListener(contentEvent, this._contentHandler);
      }
    }
  });
});