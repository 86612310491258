define("ember-unified-navbar-360/services/inapp-freshchat-widget", ["exports", "ember-unified-navbar-360/constants/freshchat-inapp-data", "ember-inject-script", "ember-unified-navbar-360/utils/difference-in-days", "ember-unified-navbar-360/utils/storage-keys"], function (exports, _emberUnifiedNavbar360ConstantsFreshchatInappData, _emberInjectScript, _emberUnifiedNavbar360UtilsDifferenceInDays, _emberUnifiedNavbar360UtilsStorageKeys) {
  "use strict";

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var isPresent = Ember.isPresent,
      service = Ember.inject.service,
      set = Ember.set,
      get = Ember.get,
      Promise = Ember.RSVP.Promise;

  exports["default"] = Ember.Service.extend({
    mkpApp: service('marketplace-app'),
    tags: null,
    initialiseFcWidget: function initialiseFcWidget(data, env, callback) {
      var _this = this;

      var _ref = env === 'production' ? _emberUnifiedNavbar360ConstantsFreshchatInappData["default"].production : _emberUnifiedNavbar360ConstantsFreshchatInappData["default"].staging,
          script = _ref.script,
          token = _ref.token,
          url = _ref.url;

      if (!(isPresent(data) && (typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object')) {
        data = {};
      }
      // Default properties including token and host are appended by LNB and the customised properties are sent from feeder products
      (0, _emberInjectScript["default"])(script).then(function () {
        if (callback && typeof callback === "function") {
          callback();
        }
        set(_this, 'tags', data.tags);
        _this.setInappFcWidgetState();
        data['token'] = token;
        data['host'] = url;
        data['open'] = JSON.parse((0, _emberUnifiedNavbar360UtilsStorageKeys.getLocalStorage)('isInappFcWidgetOpened') != undefined ? (0, _emberUnifiedNavbar360UtilsStorageKeys.getLocalStorage)('isInappFcWidgetOpened') : false);

        _this.getFcWidgetRestoreId().then(function (resp) {
          data['restoreId'] = resp;
          window.fcWidget.on('widget:loaded', function () {
            window.dispatchEvent(new CustomEvent('fcWidgetLoaded'));
          });
        }, function () {
          var getUserAfterLoad = _this.getUserAfterLoad.bind(_this);

          window.fcWidget.on('widget:loaded', getUserAfterLoad);
        })["finally"](function () {
          var configData = _this.get('mkpApp').getMKPAuthConfig();
          if (configData) {
            // Any change to externalId property will affect the restoration logic to the ongoing users.
            data['externalId'] = data.email + '_' + get(configData, 'account_id');
            data['meta'] = {
              'product_name': get(configData, 'product_name'),
              'account_id': get(configData, 'account_id'),
              'account_url': get(configData, 'freshid_config.ui_url'),
              'plan': get(configData, 'user_config.plan_name'),
              'is_account_activated': get(configData, 'account_config.account_confirmed'),
              'account_state': get(configData, 'account_config.state'),
              'signup_date': get(configData, 'account_config.signup_date'),
              'mrr': get(configData, 'account_config.subscription_mrr'),
              'role': get(configData, 'user_config.role_name'),
              'trialdays_left': get(configData, 'account_config.state') === 'trial' ? (0, _emberUnifiedNavbar360UtilsDifferenceInDays["default"])(get(configData, 'account_config.next_renewal_at')) : 0
            };
          }
          window.fcWidget.init(data);
          window.fcWidget.user.setProperties(data.meta);
        });
      });
    },
    setFcWidgetRestoreId: function setFcWidgetRestoreId(payload) {
      var url = window.location.origin + _emberUnifiedNavbar360ConstantsFreshchatInappData["default"].restoreIdUrl;
      Ember.$.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify({
          restoreId: payload
        }),
        contentType: 'application/json; charset=UTF-8'
      }).then(function (resp) {
        console.log('setRestoreID: ' + resp);
      }, function (err) {
        console.log('setRestoreID: ' + err);
      });
    },
    getUserAfterLoad: function getUserAfterLoad() {
      var _this2 = this;

      window.dispatchEvent(new CustomEvent('fcWidgetLoaded'));
      window.fcWidget.user.get().then(function (resp) {
        _this2.loadUser(resp);
      }, function (err) {
        _this2.loadUser(err);
      });
    },
    loadUser: function loadUser(resp) {
      var _this3 = this;

      if (resp.status === 200) {
        this.setFcWidgetRestoreId(resp.data.restoreId);
      } else if ([401, 403, 404, 409].includes(resp.status)) {
        window.fcWidget.user.create().then(function (resp) {
          _this3.setFcWidgetRestoreId(resp.data.restoreId);
        }, function (err) {
          console.log('loadUser: ' + err);
        });
      }
    },
    getFcWidgetRestoreId: function getFcWidgetRestoreId() {
      var url = window.location.origin + _emberUnifiedNavbar360ConstantsFreshchatInappData["default"].restoreIdUrl;
      return new Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'GET',
          url: url,
          contentType: 'application/json; charset=UTF-8'
        }).then(function (resp) {
          return resolve(resp.restoreId);
        }, function (err) {
          return reject(err);
        });
      });
    },
    setInappFcWidgetState: function setInappFcWidgetState() {
      var widgetClosedCallback = this.widgetClosedCallback.bind(this);
      window.fcWidget.on('widget:opened', function () {
        (0, _emberUnifiedNavbar360UtilsStorageKeys.setLocalStorage)('isInappFcWidgetOpened', true);
      });
      window.fcWidget.on('widget:closed', widgetClosedCallback);
    },
    widgetClosedCallback: function widgetClosedCallback() {
      (0, _emberUnifiedNavbar360UtilsStorageKeys.setLocalStorage)('isInappFcWidgetOpened', false);
      window.fcWidget.setTags(get(this, 'tags'));
    }
  });
});