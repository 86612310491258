define("ember-unified-navbar-360/templates/components/widget-items", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 10,
                "column": 2
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "aria-label", "Customer success");
            dom.setAttribute(el1, "data-unified360-addon", "fsu_addon");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "target", "_blank");
            dom.setAttribute(el2, "class", "s360-fsu");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el3 = dom.createElement("svg");
            dom.setAttribute(el3, "class", "unified360-icon unified360-valign");
            dom.setAttribute(el3, "viewBox", "0 0 32 32");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("use");
            dom.setAttributeNS(el4, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_fsu");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var element14 = dom.childAt(element13, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createAttrMorph(element14, 'href');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [3, 66], [3, 78]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [3, 61], [3, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["get", "sideNavValues.freshsuccess_config.fsu_url", ["loc", [null, [4, 16], [4, 57]]], 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 19,
                "column": 2
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "aria-label", "Tickets");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "target", "_blank");
            dom.setAttribute(el2, "class", "s360-fdesk-tickets");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el3 = dom.createElement("svg");
            dom.setAttribute(el3, "class", "unified360-icon unified360-valign");
            dom.setAttribute(el3, "viewBox", "0 0 32 32");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("use");
            dom.setAttributeNS(el4, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_fdesk_tickets");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1]);
            var element12 = dom.childAt(element11, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element11, 'class');
            morphs[1] = dom.createAttrMorph(element12, 'href');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [12, 66], [12, 78]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [12, 61], [12, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["get", "freshdeskTicketsUrl", ["loc", [null, [13, 16], [13, 35]]], 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 1
              },
              "end": {
                "line": 26,
                "column": 1
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el2 = dom.createElement("svg");
            dom.setAttribute(el2, "viewBox", "0 0 32 32");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("use");
            dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_loading_spinner");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n		");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element10, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [21, 64], [21, 76]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [21, 59], [21, 126]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 4
                },
                "end": {
                  "line": 29,
                  "column": 4
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "marketplace-list", [], ["mkpPlaceholderPosition", "left_nav_cti", "setApp", ["subexpr", "action", ["setApp"], [], ["loc", [null, [28, 70], [28, 87]]], 0, 0], "mkpAdapterServiceName", ["subexpr", "@mut", [["get", "marketplaceAdapter", ["loc", [null, [28, 110], [28, 128]]], 0, 0, 0, 0]], [], [], 0, 0], "mkpInterfaceServiceName", ["subexpr", "@mut", [["get", "marketplaceInterface", ["loc", [null, [28, 153], [28, 173]]], 0, 0, 0, 0]], [], [], 0, 0], "parentModalStackName", ["subexpr", "@mut", [["get", "parentModalStack", ["loc", [null, [28, 195], [28, 211]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 6], [28, 213]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 4
                  },
                  "end": {
                    "line": 31,
                    "column": 4
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "fd-mkp-cti-trigger", ["loc", [null, [30, 6], [30, 28]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 32,
                        "column": 6
                      },
                      "end": {
                        "line": 38,
                        "column": 6
                      }
                    },
                    "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    dom.setNamespace("http://www.w3.org/2000/svg");
                    var el2 = dom.createElement("svg");
                    dom.setAttribute(el2, "viewBox", "0 0 32 32");
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("use");
                    dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_phone_disabled");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element9 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element9, 'class');
                    morphs[1] = dom.createAttrMorph(element9, 'aria-label');
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["s360-caller unified360-widgets-item unified360-list-item unified360-list-item-disabled unified360-disabled ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [33, 132], [33, 144]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [33, 127], [33, 194]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["get", "disabledTooltipText", ["loc", [null, [33, 209], [33, 228]]], 0, 0, 0, 0], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 40,
                            "column": 10
                          },
                          "end": {
                            "line": 54,
                            "column": 10
                          }
                        },
                        "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("            ");
                        dom.appendChild(el0, el1);
                        dom.setNamespace("http://www.w3.org/2000/svg");
                        var el1 = dom.createElement("svg");
                        dom.setAttribute(el1, "viewBox", "0 0 32 32");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("use");
                        dom.setAttributeNS(el2, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_phone");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n            ");
                        dom.appendChild(el0, el1);
                        dom.setNamespace(null);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "unified360-phone-wave1");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        dom.setNamespace("http://www.w3.org/2000/svg");
                        var el2 = dom.createElement("svg");
                        dom.setAttribute(el2, "class", "unified360-icon");
                        dom.setAttribute(el2, "viewBox", "0 0 32 32");
                        var el3 = dom.createTextNode("\n                ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("use");
                        dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_phone_wave1");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n            ");
                        dom.appendChild(el0, el1);
                        dom.setNamespace(null);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "unified360-phone-wave2");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        dom.setNamespace("http://www.w3.org/2000/svg");
                        var el2 = dom.createElement("svg");
                        dom.setAttribute(el2, "class", "unified360-icon");
                        dom.setAttribute(el2, "viewBox", "0 0 32 32");
                        var el3 = dom.createTextNode("\n                ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("use");
                        dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_phone_wave2");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 54,
                            "column": 10
                          },
                          "end": {
                            "line": 58,
                            "column": 10
                          }
                        },
                        "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("            ");
                        dom.appendChild(el0, el1);
                        dom.setNamespace("http://www.w3.org/2000/svg");
                        var el1 = dom.createElement("svg");
                        dom.setAttribute(el1, "viewBox", "0 0 32 32");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("use");
                        dom.setAttributeNS(el2, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_phone");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 38,
                          "column": 6
                        },
                        "end": {
                          "line": 60,
                          "column": 6
                        }
                      },
                      "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "aria-label", "Phone");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element8 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element8, 'class');
                      morphs[1] = dom.createElementMorph(element8);
                      morphs[2] = dom.createMorphAt(element8, 1, 1);
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["s360-caller unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [39, 113], [39, 125]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [39, 108], [39, 175]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleFreshcaller"], [], ["loc", [null, [39, 13], [39, 43]]], 0, 0], ["block", "if", [["get", "callInProgress", ["loc", [null, [40, 16], [40, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [40, 10], [58, 17]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 60,
                          "column": 6
                        },
                        "end": {
                          "line": 67,
                          "column": 6
                        }
                      },
                      "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "aria-label", "Phone");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("          ");
                      dom.appendChild(el1, el2);
                      dom.setNamespace("http://www.w3.org/2000/svg");
                      var el2 = dom.createElement("svg");
                      dom.setAttribute(el2, "viewBox", "0 0 32 32");
                      var el3 = dom.createTextNode("\n            ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("use");
                      dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_phone_disabled");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n          ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n      ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createAttrMorph(element7, 'class');
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["s360-caller unified360-widgets-item unified360-list-item unified360-list-item-disabled ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [61, 112], [61, 124]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [61, 107], [61, 174]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 38,
                        "column": 6
                      },
                      "end": {
                        "line": 67,
                        "column": 6
                      }
                    },
                    "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "sideNavValues.freshcaller_config.fc_enabled", ["loc", [null, [38, 16], [38, 59]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [38, 6], [67, 6]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 31,
                      "column": 4
                    },
                    "end": {
                      "line": 68,
                      "column": 4
                    }
                  },
                  "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("    ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "if", [["get", "sideNavValues.freshcaller_config.disabled", ["loc", [null, [32, 12], [32, 53]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [32, 6], [67, 13]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 4
                  },
                  "end": {
                    "line": 68,
                    "column": 4
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "callerConfigCheck", ["loc", [null, [31, 14], [31, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [31, 4], [68, 4]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 4
                },
                "end": {
                  "line": 68,
                  "column": 4
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "s360fdAdapter.isDeskCTIAppEnabled", ["loc", [null, [29, 14], [29, 47]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [29, 4], [68, 4]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 1
              },
              "end": {
                "line": 69,
                "column": 1
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isCTIAppEnabled", ["loc", [null, [27, 10], [27, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [27, 4], [68, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 1
              },
              "end": {
                "line": 77,
                "column": 1
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el2 = dom.createElement("svg");
            dom.setAttribute(el2, "viewBox", "0 0 32 32");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("use");
            dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_loading_spinner");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [72, 64], [72, 76]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [72, 59], [72, 126]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 79,
                    "column": 3
                  },
                  "end": {
                    "line": 81,
                    "column": 3
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("				");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "marketplace-list", [], ["mkpPlaceholderPosition", "left_nav_chat", "setApp", ["subexpr", "action", ["setApp"], [], ["loc", [null, [80, 69], [80, 86]]], 0, 0], "mkpAdapterServiceName", ["subexpr", "@mut", [["get", "marketplaceAdapter", ["loc", [null, [80, 109], [80, 127]]], 0, 0, 0, 0]], [], [], 0, 0], "mkpInterfaceServiceName", ["subexpr", "@mut", [["get", "marketplaceInterface", ["loc", [null, [80, 152], [80, 172]]], 0, 0, 0, 0]], [], [], 0, 0], "parentModalStackName", ["subexpr", "@mut", [["get", "parentModalStack", ["loc", [null, [80, 194], [80, 210]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [80, 4], [80, 212]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 82,
                      "column": 4
                    },
                    "end": {
                      "line": 88,
                      "column": 4
                    }
                  },
                  "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("					");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.setNamespace("http://www.w3.org/2000/svg");
                  var el2 = dom.createElement("svg");
                  dom.setAttribute(el2, "viewBox", "0 0 32 32");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("use");
                  dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_chat_disabled");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n					");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element5, 'class');
                  morphs[1] = dom.createAttrMorph(element5, 'aria-label');
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["s360-chat unified360-widgets-item unified360-list-item unified360-list-item-disabled unified360-disabled ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [83, 127], [83, 139]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [83, 122], [83, 189]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["get", "disabledTooltipText", ["loc", [null, [83, 204], [83, 223]]], 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 90,
                          "column": 6
                        },
                        "end": {
                          "line": 92,
                          "column": 6
                        }
                      },
                      "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("							");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "link-item-tag", [], ["tag", ["subexpr", "@mut", [["get", "freshchatContent", ["loc", [null, [91, 27], [91, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [91, 7], [91, 45]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 88,
                        "column": 4
                      },
                      "end": {
                        "line": 97,
                        "column": 4
                      }
                    },
                    "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("					");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "aria-label", "Chat");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("            ");
                    dom.appendChild(el1, el2);
                    dom.setNamespace("http://www.w3.org/2000/svg");
                    var el2 = dom.createElement("svg");
                    dom.setAttribute(el2, "viewBox", "0 0 32 32");
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("use");
                    dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_chat");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n					");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element4, 'class');
                    morphs[1] = dom.createElementMorph(element4);
                    morphs[2] = dom.createMorphAt(element4, 1, 1);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["s360-chat unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [89, 111], [89, 123]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [89, 106], [89, 173]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["onClickFreshChatIcon"], [], ["loc", [null, [89, 10], [89, 43]]], 0, 0], ["block", "if", [["get", "freshchatContent.content", ["loc", [null, [90, 12], [90, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [90, 6], [92, 13]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 97,
                        "column": 4
                      },
                      "end": {
                        "line": 104,
                        "column": 4
                      }
                    },
                    "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("					");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "aria-label", "Chat");
                    dom.setAttribute(el1, "data-test-chat-disabled-icon", "");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("            ");
                    dom.appendChild(el1, el2);
                    dom.setNamespace("http://www.w3.org/2000/svg");
                    var el2 = dom.createElement("svg");
                    dom.setAttribute(el2, "viewBox", "0 0 32 32");
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("use");
                    dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_chat_disabled");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n					");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n				");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element3, 'class');
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["s360-chat unified360-widgets-item unified360-list-item unified360-list-item-disabled ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [98, 107], [98, 119]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [98, 102], [98, 169]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 88,
                      "column": 4
                    },
                    "end": {
                      "line": 104,
                      "column": 4
                    }
                  },
                  "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "sideNavValues.freshchat_config.fc_enabled", ["loc", [null, [88, 14], [88, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [88, 4], [104, 4]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 81,
                    "column": 3
                  },
                  "end": {
                    "line": 105,
                    "column": 3
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "sideNavValues.freshchat_config.disabled", ["loc", [null, [82, 10], [82, 49]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [82, 4], [104, 11]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 2
                },
                "end": {
                  "line": 106,
                  "column": 2
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isChatAppEnabled", ["loc", [null, [79, 9], [79, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [79, 3], [105, 10]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 1
              },
              "end": {
                "line": 107,
                "column": 1
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "chatConfigCheck", ["loc", [null, [78, 8], [78, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [78, 2], [106, 9]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 2
                },
                "end": {
                  "line": 116,
                  "column": 2
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("		");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.setNamespace("http://www.w3.org/2000/svg");
              var el2 = dom.createElement("svg");
              dom.setAttribute(el2, "viewBox", "0 0 32 32");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("use");
              dom.setAttributeNS(el3, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_loading_spinner");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n		");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element2, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [111, 64], [111, 76]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [111, 59], [111, 126]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 1
              },
              "end": {
                "line": 117,
                "column": 1
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "normalState", ["loc", [null, [110, 8], [110, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [110, 2], [116, 9]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 120,
                      "column": 4
                    },
                    "end": {
                      "line": 131,
                      "column": 4
                    }
                  },
                  "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("					");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "aria-label", "Freshworks Switcher");
                  var el2 = dom.createTextNode("\n						");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("freshworks-omnibar");
                  dom.setAttribute(el2, "data-omnibar-user-role", "admin");
                  var el3 = dom.createTextNode("\n						");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n					");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element0, 'class');
                  morphs[1] = dom.createAttrMorph(element1, 'data-omnibar-environment');
                  morphs[2] = dom.createAttrMorph(element1, 'data-omnibar-product');
                  morphs[3] = dom.createAttrMorph(element1, 'data-omnibar-organisation-domain');
                  morphs[4] = dom.createAttrMorph(element1, 'data-omnibar-account-url-origin');
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["unified360-widgets-item unified360-list-item ", ["subexpr", "if", [["get", "expandedMenu", ["loc", [null, [121, 67], [121, 79]]], 0, 0, 0, 0], "unified360-hint--top", "unified360-hint--right"], [], ["loc", [null, [121, 62], [121, 129]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-environment", ["get", "omnibarConfigData.env", ["loc", [null, [123, 34], [123, 55]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-product", ["get", "omnibarConfigData.product", ["loc", [null, [124, 30], [124, 55]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-organisation-domain", ["get", "omnibarConfigData.org_domain", ["loc", [null, [125, 42], [125, 70]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-account-url-origin", ["get", "omnibarConfigData.ui_url", ["loc", [null, [127, 41], [127, 65]]], 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 119,
                    "column": 3
                  },
                  "end": {
                    "line": 132,
                    "column": 3
                  }
                },
                "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "s360-not-eql", [["get", "omnibarConfigData.disabled", ["loc", [null, [120, 24], [120, 50]]], 0, 0, 0, 0], true], [], ["loc", [null, [120, 10], [120, 56]]], 0, 0]], [], 0, null, ["loc", [null, [120, 4], [131, 11]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 2
                },
                "end": {
                  "line": 133,
                  "column": 2
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "normalState", ["loc", [null, [119, 9], [119, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [119, 3], [132, 10]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 1
              },
              "end": {
                "line": 134,
                "column": 1
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "omnibarConfigData", ["loc", [null, [118, 8], [118, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [118, 2], [133, 9]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 135,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "sideNavValues.freshsuccess_config.fsu_enabled", ["loc", [null, [2, 8], [2, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [10, 9]]]], ["block", "if", [["get", "isFdeskEnabled", ["loc", [null, [11, 8], [11, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 2], [19, 9]]]], ["block", "if", [["get", "showCTILoader", ["loc", [null, [20, 7], [20, 20]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [20, 1], [69, 8]]]], ["block", "if", [["get", "chatInitializeLoading", ["loc", [null, [71, 7], [71, 28]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [71, 1], [107, 8]]]], ["block", "if", [["get", "omnibarInitializeLoading", ["loc", [null, [109, 7], [109, 31]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [109, 1], [134, 8]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 135,
              "column": 0
            },
            "end": {
              "line": 137,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "yield", ["loc", [null, [136, 1], [136, 10]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 139,
              "column": 0
            },
            "end": {
              "line": 141,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "mkp-cti-popup-wrapper", ["loc", [null, [140, 4], [140, 29]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 142,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/widget-items.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isBundleProduct", ["loc", [null, [1, 6], [1, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [137, 7]]]], ["block", "if", [["get", "showCTIPopupWrapper", ["loc", [null, [139, 6], [139, 25]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [139, 0], [141, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});