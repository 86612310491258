define('ember-unified-navbar-360/constants/workspace-switcher', ['exports'], function (exports) {
  'use strict';

  var COMMON_MODULES_URL_REGEX = [/\/crm\/messaging/, // Chat
  /\/crm\/sales\/freddy/, // freddy
  /\/crm\/sales\/personal-settings/, // user settings
  /\/crm\/sales\/settings$/, // admin settings
  /\/crm\/sales\/settings/, // admin settings submodules
  /\/crm\/sales\/contacts/, // contacts
  /\/crm\/sales\/my_dashboards/, // dashboard
  /\/crm\/sales\/analytics/, // analytics
  /\/crm\/phone/, // phone
  /\/crm\/sales\/error/, // error pages
  /\/crm\/sales\/feature-not-available/ // error pages
  ];

  exports.COMMON_MODULES_URL_REGEX = COMMON_MODULES_URL_REGEX;
  var COMMON_MODULES_LOCAL_URL_REGEX = [/\/inbox/, // Chat inbox
  /\/freddy/, // freddy
  /\/personal-settings/, // user settings
  /\/settings$/, // admin settings
  /\/sales\/settings/, // admin settings submodules
  /\/contacts/, // contacts
  /\/my_dashboards/, // dashboard
  /\/analytics/, // analytics
  /\/phone/, // phone
  /\/error/, // error pages
  /\/feature-not-available/ // error pages
  ];

  exports.COMMON_MODULES_LOCAL_URL_REGEX = COMMON_MODULES_LOCAL_URL_REGEX;
  var RELOAD_NEEDED_COMMON_MODULES_URL_REGEX = [/\/crm\/sales\/contacts/];

  exports.RELOAD_NEEDED_COMMON_MODULES_URL_REGEX = RELOAD_NEEDED_COMMON_MODULES_URL_REGEX;
  var RELOAD_NEEDED_COMMON_MODULES_LOCAL_URL_REGEX = [/\/contacts/];

  exports.RELOAD_NEEDED_COMMON_MODULES_LOCAL_URL_REGEX = RELOAD_NEEDED_COMMON_MODULES_LOCAL_URL_REGEX;
  var DASHBOARD_PAGE_NAME = '_activity_dashboard';

  exports.DASHBOARD_PAGE_NAME = DASHBOARD_PAGE_NAME;
  var WORKSPACE_REGEX = {
    freshsales: /\/crm\/marketer/,
    freshmarketer: /\/crm\/marketer/,
    freshmarketerLocal: /\/mas/
  };

  exports.WORKSPACE_REGEX = WORKSPACE_REGEX;
  var WORKSPACE = {
    freshsales: 1,
    freshmarketer: 2
  };

  exports.WORKSPACE = WORKSPACE;
  var WORKSPACE_CONFIG = {
    1: {
      id: 'freshsales',
      name: 'Sales',
      icon: 's360_fsa_circle'
    },
    2: {
      id: 'freshmarketer',
      name: 'Marketing',
      icon: 's360_fm_circle'
    }
  };
  exports.WORKSPACE_CONFIG = WORKSPACE_CONFIG;
});