define('ember-analytics-collector/services/interactivity-tracking', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  /*
   * Constants used for Interactivity Tracking
  */
  var PERFORMANCE_API_SUPPORT = !!(window.performance && window.performance.mark && window.performance.measure);
  var INTERACTIVE_LABEL = 'InteractiveNow';
  var ROUTE_START_MARK = 'PerformanceTracking:RouteStart';
  var ROUTE_RENDER_START_MARK = 'PerformanceTracking:RouteRenderStart';
  var ROUTE_END_MARK = 'PerformanceTracking:RouteInteractive';
  var ROUTE_MEASURE = 'PerformanceTracking:RouteMeasure';
  var RENDER_MEASURE = 'PerformanceTracking:RenderMeasure';
  var BEACON_MEASURE = 'PerformanceTracking:BeaconMeasure';
  var PREFETCH_START_MARK = 'PerformanceTracking:DataPrefetchStart';
  var PREFETCH_END_MARK = 'PerformanceTracking:DataPrefetchEnd';
  var PREFETCH_MEASURE = 'PerformanceTracking:DataPrefetchMeasure';
  var HERO_UPDATE_START_MARK = 'PerformanceTracking:HeroUpdateStart';
  var HERO_UPDATE_END_MARK = 'PerformanceTracking:HeroUpdateEnd';
  var HERO_UPDATE_MEASURE = 'PerformanceTracking:HeroUpdateMeasure';
  var ROUTE_RELOAD_START_MARK = 'PerformanceTracking:RouteReloadStart';
  var ROUTE_RELOAD_MEASURE = 'PerformanceTracking:RouteReloadMeasure';

  var Service = _ember['default'].Service;
  var get = _ember['default'].get;
  var assign = _ember['default'].assign;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var getOwner = _ember['default'].getOwner;
  var setProperties = _ember['default'].setProperties;
  var set = _ember['default'].set;

  var SLOW_MEASURE = 500;
  var startMarkSuffix = 'Start';
  var endMarkSuffix = 'End';

  var routeComponents = [],
      PREF = {
    transitionCount: 0,
    firstRender: {}
  };

  exports['default'] = Service.extend({
    routing: service('-routing'),
    analyticsInitialized: false,
    analyticsContext: null,
    analyticsCollector: { analyticsInterface: window.analyticsInterface, isTracker: true },
    analyticsEnabled: computed('analyticsCollectorConfig', function () {
      var analyticsCollectorConfig = get(this, 'analyticsCollectorConfig');
      return PERFORMANCE_API_SUPPORT && isPresent(analyticsCollectorConfig) && isPresent(analyticsCollectorConfig.analyticsCollectorScript) && isPresent(get(this, 'analyticsCollector')) && isPresent(get(this, 'analyticsCollector').analyticsInterface);
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var appEnvironment = getOwner(this).resolveRegistration('config:environment');

      var _ref = appEnvironment || {};

      var analyticsCollectorConfig = _ref.analyticsCollectorConfig;
      var environment = _ref.environment;

      if (window.FW_ANALYTICS_COLLECTOR) {
        set(this, 'analyticsCollector', window.FW_ANALYTICS_COLLECTOR);
        get(this, 'analyticsCollector').isTracker = false;
      } else {
        /*
         The analyticsCollectorLoaded event will be fired once analytics.js
         is loaded and executed (i..e in the onLoad event of this script) which 
         guarantees that window.FW_ANALYTICS_COLLECTOR is set.
         */
        window.addEventListener('analyticsCollectorLoaded', function () {
          set(_this, 'analyticsCollector', window.FW_ANALYTICS_COLLECTOR);
          get(_this, 'analyticsCollector').isTracker = false;
          var analyticsContext = get(_this, 'analyticsContext');
          if (analyticsContext) {
            _this.setAnalyticsContext(analyticsContext);
            set(_this, 'analyticsContext', null);
          }
          if (get(_this, 'shouldFlushInitialMeasurements') === 1 && PREF.transitionCount > 0) {
            set(_this, 'shouldFlushInitialMeasurements', 0);
            _this.flushInitialMetricsQueue();
          }
        }, { once: true });
      }
      /*
        TODO: The currentHostname property has been added here
        to be able to ship this change quickly. It needs to be
        moved to the analytics-collector later since it is a
        product/framework-agnostic parameter.
      */
      setProperties(this, {
        analyticsCollectorConfig: analyticsCollectorConfig,
        isDevelopment: environment == 'development',
        beaconsPresentInView: 0,
        beaconsToBeAdded: 0,
        beaconsToBeRemoved: 0,
        beaconsAdded: 0,
        beaconsRemoved: 0,
        transitioningFrom: '',
        firstMeaningfulPaintDone: 0,
        inRouteMeaningfulPaints: 0,
        currentHostname: window.location.host,
        shouldFlushInitialMeasurements: 1
      });
    },

    /**
     * @function trackOnce
     * @description Sends metrics to analytics collector/metricQueue only once
     * @param {String} logName - Name of the log to be sent
     */
    trackOnce: function trackOnce(logName) {
      if (!get(this, 'analyticsEnabled')) {
        return;
      }
      var key = logName.toLowerCase();
      if (!PREF.firstRender[key]) {
        this._sendMetrics({ logName: logName, duration: get(this, 'analyticsCollector').analyticsInterface.getPerfTime(), meta: { firstRender: true } });
        PREF.firstRender[key] = true;
      }
    },

    /**
     * @function trackComponent
     * @description Push name and duration sent to routeComponents 
     * @param {String} name - Log name
     * @param {Number} duration - Time duration for the log
     */
    trackComponent: function trackComponent(name, duration) {
      routeComponents.push({
        name: name,
        duration: duration
      });
    },

    /**
     * @function logPaintTimings
     * @description Sends data to analytics collector for the performance entries of type paint
     */
    logPaintTimings: function logPaintTimings() {
      var _this2 = this;

      if (!get(this, 'analyticsEnabled')) {
        return;
      }
      var paintEntries = performance.getEntriesByType('paint') || [];
      paintEntries.forEach(function (paintEntry) {
        var duration = paintEntry.startTime;
        var logName = paintEntry.name;

        _this2._sendMetrics({ logName: logName, duration: duration });
      });
    },

    /**
     * @function trackWillTransition
     * @description Called when a transition is about to happen with the current URL
     * @param {String} currentUrl - Current page's URL
     */
    trackWillTransition: function trackWillTransition(currentUrl) {
      if (!get(this, 'analyticsEnabled')) {
        return;
      }

      // TODO: Evaluate whether this is necessary. URL state update is now getting
      // handled in the trackDidTransition method.
      set(this, 'transitioningFrom', currentUrl);

      /*
        The following conditions are required for clearing start marks reliably
         1. All start marks need to be cleared during the first willTransition
            after metrics have been reported in any route. This is to prevent
            further beacons in the route from using the start mark set in the
            willTransition hook in the previous route. The next batch of metrics
            should instead use the new start mark that is made after clearing
            the previous set of start marks.
         2. When the beaconsPresentInView >= 0, the following sub-cases are
            possible,
            - beaconsPresentInView = 0. This could occur in any willTransition
              triggered in uninstrumented routes. When transitioning from a route
              without beacons to a route with beacons, any willTransitions called
              before the beacons in the target route have completed rendering, will
              have a beaconsPresentInView count of zero since beacon renders wouldn't
              have resolved. In these cases, the start marks shouldn't be cleared
              and these cases are filtered out by the additional conditions for
              beaconsToBeAdded and beaconsToBeRemoved to equal zero.
            - beaconsPresentInView > 0. This could occur in any willTransition calls
              triggered in routes which have a beacon in place, but haven't reported
              their metrics due to errors in beacon registration. In such cases, the
              start marks need to be cleared properly, failing which the metrics in
              the next route will get affected since they'll refer to start marks
              placed 2 transitions before them. This case is handled by the combination
              of beaconsPresentInView > 0, but beaconsToBeAdded and beaconsToBeRemoved
              equalling zero.
      */
      if (get(this, 'inRouteMeaningfulPaints') > 0 || get(this, 'beaconsPresentInView') >= 0 && get(this, 'beaconsToBeAdded') === 0 && get(this, 'beaconsToBeRemoved') === 0) {
        performance.clearMarks(ROUTE_START_MARK);
        performance.clearMarks(HERO_UPDATE_START_MARK);
      }

      performance.mark(ROUTE_START_MARK);

      if (PREF.transitionCount === 0) {
        var logName = 'FirstTransitionStarted';
        this._sendMetrics({ logName: logName, duration: get(this, 'analyticsCollector').analyticsInterface.getPerfTime() });
        this.logPaintTimings();
      }
    },

    /**
     * @function trackDidTransition
     * @description Called once a transition to a route is complete
     * @param {String} transitioningTo - URL of the transitioned route
     */
    trackDidTransition: function trackDidTransition(transitioningTo) {
      if (!get(this, 'analyticsEnabled')) {
        return;
      }

      // Reset states only for transitions involving path-changes. Avoid
      // state resets for route-reloads.
      if (get(this, 'transitioningFrom') !== transitioningTo || PREF.transitionCount === 0) {
        // Flush measurements made before the analytics-collector was loaded
        if (get(this, 'shouldFlushInitialMeasurements') === 1 && !get(this, 'analyticsCollector').isTracker) {
          set(this, 'shouldFlushInitialMeasurements', 0);
          this.flushInitialMetricsQueue();
        }

        /*
          TODO: Check if this is required going forward and remove if not needed.
          Update the current route name to ensure that the New Relic Browser
          SPA tool is aware of the current route whenever there is a change.
        */
        var currentRouteName = get(this, 'routing.currentRouteName');
        this.setCurrentRoute(currentRouteName);

        /*
          Route transitions with path-changes are recorded to capture an
          overall count of route-transitions (with/without beacon tracking)
          This count can be compared with the counts of transitions with
          beacons to estimate the number of route transitions which are not
          covered by beacon instrumentation.s
        */
        this._sendMetrics({
          logName: 'LogRouteTransition',
          duration: 0
        });

        setProperties(this, {
          inRouteMeaningfulPaints: 0,
          transitioningFrom: transitioningTo
        });
        PREF.transitionCount++;
        performance.clearMarks(ROUTE_RELOAD_START_MARK);
        performance.clearMarks(ROUTE_RENDER_START_MARK);
        performance.clearMarks(ROUTE_END_MARK);
        performance.clearMarks(HERO_UPDATE_END_MARK);
      } else {
        // When route reloads do occur, set a separate start mark to
        // measure the route reload time as an in-route transition
        performance.clearMarks(ROUTE_RELOAD_START_MARK);
        performance.mark(ROUTE_RELOAD_START_MARK);
      }

      performance.mark(ROUTE_RENDER_START_MARK);
    },

    registerBeaconAddition: function registerBeaconAddition() {
      this.incrementProperty('beaconsToBeAdded');
    },

    registerBeaconRemoval: function registerBeaconRemoval() {
      this.incrementProperty('beaconsToBeRemoved');
    },

    resolveBeaconAddition: function resolveBeaconAddition() {
      this.incrementProperty('beaconsAdded');
    },

    resolveBeaconRemoval: function resolveBeaconRemoval() {
      this.incrementProperty('beaconsRemoved');
    },

    /**
     * @function reportMetricsIfPossible
     * @description Checks if metrics can be sent based on beacons added and removed
     */
    reportMetricsIfPossible: function reportMetricsIfPossible() {
      /*
        Once all beacons that need to be removed have been removed, and
        all beacons that need to be added, have been added, the beacon
        render operations can be considered resolved. At this stage, if
        there are beacons present in the view after these updates, the
        execution can proceed to report metrics.
      */
      if (get(this, 'beaconsAdded') === get(this, 'beaconsToBeAdded') && get(this, 'beaconsRemoved') === get(this, 'beaconsToBeRemoved')) {
        var beaconsDelta = get(this, 'beaconsAdded') - get(this, 'beaconsRemoved');
        setProperties(this, {
          'beaconsPresentInView': get(this, 'beaconsPresentInView') + beaconsDelta,
          'beaconsToBeAdded': 0,
          'beaconsToBeRemoved': 0,
          'beaconsAdded': 0,
          'beaconsRemoved': 0
        });

        if (get(this, 'beaconsPresentInView') > 0) {
          this._trackRoute();
        }
      }
    },

    /**
     * @private
     * @function _trackRoute
     * @description Sends various metrics based on the marks set
     */
    _trackRoute: function _trackRoute() {
      if (!get(this, 'analyticsEnabled')) {
        return;
      }

      try {
        var firstTransition = PREF.transitionCount === 1;

        /*
          If a MeaningfulPaint has already been reported in the current route,
          all further reporting should be done as InRouteTransitions since
          they'll involve the measurements of updates to this route's critical
          UI elements without a transition to another URL.
        */
        if (get(this, 'inRouteMeaningfulPaints') > 0) {
          /*
            When measuring in-route transitions, hero updates (where beacons
            are destroyed and re-rendered are prioritized since they're more
            granular in nature. If there are no hero updates, route reloads
            are measured instead, if detected.
          */
          if (performance.getEntriesByName(HERO_UPDATE_START_MARK).length > 0) {
            var _getMeasure = this.getMeasure(HERO_UPDATE_MEASURE, HERO_UPDATE_START_MARK, HERO_UPDATE_END_MARK);

            var duration = _getMeasure.duration;
            var startTime = _getMeasure.startTime;

            this._sendMetrics({
              logName: 'MeaningfulPaint',
              duration: duration,
              meta: {
                startTime: startTime,
                firstTransition: firstTransition,
                transitionType: 'InRouteTransition'
              }
            });

            /*
              Clear the hero update start marks once the hero update measurement
              has been reported. This is because multiple beacons may be replaced
              in a view and can create multiple start marks. However, the earliest
              start mark is used during the measurement to ensure the time taken
              to replace all beacons is considered. If the marks aren't cleared at
              this stage, subsequent hero udpate measurements in the same route
              will end up using the earliest start mark from the previous hero
              update, resulting in continuously increasing measurement values.
            */
            performance.clearMarks(HERO_UPDATE_START_MARK);
          } else if (performance.getEntriesByName(ROUTE_RELOAD_START_MARK).length > 0) {
            var _getMeasure2 = this.getMeasure(ROUTE_RELOAD_MEASURE, ROUTE_RELOAD_START_MARK, ROUTE_END_MARK);

            var duration = _getMeasure2.duration;
            var startTime = _getMeasure2.startTime;

            this._sendMetrics({
              logName: 'MeaningfulPaint',
              duration: duration,
              meta: {
                startTime: startTime,
                firstTransition: firstTransition,
                transitionType: 'InRouteTransition'
              }
            });
          }
        } else {
          // It's the first MeaningfulPaint in this route
          var firstInteractionDuration = this.getMeasure('FirstInteractionDuration', undefined, ROUTE_END_MARK).duration;

          /*
            If it's also the first MeaningfulPaint in this Ember context, i.e.
            it's the MeainigfulPaint that signifies initial load time (including
            HTML download, asset downloads, and Ember boot-up).
          */
          if (get(this, 'firstMeaningfulPaintDone') === 0) {
            set(this, 'firstMeaningfulPaintDone', 1);

            /*
              Instrumentation for prefetch measurements is often included
              well before an Ember vendor/app bundle has loaded. For this
              reason, the prefetch marks can't be made in lifecycle methods
              within this addon. Due to this constraint, there is no guarantee
              that the prefetch marks will exist in the window's performance
              entries. To avoid a performance.measure exception, the following
              check is required.
              TODO: Check if the constants for performance marks and measures
              can be made configurable during the instantiation of the addon
              with a standard set of defined measurements (e.g. routeStart, 
              routeEnd, routeRenderStart, etc.) which can be marked by a consumer
              even outside the context of this addon.
            */
            if (performance.getEntriesByName(PREFETCH_START_MARK).length > 0 && performance.getEntriesByName(PREFETCH_END_MARK).length > 0) {
              var _getMeasure3 = this.getMeasure(PREFETCH_MEASURE, PREFETCH_START_MARK, PREFETCH_END_MARK);

              var _duration = _getMeasure3.duration;
              var _startTime = _getMeasure3.startTime;

              this._sendMetrics({
                logName: 'DataPrefetchDuration',
                duration: _duration,
                meta: { startTime: _startTime }
              });
            }
          }

          var _getMeasure4 = this.getMeasure(ROUTE_MEASURE, ROUTE_START_MARK, ROUTE_END_MARK);

          var duration = _getMeasure4.duration;
          var startTime = _getMeasure4.startTime;

          this._sendMetrics({
            logName: 'RouteInteractionReady',
            duration: duration,
            meta: {
              startTime: startTime,
              firstTransition: firstTransition
            }
          });

          this._sendMetrics({
            logName: 'MeaningfulPaint',
            duration: firstTransition ? firstInteractionDuration : duration,
            meta: {
              startTime: firstTransition ? performance.timing.navigationStart : startTime,
              firstTransition: firstTransition
            }
          });

          var _getMeasure5 = this.getMeasure(RENDER_MEASURE, ROUTE_RENDER_START_MARK, ROUTE_END_MARK);

          var renderDuration = _getMeasure5.duration;
          var renderStartTime = _getMeasure5.startTime;

          this._sendMetrics({
            logName: 'RouteRenderTime',
            duration: renderDuration,
            meta: {
              startTime: renderStartTime,
              firstTransition: firstTransition
            }
          });
        }

        if (get(this, 'isDevelopment')) {
          // Printing list of components for this page.
          this._logRouteComponents();
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    },

    /**
     * @private
     * @function _logRouteComponents
     * @description Logs the various metrics to console, this is called only for development environment
     */
    _logRouteComponents: function _logRouteComponents() {
      if (routeComponents.length === 0) {
        return;
      }

      var routeName = get(this, 'routing.currentRouteName');
      var groupName = 'COMPONENT-PERFORMANCE : ' + routeName;
      console.groupCollapsed(groupName); // eslint-disable-line no-console
      console.log('PERFORMANCE : Page components that are taking more then ' + SLOW_MEASURE + 'ms to load in this page'); // eslint-disable-line no-console
      routeComponents.filter(function (_ref2) {
        var duration = _ref2.duration;

        return duration > SLOW_MEASURE;
      }).forEach(function (_ref3) {
        var name = _ref3.name;
        var duration = _ref3.duration;

        console.log('PERFORMANCE', ':', name, ':', duration); // eslint-disable-line no-console
      });
      console.groupEnd(); // eslint-disable-line no-console
      this._clearRouteComponents();
    },

    /**
     * @private
     * @function _clearRouteComponents
     * @description Empties routeComponents array
     */
    _clearRouteComponents: function _clearRouteComponents() {
      routeComponents = [];
    },

    /**
     * @function trackBeacon
     * @description Calculates and sends metrics for the log Name
     * @param {String} logName - Logname
     * @param {Boolean} withinRoute - Flag indicating if transition is within same route
     */
    trackBeacon: function trackBeacon(logName, withinRoute) {
      if (!get(this, 'analyticsEnabled')) {
        return;
      }

      try {
        var measureName = BEACON_MEASURE + ':' + logName;
        var interactivityMarkName = INTERACTIVE_LABEL + ':' + logName;
        if (withinRoute) {
          var _getMeasure6 = this.getMeasure(measureName, ROUTE_START_MARK, interactivityMarkName);

          var duration = _getMeasure6.duration;
          var startTime = _getMeasure6.startTime;

          this._sendMetrics({
            logName: logName,
            duration: duration,
            meta: { startTime: startTime }
          });
        } else {
          this._sendMetrics({ logName: logName, duration: get(this, 'analyticsCollector').analyticsInterface.getPerfTime() });
        }
        performance.clearMarks(interactivityMarkName);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    },

    /**
     * @function trackTask
     * @description Calculates duration based on the logName
     * @param {String} logName - Logname for which metrics needs to be sent
     * @param {Object} options - Additional options to be assigned to meta
     */
    trackTask: function trackTask(logName) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (!get(this, 'analyticsEnabled')) {
        return;
      }
      try {
        this.setMark(logName, true);
        var taskStartMark = '' + logName + startMarkSuffix;
        var taskEndMark = '' + logName + endMarkSuffix;

        var _getMeasure7 = this.getMeasure(logName, taskStartMark, taskEndMark);

        var duration = _getMeasure7.duration;
        var startTime = _getMeasure7.startTime;

        var meta = assign(options, { startTime: startTime });
        this._sendMetrics({ logName: logName, meta: meta, duration: duration });
        performance.clearMarks(taskStartMark);
        performance.clearMarks(taskEndMark);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    },

    /**
     * @function getMeasure
     * @description Gets the duration for the measureName based on routeStartMarkName and interactivityMarkName
     * @param {String} measureName - Name of the measure set
     * @param {String} routeStartMarkName - Start mark for the duration to be retrived
     * @param {String} interactivityMarkName - End mark for the duration to be retrived
     * @param {String} useEarliestStartMark - Use first start mark if multiple start marks present if set to true, use later otherwise
     * @returns {Object} Returns duration and startTime
     */
    getMeasure: function getMeasure(measureName, routeStartMarkName, interactivityMarkName) {
      var useEarliestStartMark = arguments.length <= 3 || arguments[3] === undefined ? true : arguments[3];

      try {
        /*
          There are two cases in which the native performance.measure API needs
          to be used. One is when the start mark is of type undefined. In this
          case, the native API's behavior is to use the navigation start time as
          the starting time for the measurement. The other use case is when there
          are multiple start marks in the same name, and the latest start mark
          is to be used in the measurement (which is the default behavior of the
          native measure API). For all other cases, the earliest start mark and
          the latest end mark need to be used since route transitions may involve
          more than one hop based on the application architecture and the measurement
          shouldn't cover only the time taken for the latest hop/transition.
        */
        var analyticsInterface = get(this, 'analyticsCollector').analyticsInterface;

        if (typeof routeStartMarkName === 'undefined' || !useEarliestStartMark) {
          performance.measure(measureName, routeStartMarkName, interactivityMarkName);

          var _performance$getEntriesByName$pop = performance.getEntriesByName(measureName).pop();

          var duration = _performance$getEntriesByName$pop.duration;
          var startTime = _performance$getEntriesByName$pop.startTime;

          duration = analyticsInterface.formatTimestamp(duration);
          performance.clearMeasures(measureName);

          return { duration: duration, startTime: startTime };
        }

        var startEntry = performance.getEntriesByName(routeStartMarkName)[0];
        var endEntry = performance.getEntriesByName(interactivityMarkName).pop();

        return {
          duration: analyticsInterface.formatTimestamp(endEntry.startTime - startEntry.startTime),
          startTime: startEntry.startTime
        };
      } catch (e) {
        // TODO: Need to assess the impact of zero values in the metrics
        // and revisit the return values later.
        return { duration: 0, startTime: 0 };
      }
    },

    /**
     * @function setMark
     * @description Sets performance mark for the measureName
     * @param {String} measureName - Name of the mark to be set
     * @param {Boolean} endMarkFlag - Appends End to measureName if true, else Start
     */
    setMark: function setMark(measureName) {
      var endMarkFlag = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      if (!get(this, 'analyticsEnabled')) {
        return;
      }
      performance.mark('' + measureName + (endMarkFlag ? endMarkSuffix : startMarkSuffix));
    },

    /**
     * @function initializeMetricsCollector
     * @description Called from client applications once app is Loaded which sets analyticsInitialized
     * @param {Object} context - Application specific context to be set 
     */
    initializeMetricsCollector: function initializeMetricsCollector(context) {
      if (isPresent(get(this, 'analyticsCollector')) && !get(this, 'analyticsInitialized')) {
        set(this, 'analyticsInitialized', true);
        this.setAnalyticsContext(context);
      }
    },

    /**
     * @function flushInitialMetricsQueue
     * @description Flushes tracker queue and sends metrics to collector.
     */
    flushInitialMetricsQueue: function flushInitialMetricsQueue() {
      var _this3 = this;

      /*
      window.analyticsInterface is deliberately used here instead of
      get(this, 'analyticsCollector').analyticsInterface to flush the initialMetrics
      that are stored in tracker's queue. Since, get(this, 'analyticsCollector') can return
      collector once it is loaded and flushMetricQueue API is only exposed by tracker to
      retrieve it's queue metrics.
      */
      var metricQueue = window.analyticsInterface.flushMetricQueue();
      metricQueue.forEach(function (metric) {
        _this3._sendMetrics(metric);
      });
    },

    /**
     * @function setAnalyticsContext
     * @description Intializes user context which will be sent for all events tracked
     * @param {Object} context - context that needs to be sent for all events
     */
    setAnalyticsContext: function setAnalyticsContext(context) {
      var analyticsCollector = get(this, 'analyticsCollector');
      if (analyticsCollector.isTracker) {
        set(this, 'analyticsContext', context);
      } else {
        analyticsCollector.setUserContext && analyticsCollector.setUserContext(context);
      }
    },

    /**
     * @function setCurrentRoute
     * @description Sets the routeName to be sent
     * @param {String} routeName - Name of the route to be set
     */
    setCurrentRoute: function setCurrentRoute(routeName) {
      var analyticsCollector = get(this, 'analyticsCollector');
      if (analyticsCollector.isTracker) {
        return;
      }
      analyticsCollector.setCurrentRoute && analyticsCollector.setCurrentRoute(routeName);
    },

    /**
     * @private
     * @function _sendMetrics
     * @description Sends data to analytics collector if analytics.js loaded, else pushes to tracker's queue
     * @param {Object} data - data to be sent
     */
    _sendMetrics: function _sendMetrics(data) {
      if (get(this, 'analyticsEnabled')) {
        var analyticsCollector = get(this, 'analyticsCollector');
        var logName = data.logName;

        if (analyticsCollector.isTracker) {
          analyticsCollector.analyticsInterface.trackEvent(data);
        } else {
          var value = data.duration;
          var _data$meta = data.meta;
          var meta = _data$meta === undefined ? {} : _data$meta;

          var routeName = get(this, 'routing.currentRouteName');
          var transitionCount = PREF.transitionCount;

          var metricPayload = {
            logName: logName,
            value: value,
            meta: assign(meta, {
              routeName: routeName,
              transitionCount: transitionCount,
              hostname: get(this, 'currentHostname')
            })
          };
          analyticsCollector.trackEvent(metricPayload);
        }

        if (logName === 'MeaningfulPaint') {
          this.incrementProperty('inRouteMeaningfulPaints');
        }
      }
    }
  });
});