define('ember-unified-navbar-360/helpers/s360-display-count', ['exports'], function (exports) {
  'use strict';

  exports.s360DisplayCount = s360DisplayCount;

  function s360DisplayCount(params /* , hash*/) {
    if (params[0] > 99) {
      return '99+';
    } else {
      return params;
    }
  }

  exports['default'] = Ember.Helper.helper(s360DisplayCount);
});