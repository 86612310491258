define('ember-crumbly/components/bread-crumbs', ['exports', 'ember', 'ember-crumbly/templates/components/bread-crumbs', 'ember-new-computed', 'ember-getowner-polyfill'], function (exports, _ember, _emberCrumblyTemplatesComponentsBreadCrumbs, _emberNewComputed, _emberGetownerPolyfill) {
  'use strict';

  var _get = _ember['default'].get;
  var Component = _ember['default'].Component;
  var getWithDefault = _ember['default'].getWithDefault;
  var assert = _ember['default'].assert;
  var typeOf = _ember['default'].typeOf;
  var setProperties = _ember['default'].setProperties;
  var emberArray = _ember['default'].A;
  var classify = _ember['default'].String.classify;
  var bool = _emberNewComputed['default'].bool;
  var readOnly = _emberNewComputed['default'].readOnly;

  exports['default'] = Component.extend({
    layout: _emberCrumblyTemplatesComponentsBreadCrumbs['default'],
    tagName: 'ol',
    linkable: true,
    reverse: false,
    classNameBindings: ['breadCrumbClass'],
    hasBlock: bool('template').readOnly(),
    currentUrl: readOnly('applicationRoute.router.url'),
    currentRouteName: readOnly('applicationRoute.controller.currentRouteName'),

    routeHierarchy: (0, _emberNewComputed['default'])('currentUrl', 'currentRouteName', 'reverse', {
      get: function get() {
        var currentRouteName = getWithDefault(this, 'currentRouteName', false);

        assert('[ember-crumbly] Could not find a curent route', currentRouteName);

        var routeNames = currentRouteName.split('.');
        var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
        var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

        return _get(this, 'reverse') ? crumbs.reverse() : crumbs;
      }
    }).readOnly(),

    breadCrumbClass: (0, _emberNewComputed['default'])('outputStyle', {
      get: function get() {
        var className = 'breadcrumb';
        var outputStyle = getWithDefault(this, 'outputStyle', '');
        var lowerCaseOutputStyle = outputStyle.toLowerCase();

        if (lowerCaseOutputStyle === 'foundation') {
          className = 'breadcrumbs';
        }

        return className;
      }
    }).readOnly(),

    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        var path = name + '.index';

        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },

    _filterIndexAndLoadingRoutes: function _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(function (name) {
        return !(name === 'index' || name === 'loading');
      });
    },

    _lookupRoute: function _lookupRoute(routeName) {
      return (0, _emberGetownerPolyfill['default'])(this).lookup('route:' + routeName);
    },

    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = _get(this, 'linkable');
      var pathLength = routeNames.length;
      var breadCrumbs = filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);
        var route = _this._lookupRoute(path);
        var crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        assert('[ember-crumbly] `route:' + path + '` was not found', route);

        var breadCrumb = getWithDefault(route, 'breadCrumb', {
          title: classify(name)
        });

        if (typeOf(breadCrumb) === 'null') {
          return;
        } else {
          setProperties(breadCrumb, {
            path: path,
            linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
          });
        }

        return breadCrumb;
      });

      return emberArray(breadCrumbs.filter(function (breadCrumb) {
        return typeOf(breadCrumb) !== 'undefined';
      }));
    }
  });
});