define("ember-unified-navbar-360/helpers/s360-not-eql", ["exports"], function (exports) {
  "use strict";

  exports.s360NotEql = s360NotEql;

  function s360NotEql(params /* , hash*/) {
    return params[0] !== params[1];
  }

  exports["default"] = Ember.Helper.helper(s360NotEql);
});