define("ember-unified-navbar-360/templates/components/fd-mkp-cti-trigger", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/fd-mkp-cti-trigger.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "unified360-widgets-item unified360-hint--tooltip unified360-padding-0");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "role", "button");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "class", "unified360-markeplace-apps-icon-size unified360-valign");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'aria-label');
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createAttrMorph(element1, 'onClick');
        morphs[3] = dom.createAttrMorph(element2, 'src');
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "aria-label", ["concat", [["get", "s360fdAdapter.ctiApp.displayName", ["loc", [null, [3, 16], [3, 48]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["unified360-standard-width-height ", ["subexpr", "if", [["get", "s360fdAdapter.isCTIAppOpen", ["loc", [null, [6, 49], [6, 75]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [6, 44], [6, 86]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["toggleApp"], [], ["loc", [null, [null, null], [7, 34]]], 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "s360fdAdapter.ctiApp.iconURL", ["loc", [null, [10, 13], [10, 41]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "fd-mkp-cti-app", [], ["app", ["subexpr", "@mut", [["get", "s360fdAdapter.ctiApp", ["loc", [null, [14, 21], [14, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "isCTIAppOpen", ["subexpr", "@mut", [["get", "s360fdAdapter.isCTIAppOpen", ["loc", [null, [14, 55], [14, 81]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 0], [14, 83]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});