define('ember-unified-navbar-360/helpers/s360-totitlecase', ['exports'], function (exports) {
  'use strict';

  exports.s360Totitlecase = s360Totitlecase;

  function s360Totitlecase(params /* , hash*/) {
    var str = params[0];
    if (str && str.length) {
      str = str.split(' ');
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    }
    return params;
  }

  exports['default'] = Ember.Helper.helper(s360Totitlecase);
});