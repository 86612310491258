define('ember-unified-navbar-360/components/fd-mkp-cti-app', ['exports', 'ember-unified-navbar-360/templates/components/fd-mkp-cti-app', 'ember-unified-navbar-360/utils/regex-utils'], function (exports, _emberUnifiedNavbar360TemplatesComponentsFdMkpCtiApp, _emberUnifiedNavbar360UtilsRegexUtils) {
  'use strict';

  var Component = Ember.Component,
      service = Ember.inject.service,
      get = Ember.get,
      getProperties = Ember.getProperties,
      isBlank = Ember.isBlank;

  exports['default'] = Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsFdMkpCtiApp['default'],
    app: null,
    s360fdAdapter: service('360-fd-marketplace-adapter'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.subscribeResize();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unsubscribeResize();
    },

    /**
     * subscribeMarketplaceEvents - initiate the events for the iframe
     * Instance close will be triggered in Interface method of adapter
     */
    subscribeResize: function subscribeResize() {
      var _this = this;

      if (!this.app) {
        return;
      }
      var instanceId = this.app.element.id;
      this.get('s360fdAdapter').on('resize_' + instanceId, function (data) {
        _this.resize(data);
      });
    },
    resize: function resize(options) {
      var element = get(this, 'element');
      if (isBlank(element)) {
        return;
      }
      var ctiContent = element.querySelector('#unified360-marketplace-cti-content');

      var _getProperties = getProperties(options, 'height', 'width'),
          height = _getProperties.height,
          width = _getProperties.width;

      if (_emberUnifiedNavbar360UtilsRegexUtils['default'].isDimensionUnit(height)) {
        ctiContent.style.height = height;
      }
      if (_emberUnifiedNavbar360UtilsRegexUtils['default'].isDimensionUnit(width)) {
        ctiContent.style.width = width;
      }
    },

    /**
     * unsubscribeMarketplaceEvents - destroy the events on the iframe after the iframe is closed
     */
    unsubscribeResize: function unsubscribeResize() {
      if (!this.app) {
        return;
      }
      var instanceId = this.app.id;
      this.get('s360fdAdapter').off('resize_' + instanceId);
    }
  });
});