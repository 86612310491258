define('ember-form/components/ui-radio/component', ['exports', 'ember', 'ember-form/components/ui-radio/template'], function (exports, _ember, _emberFormComponentsUiRadioTemplate) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiRadioTemplate['default'],
    tagName: "label",
    classNames: ['center-block'],
    checked: false,
    updateChecked: (function () {
      this.set('checked', this.get('parentView.value') === this.get('value'));
    }).on('init').observes('parentView.value'),
    labelText: computed('parentView.optionLabelPath', 'content', {
      get: function get() {
        // TODO: identical code can be moved into a util
        if (this.get('parentView.optionLabelPath')) {
          return this.get(this.get('parentView.optionLabelPath'));
        } else {
          return null;
        }
      }
    }),
    value: computed('parentView.optionValuePath', 'content', {
      get: function get() {
        // TODO: identical code can be moved into a util
        if (this.get('parentView.optionValuePath')) {
          return this.get(this.get('parentView.optionValuePath'));
        } else {
          return null;
        }
      }
    }),
    change: function change() {
      this.set('parentView.value', this.get('value'));
    }
  });
});