define('ember-copy/index', ['exports', 'ember-copy/copy', 'ember-copy/copyable'], function (exports, _emberCopyCopy, _emberCopyCopyable) {
  'use strict';

  Object.defineProperty(exports, 'copy', {
    enumerable: true,
    get: function get() {
      return _emberCopyCopy['default'];
    }
  });
  Object.defineProperty(exports, 'Copyable', {
    enumerable: true,
    get: function get() {
      return _emberCopyCopyable['default'];
    }
  });
});