define('ember-data-table/components/ui-pagination-item/component', ['exports', 'ember', 'ember-data-table/components/ui-pagination-item/template'], function (exports, _ember, _emberDataTableComponentsUiPaginationItemTemplate) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiPaginationItemTemplate['default'],
    tagName: 'li',
    classNameBindings: ['isActive:active'],
    seperator: '…',

    url: computed('urlTemplate', 'page', {
      get: function get() {
        var urlTemplate = this.get('urlTemplate');
        var current = this.get('page');

        urlTemplate = urlTemplate.replace('{current}', current);

        return urlTemplate;
      }
    }),

    isActive: computed('page', 'selected', {
      get: function get() {
        return this.get('page') === this.get('selected');
      }
    }),

    isDots: computed('page', {
      get: function get() {
        var seperator = this.get('seperator');
        var page = this.get('page');

        return page === seperator;
      }
    }),

    actions: {
      setCurrent: function setCurrent() {
        var last = this.get('selected');
        var page = this.get('page');

        if (page !== last) {
          this.set('selected', page);
          this.sendAction('pageSet', page, last);
        }
      }
    }
  });
});