define("ember-form/components/ui-radio/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "modules/ember-form/components/ui-radio/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "radio");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'name');
        morphs[1] = dom.createAttrMorph(element0, 'checked');
        morphs[2] = dom.createAttrMorph(element0, 'value');
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "name", ["get", "parentView.name", ["loc", [null, [1, 14], [1, 29]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["get", "checked", ["loc", [null, [1, 55], [1, 62]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "value", ["loc", [null, [1, 73], [1, 78]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "labelText", ["loc", [null, [2, 6], [2, 19]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});