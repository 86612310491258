define('ember-unified-navbar-360/components/fd-mkp-cti-trigger', ['exports', 'ember-unified-navbar-360/templates/components/fd-mkp-cti-trigger'], function (exports, _emberUnifiedNavbar360TemplatesComponentsFdMkpCtiTrigger) {
  'use strict';

  var Component = Ember.Component,
      service = Ember.inject.service;

  exports['default'] = Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsFdMkpCtiTrigger['default'],
    tagName: '',
    s360fdAdapter: service('360-fd-marketplace-adapter'),

    actions: {
      toggleApp: function toggleApp() {
        this.get('s360fdAdapter').toggleCTIAppstate();
      }
    }
  });
});