define('ember-unified-navbar-360/services/workspace-switcher', ['exports', 'ember-unified-navbar-360/utils/storage-keys', 'ember-unified-navbar-360/constants/workspace-switcher'], function (exports, _emberUnifiedNavbar360UtilsStorageKeys, _emberUnifiedNavbar360ConstantsWorkspaceSwitcher) {
  'use strict';

  var Service = Ember.Service,
      readOnly = Ember.computed.readOnly,
      setProperties = Ember.setProperties,
      set = Ember.set,
      get = Ember.get,
      getProperties = Ember.getProperties;

  exports['default'] = Service.extend({
    router: Ember.inject.service(),
    currentRouteName: readOnly('router.currentRouteName'),
    previousUrl: '',
    currentWorkSpace: null,
    availableWorkSpaces: [],
    isCommonModule: false,

    checkURLPatternMatch: function checkURLPatternMatch() {
      var regex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new RegExp();

      return (location.href || '').match(regex) !== null;
    },
    getWorkSpace: function getWorkSpace() {
      return Number(this.get('currentWorkSpace'));
    },
    setWorkSpace: function setWorkSpace() {
      var workspace = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE.freshsales;

      set(this, 'currentWorkSpace', workspace);
      (0, _emberUnifiedNavbar360UtilsStorageKeys.setLocalStorage)('currentWorkSpace', workspace);
    },
    setAvailableWorkSpaces: function setAvailableWorkSpaces(workspaces) {
      set(this, 'availableWorkSpaces', workspaces);
    },
    getIsReloadNeeded: function getIsReloadNeeded() {
      var _this = this;

      var COMMON_MOD_REGEX = this.checkURLPatternMatch(/\/crm\//) ? _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.RELOAD_NEEDED_COMMON_MODULES_URL_REGEX : _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.RELOAD_NEEDED_COMMON_MODULES_LOCAL_URL_REGEX;
      return COMMON_MOD_REGEX.some(function (regex) {
        return _this.checkURLPatternMatch(regex);
      });
    },
    setWorkSpaceOnRouteChange: function setWorkSpaceOnRouteChange(defaultWorkspace) {
      var _this2 = this;

      var _getProperties = getProperties(this, 'previousUrl', 'availableWorkSpaces'),
          previousUrl = _getProperties.previousUrl,
          availableWorkSpaces = _getProperties.availableWorkSpaces;

      if (previousUrl === location.href || !(availableWorkSpaces || []).length) {
        return;
      }

      var getWorkspaceIfAvailable = function getWorkspaceIfAvailable(workspace) {
        return availableWorkSpaces.includes(workspace) ? workspace : availableWorkSpaces[0];
      };

      var COMMON_MOD_REGEX = this.checkURLPatternMatch(/\/crm\//) ? _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.COMMON_MODULES_URL_REGEX : _emberUnifiedNavbar360ConstantsWorkspaceSwitcher.COMMON_MODULES_LOCAL_URL_REGEX;
      var isCommonRoute = COMMON_MOD_REGEX.some(function (regex) {
        return _this2.checkURLPatternMatch(regex);
      });
      var workspaceSelected = Number((0, _emberUnifiedNavbar360UtilsStorageKeys.getLocalStorage)('currentWorkSpace') || defaultWorkspace);
      var workspace = getWorkspaceIfAvailable(workspaceSelected);
      if (this.checkURLPatternMatch(_emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE_REGEX.freshmarketer) || this.checkURLPatternMatch(_emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE_REGEX.freshmarketerLocal)) {
        workspace = getWorkspaceIfAvailable(_emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE.freshmarketer); // If current page is freshmarketer set  current workspace to freshmarketer
      } else if (!isCommonRoute) {
          workspace = getWorkspaceIfAvailable(_emberUnifiedNavbar360ConstantsWorkspaceSwitcher.WORKSPACE.freshsales); // If current page is not freshmarketer and is not a common module set freshsales as current workspace
        }

      this.setWorkSpace(Number(workspace));
      setProperties(this, {
        previousUrl: location.href,
        isCommonModule: isCommonRoute
      });
    }
  });
});