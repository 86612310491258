define('ember-unified-navbar-360/persistence-store/migrations/2023051401_create_store', ['exports'], function (exports) {
  'use strict';

  exports['default'] = function (db) {

    if (!db.objectStoreNames.contains('migration_log')) {
      db.createObjectStore('migration_log', {
        keyPath: 'name'
      });
    }

    if (!db.objectStoreNames.contains('navbar-svg-sprite-config')) {
      db.createObjectStore('navbar-svg-sprite-config', {
        keyPath: 'apiName'
      });
    }
  };
});