define('ember-crumbly/components/bread-crumb', ['exports', 'ember', 'ember-crumbly/templates/components/bread-crumb', 'ember-new-computed'], function (exports, _ember, _emberCrumblyTemplatesComponentsBreadCrumb, _emberNewComputed) {
  'use strict';

  var Component = _ember['default'].Component;
  var oneWay = _emberNewComputed['default'].oneWay;
  var bool = _emberNewComputed['default'].bool;

  exports['default'] = Component.extend({
    layout: _emberCrumblyTemplatesComponentsBreadCrumb['default'],
    tagName: 'li',
    classNameBindings: ['crumbClass'],

    crumbClass: oneWay('breadCrumbs.crumbClass'),
    linkClass: oneWay('breadCrumbs.linkClass'),
    hasBlock: bool('template').readOnly()
  });
});