define('ember-emojipalette/components/emoji-list/component', ['exports', 'ember-emojipalette/components/emoji-list/template'], function (exports, _emberEmojipaletteComponentsEmojiListTemplate) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberEmojipaletteComponentsEmojiListTemplate['default'],
    actions: {
      selectEmoji: function selectEmoji(emoji) {
        this.selectEmoji(emoji);
      }
    }
  });
});