define('ember-data-table/components/ui-list-item/component', ['exports', 'ember', 'ember-data-table/components/ui-list-item/template'], function (exports, _ember, _emberDataTableComponentsUiListItemTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiListItemTemplate['default'],
    tagName: 'tbody',

    registerToggle: 'registerToggle',

    deregisterToggle: 'deregisterToggle',

    actions: {
      registerToggle: function registerToggle(param) {
        // propagate from ui-list-checkbox component
        this.sendAction('registerToggle', param);
      },
      deregisterToggle: function deregisterToggle(param) {
        // propagate from ui-list-checkbox component
        this.sendAction('deregisterToggle', param);
      }
    }
  });
});