define("ember-unified-navbar-360/templates/components/mkp-cti-popup", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/mkp-cti-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "unified360-marketplace-cti-body");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 2, 2);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "mkp-app-frame", [], ["app", ["subexpr", "@mut", [["get", "popup.app", ["loc", [null, [4, 8], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "pageContext", ["subexpr", "@mut", [["get", "popup.pageContext", ["loc", [null, [5, 16], [5, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 2], [5, 35]]], 0, 0], ["content", "yield", ["loc", [null, [8, 0], [8, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});