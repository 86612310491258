define('ember-unified-navbar-360/services/360-base-marketplace-interface', ['exports'], function (exports) {
  'use strict';

  var Service = Ember.Service,
      get = Ember.get,
      Promise = Ember.RSVP.Promise;

  exports['default'] = Service.extend({
    /**
      * @public Close the given modal instance
      * @params {Object} options - includes instanceId - unique identifier of the modal iframe
      * @return
      */
    closeInstance: function closeInstance(options) {
      if (!options.instanceId) {
        return Promise.reject({ error: get(this, 'i18n').t('marketplace.errors.close_instance') });
      }
      get(this, 'mkpAdapter360').trigger('closeInstance_' + options.instanceId);
      return Promise.resolve({ success: get(this, 'i18n').t('marketplace.success.interface_success') });
    },

    /**
    * Resize
    * @params {Object} options - includes instanceId - unique identifier of the modal iframe
    * @return
    */
    resize: function resize() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var instanceId = get(options, 'instanceId');
      if (!instanceId) {
        return Promise.reject({ error: 'true' });
      }
      get(this, 'mkpAdapter360').trigger('resize_' + instanceId, options);
      return Promise.resolve({ success: 'true' });
    },

    /**
     *  Central show action for all Marketplace Interface
     * @param options
     * @returns {*|Promise.resolve}
     */
    show: function show(options) {
      return this.parseAction('show', options);
    },

    /**
     * Central hide action for all Marketplace Interface
     * @param options
     * @returns {*|Promise.resolve}
     */
    hide: function hide(options) {
      return this.parseAction('hide', options);
    },

    /* @public Shows notification message to user
    * @params {Object} options - includes
    *    type The type of notification requested by user, Maketplace has already validated the with supported types
    *    title The title for the notification message
    *    message The message for the notification
    * @return Promise.resolve
    */
    showNotify: function showNotify(options) {
      return;
    },

    /**
     * @public Modal handler
     * @params {DOM Element} element - The IFrame which has the service binded
     * @params {Object} options  - includes Title opted by the app, if app skipps default from marketplace will be used, product can opt for their tile in modalCOnfig.modal.title
     * @return - promise.resolve
     */
    showModal: function showModal(options, instance) {
      if (options.data && options.data.contacts.length) {
        return { event: 'closeMultiContactModal' };
      }
      return;
    },

    /**
     * @public confirmation dialog handler
     * @params {Object} options - includes Title, Message, SaveLabel, CancelLabel
     * @return - a Promise that is resolved on clicking 'SaveLabel', rejected otherwise.
     */
    showConfirm: function showConfirm(options) {
      return;
    }
  });
});