define('ember-unified-navbar-360/components/video-player', ['exports', 'ember-unified-navbar-360/templates/components/video-player', 'ember-unified-navbar-360/mixins/video-player'], function (exports, _emberUnifiedNavbar360TemplatesComponentsVideoPlayer, _emberUnifiedNavbar360MixinsVideoPlayer) {
  'use strict';

  var _window = window,
      interact = _window.interact;

  var VIDEO_PLAYER_DIMENSIONS = {
    height: 380,
    width: 480,
    footerHeight: 18
  };

  exports['default'] = Ember.Component.extend(_emberUnifiedNavbar360MixinsVideoPlayer['default'], {
    layout: _emberUnifiedNavbar360TemplatesComponentsVideoPlayer['default'],
    classNames: ['video-player'],
    videoUrl: Ember.computed('videoProperties.url', function () {
      var url = this.get('videoProperties.url');
      var params = '?seo=false&videoFoam=true';
      return url + params;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      // This comes from the library https://interactjs.io/docs/api/Interactable.html#draggable
      // Refer this doc for future reference. You will find the library file in the vendor directory
      interact('.draggable').resizable({
        cursorChecker: function cursorChecker() {
          return 'se-resize';
        },

        // resize from all edges and corners
        edges: { left: false, right: false, bottom: true, top: false },
        modifiers: [interact.modifiers.aspectRatio({
          ratio: 1.7
        })],
        listeners: {
          move: function move(event) {
            var target = event.target;
            var x = parseFloat(target.getAttribute('data-x')) || 0;
            var y = parseFloat(target.getAttribute('data-y')) || 0;
            // update the element's style
            target.style.width = event.rect.width + 'px';
            target.style.height = event.rect.height + VIDEO_PLAYER_DIMENSIONS.footerHeight + 'px';

            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            target.style.transform = 'translate(' + x + 'px,' + y + 'px)';

            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
          }
        }
      }).draggable({
        cursorChecker: function cursorChecker() {
          return 'grab';
        },

        listeners: {
          move: function move(event) {
            var target = event.target;
            // keep the dragged position in the data-x/data-y attributes
            var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
            var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

            var XThreshold = window.innerWidth - VIDEO_PLAYER_DIMENSIONS.width;
            var YThreshold = window.innerHeight - VIDEO_PLAYER_DIMENSIONS.height;

            // Make sure video player cannot be dragged outside of the window
            if (x > 20 && y > 10 && x < XThreshold && y < YThreshold) {
              target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

              // update the posiion attributes
              target.setAttribute('data-x', x);
              target.setAttribute('data-y', y);
            }
          }
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var x = window.innerWidth - VIDEO_PLAYER_DIMENSIONS.width;
      var y = window.innerHeight - VIDEO_PLAYER_DIMENSIONS.height;
      var videoModal = this.element.querySelector('.draggable');
      videoModal.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
      videoModal.setAttribute('data-x', x);
      videoModal.setAttribute('data-y', y);
    },

    actions: {
      closeVideoPlayer: function closeVideoPlayer() {
        this.closeVideoPlayerModal();
      },
      goBack: function goBack() {
        window.CommandBar.open();
        this.closeVideoPlayerModal();
      },
      navigateToRoute: function navigateToRoute(path) {
        var origin = window.location.origin;
        if (!path.startsWith('/')) {
          path = '/' + path;
        }
        window.location = origin + path;
        this.closeVideoPlayerModal();
      }
    }
  });
});