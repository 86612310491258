define("ember-emojipalette/components/emoji-palette/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 6
              },
              "end": {
                "line": 19,
                "column": 6
              }
            },
            "moduleName": "modules/ember-emojipalette/components/emoji-palette/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            morphs[1] = dom.createAttrMorph(element0, 'class');
            morphs[2] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["subexpr", "get", [["get", "categorySVG", ["loc", [null, [18, 24], [18, 35]]], 0, 0, 0, 0], ["get", "category", ["loc", [null, [18, 36], [18, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 18], [18, 46]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["emojidex-category-icon category-icon-", ["get", "category", ["loc", [null, [18, 94], [18, 102]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeCategory", ["get", "category", ["loc", [null, [18, 132], [18, 140]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 106], [18, 142]]], 0, 0]],
          locals: ["category"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 2
            },
            "end": {
              "line": 21,
              "column": 2
            }
          },
          "moduleName": "modules/ember-emojipalette/components/emoji-palette/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "emojidex-horizontal-ruler");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "emojidex-category-holder emojidex-bottom-component");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "allowedCategories", ["loc", [null, [17, 14], [17, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 6], [19, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "modules/ember-emojipalette/components/emoji-palette/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "emojidex-palette-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element1, 2, 2);
        morphs[1] = dom.createMorphAt(element1, 4, 4);
        return morphs;
      },
      statements: [["inline", "emoji-list", [], ["isCompleteList", ["subexpr", "@mut", [["get", "isCompleteList", ["loc", [null, [8, 21], [8, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "listType", ["subexpr", "@mut", [["get", "currentListType", ["loc", [null, [9, 15], [9, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "categories", ["subexpr", "@mut", [["get", "allowedCategories", ["loc", [null, [10, 17], [10, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "categoryNames", ["subexpr", "@mut", [["get", "categoryNames", ["loc", [null, [11, 20], [11, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "emojiList", ["subexpr", "@mut", [["get", "emojiList", ["loc", [null, [12, 16], [12, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "selectEmoji", ["subexpr", "action", ["selectEmoji"], [], ["loc", [null, [13, 18], [13, 40]]], 0, 0]], ["loc", [null, [7, 2], [13, 42]]], 0, 0], ["block", "unless", [["get", "hideCategories", ["loc", [null, [14, 12], [14, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 2], [21, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});