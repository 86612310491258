define('ember-unified-navbar-360/components/mkp-cti-trigger', ['exports', 'ember-unified-navbar-360/templates/components/mkp-cti-trigger'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMkpCtiTrigger) {
  'use strict';

  var _get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      getWithDefault = Ember.getWithDefault,
      isPresent = Ember.isPresent,
      capitalize = Ember.String.capitalize,
      service = Ember.inject.service,
      setProperties = Ember.setProperties;

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMkpCtiTrigger['default'],
    attributeBindings: ['data-app'],
    classNames: ['unified360-marketplace-app-nav-trigger', 'unified360-standard-width-height'],
    classNameBindings: ['isActive:active'],
    tagName: 'a',

    mkpAdapter360: service(window.currentProduct === 'freshsales' ? 'marketplace-adapter' : '360-marketplace-adapter'),
    mkpInterface: service(window.currentProduct === 'freshsales' ? 'marketplace-interface' : '360-marketplace-interface'),
    parentModalStack: service(window.currentProduct === 'freshsales' ? 'parent-modal-stack' : '360-parent-modal-stack'),

    // mkpAdapter360ServiceName: '360-marketplace-adapter',
    // mkpInterfaceServiceName: '360-marketplace-interface',
    // parentModalStackName: '360-parent-modal-stack',

    appType: computed('isChatApp', {
      get: function get() {
        return _get(this, 'isChatApp') ? 'chat' : 'phone';
      }
    }),

    'data-app': computed('app.displayName', {
      get: function get() {
        return _get(this, 'app.displayName') + '-' + _get(this, 'appType');
      }
    }),

    isActive: computed('mkpInterface.isChatAppActive', 'mkpInterface.isPhoneAppActive', {
      get: function get() {
        var mkpInterface = _get(this, 'mkpInterface');
        return _get(this, 'isChatApp') ? _get(mkpInterface, 'isChatAppActive') : _get(mkpInterface, 'isPhoneAppActive');
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.sortLeftNavApps();
      // let owner = getOwner(this);
      // setProperties(this, {
      //   'mkpAdapter360': owner.lookup(`service:${get(this, 'mkpAdapter360ServiceName')}`),
      //   'mkpInterface': owner.lookup(`service:${get(this, 'mkpInterfaceServiceName')}`),
      //   'parentModalStack': owner.lookup(`service:${get(this, 'parentModalStackName')}`)
      // })
      // the below events are triggered from within marketplace apps
      var mkpAdapter360 = _get(this, 'mkpAdapter360');

      if (_get(this, 'isChatApp')) {
        mkpAdapter360.on('showChatApp', this.toggleAppEvent.bind(this, true, 'chat'));
        mkpAdapter360.on('hideChatApp', this.toggleAppEvent.bind(this, false, 'chat'));
      } else {
        mkpAdapter360.on('showPhoneApp', this.toggleAppEvent.bind(this, true, 'phone'));
        mkpAdapter360.on('hidePhoneApp', this.toggleAppEvent.bind(this, false, 'phone'));
      }
    },
    click: function click() {
      this._toggleApp();
    },
    sortLeftNavApps: function sortLeftNavApps() {
      var app = _get(this, 'app');
      var appType = _get(this, 'appType');
      if (_get(this, 'isChatApp') || _get(this, 'isPhoneApp')) {
        var appId = _get(app, 'id');
        var mkpApps = _get(this, 'mkpInterface').popupStack;
        var appExists = mkpApps.findBy('appId', appId);
        if (!appExists) {
          mkpApps.pushObject({
            app: app,
            open: false,
            appType: appType,
            appId: _get(app, 'id'),
            appName: _get(app, 'displayName'),
            appIcon: _get(app, 'iconURL'),
            location: _get(app, 'location'),
            iframe: _get(app, 'element'),
            title: _get(app, 'displayName'),
            pageContext: getWithDefault(this, 'pageContext', null)
          });
        }
      }
    },
    toggleAppEvent: function toggleAppEvent() {
      var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var appType = arguments[1];

      var obj = _get(this, 'mkpInterface').popupStack.findBy('appType', appType);
      var trigger = show && !obj.open || !show && obj.open;
      if (trigger) {
        set(this, 'is' + capitalize(appType) + 'App', true);
        this._toggleApp(obj, show);
      }
    },
    _toggleApp: function _toggleApp(appObject, state) {
      var appId = isPresent(appObject) ? appObject.appId : _get(this, 'app.id');
      var activeApp = _get(this, 'mkpInterface').popupStack.findBy('appId', appId);
      var updatedState = state || !_get(activeApp, 'open');
      if (_get(this, 'isChatApp')) {
        set(this, 'mkpInterface.isChatAppActive', updatedState);
      } else {
        set(this, 'mkpInterface.isPhoneAppActive', updatedState);
        _get(this, 'parentModalStack').moveFCWidget(updatedState);
      }

      set(activeApp, 'open', updatedState);
      if (updatedState) {
        activeApp.app.triggerActivate();
      } else {
        activeApp.app.trigger({ type: 'app.deactivated' });
      }
      set(this, 'mkpInterface.popupStack', _get(this, 'mkpInterface').popupStack.slice());
    }
  });
});