define('ember-unified-navbar-360/constants/freshchat-inapp-data', ['exports'], function (exports) {
  'use strict';

  var FreshChatConfig = {
    production: {
      script: 'https://wchat.freshchat.com/js/widget.js',
      url: 'https://wchat.freshchat.com',
      token: '99198db8-ea46-4f69-a106-4fd0076835b4'
    },
    staging: {
      script: 'https://wchat-staging.freshpori.com/js/widget.js',
      url: 'https://wchat-staging.freshpori.com',
      token: '8c979c05-ad8c-481e-b06b-2faebb6aff61'
    },
    restoreIdUrl: '/crm/messaging/app/agent/restoreId'
  };

  exports['default'] = FreshChatConfig;
});