define('ember-form/components/ui-form/component', ['exports', 'ember', 'ember-form/components/ui-form/template'], function (exports, _ember, _emberFormComponentsUiFormTemplate) {
  'use strict';

  // EMBER::TODO:
  // Tweak the architecture to rails like helper

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiFormTemplate['default'],
    attributeBindings: ['role'],
    classNames: ['ui-form'],
    tagName: 'form',
    'for': null,
    startValidate: false,

    submit: function submit(e) {
      e.preventDefault();
      this.send('validate');
    },
    closeModal: 'closeModal',
    actions: {
      validate: function validate(callBackAction) {
        var _this = this,
            formModel = _this.get('for');

        callBackAction = callBackAction || 'save';

        _ember['default'].set(_this, callBackAction, callBackAction);

        _this.set('startValidate', true);

        formModel.validate().then(function () {
          _this.sendAction(callBackAction);
        })['catch'](function () {
          _this.sendAction('onValidationFailed');
          // any validations fail
        });
      },
      cancel: function cancel(callBackAction) {
        var _this = this;

        callBackAction = callBackAction || 'cancel';

        _ember['default'].set(_this, callBackAction, callBackAction);

        _this.sendAction(callBackAction);
      }
    }
  });
});