define('ember-emojipalette/utils/emojidex', ['exports', 'ember-emojipalette/utils/emojilist', 'ember-emojipalette/utils/emojiListVersion'], function (exports, _emberEmojipaletteUtilsEmojilist, _emberEmojipaletteUtilsEmojiListVersion) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /* eslint-disable no-fallthrough */
  // import { emojidata } from './emojidata';
  var emojidex = {
    // emojidata: emojidata,
    emojilist: _emberEmojipaletteUtilsEmojilist.emojilist,
    emojiListVersion: _emberEmojipaletteUtilsEmojiListVersion.emojiListVersion,
    getCategoryNames: function getCategoryNames() {
      return {
        people: "Smileys & People",
        nature: "Animals & Nature",
        food: "Food & Drinks",
        activity: "Activity",
        travel: "Travel & Places",
        object: "Objects",
        symbol: "Symbols",
        flag: "Flags"
      };
    },
    getUnsupportedEmojis: function getUnsupportedEmojis(version) {
      var _unsupportedEmojis, _unsupportedEmojis2;

      if (version === '11') return;
      var unsupportedEmojis = [];
      switch (version) {
        case '9':
          unsupportedEmojis = (_unsupportedEmojis = unsupportedEmojis).concat.apply(_unsupportedEmojis, _toConsumableArray(_emberEmojipaletteUtilsEmojiListVersion.emojiListVersion.v10));
        case '10':
          unsupportedEmojis = (_unsupportedEmojis2 = unsupportedEmojis).concat.apply(_unsupportedEmojis2, _toConsumableArray(_emberEmojipaletteUtilsEmojiListVersion.emojiListVersion.v11));
      }
      return unsupportedEmojis;
    },
    filteredEmojiList: function filteredEmojiList(version) {
      var unsupportedEmojis = this.getUnsupportedEmojis(version);
      var filteredEmojiList = {};
      Object.entries(this.emojilist).forEach(function (entry) {
        var category = entry[0];
        var emojis = entry[1];
        filteredEmojiList[category] = emojis.filter(function (emoji) {
          return !unsupportedEmojis.includes(emoji);
        });
      });
      return filteredEmojiList;
    }
    // _searchInSubcategory(searchTerm, emoji) {
    //   return emoji.subcategory.split(/[- ]/).filter( word => word.length > 1).includes(searchTerm);
    // },
    // searchEmojis(searchTerm) {
    //   let searchResults = [];
    //   for(let category in emojidata) {
    //     let emojis = emojidata[category];
    //     searchResults.push(
    //       emojis.filter((emoji) => {
    //           return (emoji.keyTerms && emoji.keyTerms.includes(searchTerm))
    //           ||  (emoji.subcategory && this._searchInSubcategory(searchTerm, emoji))
    //       }).map((emoji) => {
    //         return emoji.char;
    //       })
    //     );
    //   }
    //   return searchResults.flat();
    // }

  };
  exports.emojidex = emojidex;
});