define('ember-data-table/components/ui-list-checkbox/component', ['exports', 'ember', 'ember-data-table/components/ui-list-checkbox/template'], function (exports, _ember, _emberDataTableComponentsUiListCheckboxTemplate) {
  'use strict';

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableComponentsUiListCheckboxTemplate['default'],
    content: {},

    isChecked: false,

    registerToggle: 'registerToggle',

    deregisterToggle: 'deregisterToggle',

    selectItem: observer('isChecked', function () {
      return this.toggleProperty('content.isSelected');
    }),

    itemId: computed('content', {
      get: function get() {
        return 'checkbox_' + this.get('id');
      }
    }),

    id: computed('itemId', {
      get: function get() {
        return this.get('content.id');
      }
    }),

    registerOnParent: (function () {
      this.set('content.isSelected', false);
      this.sendAction('registerToggle', this);
    }).on('didInsertElement'),

    deregisterOnParent: (function () {
      this.sendAction('deregisterToggle', this);
    }).on('willDestroyElement')

  });
});