define('ember-unified-navbar-360/mixins/highlight-current-menu', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Mixin.create({
    router: Ember.inject.service('-routing'),
    s360Transformation: Ember.inject.service(),
    workspaceService: Ember.inject.service('workspace-switcher'),
    removeActiveClass: function removeActiveClass(value) {
      // let doc = selectedElement || document;
      var removeClassName = value;
      var selectNavBar = document.getElementsByClassName('unified360-menu-list-container');
      if (selectNavBar && selectNavBar.length > 0) {
        var previousActiveElement = selectNavBar[0].getElementsByClassName('' + removeClassName);
        if (previousActiveElement && previousActiveElement.length > 0) {
          while (previousActiveElement.length) {
            var a = previousActiveElement.length - 1;
            previousActiveElement[a].classList.remove('' + removeClassName);
          }
        }
      }
    },
    getParamVal: function getParamVal(name, url) {
      if (!url) {
        url = location.href;
      }
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regexS = '[\\?&]' + name + '=([^&#]*)';
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? null : results[1];
    },
    addActiveClass: function addActiveClass(highlightMenu) {
      /* this will setup the workspace based on route change */
      this.get('workspaceService').setWorkSpaceOnRouteChange();

      /* To add active class to menu based on submenus */
      this.get('s360Transformation').sethighlightMenuValue(highlightMenu);
      var multipleRouteCombo = [{ route: 'campaigns', type: 'type' }, { route: 'web.marketing.rules.index', type: 'campaignType' }, { route: 'ab', type: 'croexp' }];
      var doc = document;
      this.removeActiveClass('unified360-active-menu');
      var activeState = document.getElementsByClassName('unified360-submenu-item active');
      if (activeState.length > 1) {
        for (var z = 0; z < multipleRouteCombo.length; z++) {
          var currentRoute = this.get('router.currentRouteName');
          if (currentRoute && currentRoute.includes(multipleRouteCombo[z].route)) {
            var type = this.getParamVal(multipleRouteCombo[z].type, window.location.href);
            if (type) {
              this.manipulateClass(type, activeState, multipleRouteCombo[z]);
              window.routeType = type;
            } else if (window.routeType) {
              var currentType = window.routeType;
              this.manipulateClass(currentType, activeState, multipleRouteCombo[z]);
            }
          }
        }
      }

      var overridemenu = highlightMenu && highlightMenu.length;
      if (overridemenu) {
        this.overrideHighlightingIcon(highlightMenu);
      }

      var childActiveModule = overridemenu ? doc.getElementsByClassName('unified360-collapsed-item unified360-submenu-item unified360-active-menu') : doc.getElementsByClassName('unified360-collapsed-item unified360-submenu-item active');

      if (childActiveModule && childActiveModule.length > 0) {
        childActiveModule = childActiveModule[0];
        var closesetmainMenu = Ember.$(childActiveModule).closest('.unified360-menu');
        if (closesetmainMenu.length) {
          var activeAElement = closesetmainMenu[0].getElementsByClassName('unified360-list-item')[0];
          activeAElement.classList.add('unified360-active-menu');
        }
      } else if (doc.getElementsByClassName('unified360-list-item active').length < 1 && doc.querySelectorAll('.unified360-active-menu').length < 1) {
        this.highlightMoreIcon();
      }
    },
    manipulateClass: function manipulateClass(currentType, activeState, routeObj) {
      for (var a = 0; a < activeState.length;) {
        var url = activeState[a].getAttribute('href');
        if (currentType !== this.getParamVal(routeObj.type, url)) {
          activeState[a].classList.remove('active');
        } else {
          a++;
        }
      }
    },
    highlightMoreIcon: function highlightMoreIcon() {
      var moreMenu = document.querySelector("[aria-label='More']");
      if (moreMenu) {
        var menu = moreMenu.getElementsByClassName('unified360-list-item')[0];
        this.addClasstoitem(menu);
      }
    },
    overrideHighlightingIcon: function overrideHighlightingIcon(highlightMenu) {
      var getMenuTobehighlighted = document.querySelectorAll('[data-unified360-addon="' + highlightMenu + '"]');
      if (getMenuTobehighlighted && getMenuTobehighlighted.length > 0) {
        this.removeActiveClass('active');
        if (getMenuTobehighlighted.length === 1) {
          var atagElement = getMenuTobehighlighted[0].querySelector('[data-s360product-info]') || getMenuTobehighlighted[0].querySelector('a');
          this.addClasstoitem(atagElement);
          if (!atagElement.classList.contains('unified360-collapsed-item')) {
            this.highlightMoreIcon();
          }
        } else {
          for (var a = 0; a < getMenuTobehighlighted.length; a++) {
            var _atagElement = getMenuTobehighlighted[a].querySelector('[data-s360product-info]') || getMenuTobehighlighted[a].querySelector('a');
            this.addClasstoitem(_atagElement);
          }
        }
      }
    },
    addClasstoitem: function addClasstoitem(element) {
      // adds active class for submenu and active-menu for menu items
      if (element.classList.contains('unified360-submenu-item') || element.classList.contains('unified360-menu-item')) {
        //   element.classList.add("active");
        // } else {
        element.classList.add('unified360-active-menu');
      }
      return;
    }
  });
});