define('ember-unified-navbar-360/utils/add-svgxml-to-dom', ['exports', 'ember-unified-navbar-360/utils/lnb-application-config-utils'], function (exports, _emberUnifiedNavbar360UtilsLnbApplicationConfigUtils) {
  'use strict';

  exports.addSvgxmlToDom = addSvgxmlToDom;

  function addSvgxmlToDom(persistenceStore, redisDataVersion, lnbIconAssetUrl) {
    (0, _emberUnifiedNavbar360UtilsLnbApplicationConfigUtils.getApplicationConfigForRoute)(persistenceStore, redisDataVersion, lnbIconAssetUrl, 'navbar-svg-sprite-config').then(function (xmlString) {
      var parser = new DOMParser();
      var lnbSpritesDoc = parser.parseFromString(xmlString, 'text/xml');
      var sprites = lnbSpritesDoc.childNodes;
      if (!document.getElementById('sprite-defs')) {
        var div = document.createElement('div');
        div.id = 'sprite-defs';
        div.style.display = 'none';
        document.getElementsByTagName('body')[0].appendChild(div);
      }
      for (var k = 0; k < sprites.length; k++) {
        var sprite = sprites.item(k).cloneNode(true);
        document.getElementById('sprite-defs').appendChild(sprite);
      }
    });
  }
});