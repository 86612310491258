define("ember-unified-navbar-360/components/link-identifier", ["exports", "ember-unified-navbar-360/templates/components/link-identifier"], function (exports, _emberUnifiedNavbar360TemplatesComponentsLinkIdentifier) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  var isPresent = Ember.isPresent;

  exports["default"] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsLinkIdentifier["default"],
    isSubMenu: false, // true for subemenu in both views
    isMainMenu: false, // true for collapded view menu without submenus
    expandedSubmenu: false, // true for expanded view submenu
    fromCollapsedView: false, // true for both menu and submenu from collapsed view
    router: Ember.inject.service(),
    launchPad: Ember.inject.service(),
    mkpInterface: Ember.inject.service('360-marketplace-interface'),
    customTarget: Ember.computed(function () {
      // to open link in other tab for products that doesnt have unified left nav
      return this.get('linkIdentifier.product') === 'freshid' ? '_blank' : '_self';
    }),
    isSubMenuClass: Ember.computed('linkIdentifier', function () {
      return this.get('isSubMenu') ? this.get('expandedSubmenu') ? 'unified360-submenu-item unified360-pl-35' : 'unified360-submenu-item' : 'unified360-list-item';
    }),
    isMainMenuClass: Ember.computed('linkIdentifier', function () {
      // menus shown in case of collapsed view with only icons
      return this.get('isMainMenu') ? ' unified360-menu-item' : '';
    }),
    fromCollapsedViewClass: Ember.computed('linkIdentifier', function () {
      return this.get('fromCollapsedView') ? ' unified360-collapsed-item' : '';
    }),
    itemClassNames: Ember.computed('linkIdentifier', function () {
      return this.get('isSubMenuClass') + this.get('isMainMenuClass') + this.get('fromCollapsedViewClass') + (this.get('linkIdentifier.disabled') ? ' unified360-disabled' : '');
    }),
    activeRoutes: Ember.computed('linkIdentifier.url_as_route.route_name', 'linkIdentifier.url_as_route.active_on_routes', {
      get: function get() {
        var _getProperties = this.getProperties('linkIdentifier.url_as_route.active_on_routes', 'linkIdentifier.url_as_route.route_name'),
            activeRoutes = _getProperties['linkIdentifier.url_as_route.active_on_routes'],
            routeName = _getProperties['linkIdentifier.url_as_route.route_name'];

        if (isPresent(activeRoutes)) {
          return activeRoutes;
        } else {
          return routeName;
        }
      }
    }),
    queryParams: Ember.computed(function () {
      var queryParam = this.get('linkIdentifier.url_as_route.query_params');
      var paramsValue = this.get('linkIdentifier.url_as_route.params');
      if (paramsValue && queryParam) {
        var params = [this.get('linkIdentifier.url_as_route.route_name'), paramsValue, {
          isQueryParams: true,
          values: queryParam
        }];
        return params;
      } else if (paramsValue) {
        var _params = [this.get('linkIdentifier.url_as_route.route_name'), paramsValue];
        return _params;
      } else {
        var _params2 = [this.get('linkIdentifier.url_as_route.route_name'), {
          isQueryParams: true,
          values: queryParam
        }];
        return _params2;
      }
    }),
    pillClass: Ember.computed('linkIdentifier', function () {
      // if pill needed for lnb icons add the linkIdentifier name to the iconsWithPill array
      var iconsWithPill = [];
      var shouldShowPill = iconsWithPill.includes(this.get('linkIdentifier.name'));
      return shouldShowPill ? 'unified360-show-pill' : '';
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      redirectToCallback: function redirectToCallback(obj) {
        obj.callback.call(obj, obj);
      },
      callbackAction: function callbackAction(actionString) {
        try {
          var _actionString$split = actionString.split('/'),
              _actionString$split2 = _slicedToArray(_actionString$split, 3),
              action = _actionString$split2[0],
              type = _actionString$split2[1],
              value = _actionString$split2[2];

          switch (action) {
            case 'openLaunchPad':
            case 'launchpad':
              if (type === 'category') {
                window.CommandBar.open('', { categoryFilter: Number(value) });
              } else if (type === 'command') {
                window.CommandBar.execute(value);
              } else {
                window.CommandBar.open();
              }
              if (window.heap) {
                window.heap.track('launch-pad-lnb-click');
              }
              if (window.Appcues) {
                window.Appcues.track('freshsales_launch-pad-lnb-click');
              }
              window.dispatchEvent(new CustomEvent('commandbar-opened'));
              break;
          }
        } catch (err) {}
      },
      checkCaller: function checkCaller(a, url) {
        if (a !== this.get('currentProduct')) {
          if (window.callInProgress) {
            // show popup
            this.sendAction('callerModalAction', url);
            return;
          } else if (Ember.get(this, 'mkpInterface.isCTICallInProgress')) {
            window.open(url, '_blank');
            return;
          }
        }
        window.location.replace(url);
      }
    }
  });
});