define("ember-unified-navbar-360/utils/regex-utils", ["exports"], function (exports) {
  "use strict";

  var regexUtil = {
    _DIMENSION_UNIT: /(\d*\.?\d+)\s?(px|em|ex|%|in|cn|mm|pt|pc+)/,

    isDimensionUnit: function isDimensionUnit(text) {
      return text && this._DIMENSION_UNIT.test(text);
    }
  };

  exports.regexUtil = regexUtil;
  exports["default"] = regexUtil;
});