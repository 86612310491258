define('ember-unified-navbar-360/components/mkp-cti-popup', ['exports', 'ember-unified-navbar-360/templates/components/mkp-cti-popup', 'ember-unified-navbar-360/mixins/form', 'ember-unified-navbar-360/utils/regex-utils'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMkpCtiPopup, _emberUnifiedNavbar360MixinsForm, _emberUnifiedNavbar360UtilsRegexUtils) {
  'use strict';

  var get = Ember.get,
      set = Ember.set,
      capitalize = Ember.String.capitalize,
      isBlank = Ember.isBlank,
      getProperties = Ember.getProperties,
      computed = Ember.computed,
      service = Ember.inject.service;

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMkpCtiPopup['default'],
    parentModalStack: service('360-parent-modal-stack'),
    mkpInterface: service('360-marketplace-interface'),

    attributeBindings: ['data-popup-id'],
    classNames: ['unified360-marketplace-cti-content'],
    classNameBindings: ['isActive:unified360-block:unified360-hide'],

    'data-popup-id': computed.reads('popup.appId'),
    isActive: computed.alias('popup.open'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.subscribeResize();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unsubscribeResize();
    },

    /**
     * subscribeMarketplaceEvents - initiate the events for the iframe
     * Instance close will be triggered in Interface method of adapter
     */
    subscribeResize: function subscribeResize() {
      var _this = this;

      var _getProperties = getProperties(this, 'popup.iframe', 'mkpAdapter'),
          iFrame = _getProperties['popup.iframe'],
          mkpAdapter = _getProperties.mkpAdapter;

      if (!iFrame) {
        return;
      }
      var instanceId = iFrame.id;

      mkpAdapter.on('resize_' + instanceId, function (data) {
        _this.resize(data);
      });
    },

    /**
     * unsubscribeMarketplaceEvents - destroy the events on the iframe after the iframe is closed
     */
    unsubscribeResize: function unsubscribeResize() {
      var _getProperties2 = getProperties(this, 'popup.iframe', 'mkpAdapter'),
          iFrame = _getProperties2['popup.iframe'],
          mkpAdapter = _getProperties2.mkpAdapter;

      if (!iFrame) {
        return;
      }
      var instanceId = iFrame.id;
      mkpAdapter.off('resize_' + instanceId);
    },
    resize: function resize(options) {
      var element = get(this, 'element');
      if (isBlank(element)) {
        return;
      }

      var _getProperties3 = getProperties(options, 'height', 'width'),
          height = _getProperties3.height,
          width = _getProperties3.width;

      if (_emberUnifiedNavbar360UtilsRegexUtils['default'].isDimensionUnit(height)) {
        element.style.height = height;
      }
      if (_emberUnifiedNavbar360UtilsRegexUtils['default'].isDimensionUnit(width)) {
        element.style.width = width;
      }
    },

    actions: {
      /**
       * close - close the app based on id after computation.
       *
       * @param  {string} appId - id of the app
       */
      close: function close(appId) {
        var mkpInterface = get(this, 'mkpInterface');
        var appObj = get(mkpInterface, 'popupStack').findBy('appId', appId);
        var appType = get(appObj, 'appType');
        if (get(appObj, 'open')) {
          set(appObj, 'open', false);
          appObj.app.trigger({ type: 'app.deactivated' });
          set(mkpInterface, 'is' + capitalize(appType) + 'AppActive', false);
          if (appType === 'phone') {
            get(this, 'parentModalStack').moveFCWidget(false);
          }
        }
      }
    }
  }, _emberUnifiedNavbar360MixinsForm['default']);
});