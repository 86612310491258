define('ember-unified-navbar-360/constants/marketplace', ['exports'], function (exports) {
  'use strict';

  var openActions = ['contact', 'lead', 'deal', 'account', 'calllog'];
  var hideActions = ['phoneApp', 'chatApp'];

  var showActions = ['account', 'deal', 'contact', 'lead', 'phoneApp', 'chatApp'];
  var FrontendMarketplaceConstants = {
    CONVERSATION_EDITOR_LIMIT: 5,
    INTERFACE_API_TIMEOUT: 10000,
    TIMEOUT_STATUS: 504,
    POPUP_LIMIT: 2,

    phoneApps: ['left_nav_cti'],
    chatApps: ['left_nav_chat'],

    // TODO: DataAPI Refactor
    // https://github.com/freshdesk/helpkit-ember/pull/1715/files
    dataSources: {
      lead: ['lead', 'notes', 'tasks', 'appointments', 'files'],
      contact: ['contact', 'notes', 'tasks', 'appointments', 'files', 'deals', 'conversations'],
      'sales_account': ['sales_account', 'notes', 'tasks', 'appointments', 'files'],
      deal: ['deal', 'notes', 'tasks', 'appointments', 'files']
    },

    INTERCEPTABLE_EVENTS: [],
    EVENTS: ['task.create', 'appointment.create', 'lead.update', 'deal.update', 'contact.update', 'account.update', 'task.update', 'appointment.update', 'calling', 'task.complete'],
    OVERLAYS: ['showModal'],

    blacklistedAttributes: {
      lead: ['meta'],
      contact: ['meta'],
      loggedInUser: ['autorefresh_user_hash', 'collision_user_hash']
    },

    interfaceAttr: {
      ctiMap: ['phoneApp', 'chatApp'],
      actionElementMap: {
        show: showActions,
        hide: hideActions,
        open: openActions,
        showNotify: {},
        showConfirm: {},
        showModal: {},
        closeInstance: {},
        resize: {},
        publishCallState: {}
      },
      // Will pbe used by interface-source service
      modalConfig: {
        modal: {
          title: 'marketplace.modal_config.modal.title',
          closeOnEscape: true
        },
        dialog: {
          title: 'marketplace.modal_config.dialog.title',
          closeOnEscape: true
        },
        confirmation: {
          title: 'marketplace.modal_config.confirm.title',
          saveString: 'marketplace.modal_config.confirm.save',
          cancelString: 'marketplace.modal_config.confirm.cancel',
          dialogTimeout: 10000,
          closeOnEscape: true
        },
        notification: {
          'default': 'success',
          // Product can load this list from its environments also
          // All products needs to have support for 'success', 'info', 'warning', 'danger'
          options: ['success', 'info', 'warning', 'danger', 'alert', 'banner']
        },
        popupNotification: {
          'default': 'success',
          options: ['success', 'info', 'warning', 'danger', 'alert']
        }
      }
    },

    ROUTE_PAGE_TYPE_MAPPER: {
      'leads.show': 'lead',
      'leads.filters': 'lead',
      'contacts.show': 'contact',
      'contacts.filters': 'contact',
      'accounts.filters': 'account',
      'accounts.show': 'account',
      'deals.show': 'deal',
      'deals.filters': 'deal'
    },

    LOCATIONS: {
      'left_nav_chat': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'left_nav_cti': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'lead_entity_menu': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'contact_entity_menu': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'sales_account_entity_menu': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'deal_entity_menu': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'flash_message_menu': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'overlay': {
        services: ['DataAPI', 'InterfaceAPI']
      },
      'lead_quick_actions': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'contact_quick_actions': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'sales_account_quick_actions': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'deal_quick_actions': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      },
      'full_page_app': {
        services: ['DataAPI', 'EventAPI', 'InterfaceAPI']
      }
    },
    CTI_CALL_STATES: {
      IN_PROGRESS: 'IN_PROGRESS',
      DISCONNECTED: 'DISCONNECTED'
    }

  };

  exports['default'] = FrontendMarketplaceConstants;
});