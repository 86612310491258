define('ember-features/helpers/has-feature', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.hasFeature = hasFeature;

  function hasFeature(params, _ref) {
    var feature = _ref.feature;
    var account = _ref.account;

    return account && account.get('features').indexOf(feature) !== -1;
  }

  exports['default'] = _ember['default'].Helper ? _ember['default'].Helper.helper(hasFeature) : _ember['default'].HTMLBars.makeBoundHelper(hasFeature);
});