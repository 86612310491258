define('ember-cli-deploy-sentry/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _emberCliSentryServicesRaven) {
    'use strict';

    exports['default'] = _emberCliSentryServicesRaven['default'].extend({
        releaseMetaName: 'sentry:revision',
        release: Ember.computed('releaseMetaName', {
            get: function get() {
                return document.querySelector('meta[name=\'' + this.get('releaseMetaName') + '\']').content;
            }
        })
    });
});