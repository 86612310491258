define('ember-unified-navbar-360/components/mkp-cti-popup-wrapper', ['exports', 'ember-unified-navbar-360/templates/components/mkp-cti-popup-wrapper'], function (exports, _emberUnifiedNavbar360TemplatesComponentsMkpCtiPopupWrapper) {
  'use strict';

  var service = Ember.inject.service;

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsMkpCtiPopupWrapper['default'],
    classNames: ['unified360-marketplace-cti-container'],
    mkpInterface: service('360-marketplace-interface')
  });
});