define("ember-unified-navbar-360/templates/components/main-logo", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "unified360-standard-loader-dimension unified360-hover-state unified360-opacity-0-5");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["get", "s360Valign", ["loc", [null, [3, 17], [3, 27]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 8
                },
                "end": {
                  "line": 11,
                  "column": 8
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "class", "unified360-icon");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'src');
              morphs[1] = dom.createAttrMorph(element1, 'alt');
              return morphs;
            },
            statements: [["attribute", "src", ["get", "finalSideNavValues.product_icon_url", ["loc", [null, [10, 21], [10, 56]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["subexpr", "s360-totitlecase", [["get", "logoObject.label", ["loc", [null, [10, 106], [10, 122]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [10, 124]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 8
                },
                "end": {
                  "line": 15,
                  "column": 8
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              dom.setNamespace("http://www.w3.org/2000/svg");
              var el1 = dom.createElement("svg");
              dom.setAttribute(el1, "class", "unified360-icon");
              dom.setAttribute(el1, "viewBox", "0 0 32 32");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("use");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'xlink:href', 'http://www.w3.org/1999/xlink');
              return morphs;
            },
            statements: [["attribute", "xlink:href", ["concat", ["#", ["get", "finalSideNavValues.product_icon", ["loc", [null, [13, 32], [13, 63]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 6
                },
                "end": {
                  "line": 21,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1, "class", "unified360-submenus-dropdown-item-label unified360-text-ellipsis");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "s360-totitlecase", [["get", "finalSideNavValues.product_name", ["loc", [null, [19, 27], [19, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 8], [19, 60]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 4
              },
              "end": {
                "line": 22,
                "column": 4
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "class", ["get", "s360Valign", ["loc", [null, [8, 19], [8, 29]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "finalSideNavValues.product_icon_url.length", ["loc", [null, [9, 14], [9, 56]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 8], [15, 15]]]], ["block", "if", [["get", "expanded", ["loc", [null, [17, 12], [17, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [17, 6], [21, 13]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-identifier", [], ["callerPopup", ["subexpr", "@mut", [["get", "callerPopup", ["loc", [null, [7, 37], [7, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "linkIdentifier", ["subexpr", "@mut", [["get", "logoObject", ["loc", [null, [7, 64], [7, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "isSubMenu", ["subexpr", "if", [["get", "expanded", ["loc", [null, [7, 89], [7, 97]]], 0, 0, 0, 0], true, false], [], ["loc", [null, [7, 85], [7, 109]]], 0, 0], "callerModalAction", ["subexpr", "action", ["callerModalAction"], [], ["loc", [null, [7, 128], [7, 156]]], 0, 0], "isBundleProduct", ["subexpr", "@mut", [["get", "isBundleProduct", ["loc", [null, [7, 173], [7, 188]]], 0, 0, 0, 0]], [], [], 0, 0], "currentProduct", ["subexpr", "@mut", [["get", "currentProduct", ["loc", [null, [7, 204], [7, 218]]], 0, 0, 0, 0]], [], [], 0, 0], "fromCollapsedView", ["subexpr", "@mut", [["get", "fromCollapsedView", ["loc", [null, [7, 237], [7, 254]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [7, 4], [22, 24]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createAttrMorph(element4, 'onClick');
        morphs[2] = dom.createMorphAt(element4, 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "class", ["subexpr", "if", [["get", "expanded", ["loc", [null, [1, 16], [1, 24]]], 0, 0, 0, 0], "", "unified360-simple-main-logo"], [], ["loc", [null, [null, null], [1, 59]]], 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["removeClass"], [], ["loc", [null, [null, null], [1, 92]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "loading", ["loc", [null, [2, 8], [2, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [23, 9]]]], ["content", "yield", ["loc", [null, [25, 0], [25, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});