define('ember-form/components/ui-input/component', ['exports', 'ember', 'ember-form/components/ui-input/template', 'ember-form/mixins/focus-field'], function (exports, _ember, _emberFormComponentsUiInputTemplate, _emberFormMixinsFocusField) {
  'use strict';

  exports['default'] = _ember['default'].TextField.extend(_emberFormMixinsFocusField['default'], {
    layout: _emberFormComponentsUiInputTemplate['default'],
    init: function init() {
      if (this.get('parentView.sanitizedID')) {
        this.set('elementId', this.get('parentView.sanitizedID'));
      }
      this._super(arguments);
    },
    classNames: ['form-control'],
    attributeBindings: ['autofocus'],
    autofocus: 'autofocus'
  });
});