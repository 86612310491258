define('ember-unified-navbar-360/components/widget-items', ['exports', 'ember-unified-navbar-360/templates/components/widget-items', 'ember-unified-navbar-360/mixins/apps'], function (exports, _emberUnifiedNavbar360TemplatesComponentsWidgetItems, _emberUnifiedNavbar360MixinsApps) {
  'use strict';

  var get = Ember.get,
      _Ember$computed = Ember.computed,
      oneWay = _Ember$computed.oneWay,
      readOnly = _Ember$computed.readOnly,
      Component = Ember.Component,
      getProperties = Ember.getProperties;

  var fdeskTicketsSlug = '/a/tickets/filters/all_tickets';

  exports['default'] = Ember.Component.extend(_emberUnifiedNavbar360MixinsApps['default'], {
    layout: _emberUnifiedNavbar360TemplatesComponentsWidgetItems['default'],
    classNames: ['unified360-widgets-container', 'unified360-flex-container'],
    expandedMenu: false,
    chatInitializeLoading: true,
    callerInitializeLoading: true,
    omnibarInitializeLoading: true,
    s360Transformation: Ember.inject.service(),
    mkpInterface: Ember.inject.service('360-marketplace-interface'),
    s360fdAdapter: Ember.inject.service('360-fd-marketplace-adapter'),
    fdBootstrap: Ember.inject.service('360-fd-bootstrap'),
    router: Ember.inject.service(),
    mkpCTIApp: true,
    mkpChatApp: true,
    hideChatWidget: Ember.computed.equal('sideNavValues.freshchat_config.hide_chat_widget', true),
    chatConfigCheck: Ember.computed.not('hideChatWidget'),
    fdMessagingURL: readOnly('sideNavValues.freshchat_config.inbox_url'),
    fdMessagingEnabled: readOnly('sideNavValues.freshchat_config.fd_messaging_enabled'),
    isFdeskEnabled: Ember.computed.alias('sideNavValues.freshdesk_config.enabled'),
    showCTILoader: Ember.computed.or('deskCTILoading', 'callerInitializeLoading'),
    callerConfigCheck: Ember.computed('sideNavValues', function () {
      var sideNavValues = get(this, 'sideNavValues');
      return Object.keys(sideNavValues.freshcaller_config).length > 0;
    }),
    canLoadDeskMkp: Ember.computed('sideNavValues', 'isCTIAppEnabled', function () {
      var userConfig = this.get('sideNavValues.user_config');
      var isDeskFeaturePresent = this.get('fdBootstrap').canLoadDeskFeatures(userConfig);
      return isDeskFeaturePresent && !this.get('isCTIAppEnabled');
    }),
    deskCTILoading: Ember.computed('canLoadDeskMkp', 's360fdAdapter.isMkpInitalized', function () {
      return this.get('canLoadDeskMkp') ? !this.get('s360fdAdapter.isMkpInitalized') : false;
    }),
    freshdeskTicketsUrl: Ember.computed('isFdeskEnabled', 'sideNavValues', 'sideNavValues.freshdesk_config', function () {
      var _getProperties = getProperties(this, 'isFdeskEnabled', 'sideNavValues.freshdesk_config.domain'),
          isFdeskEnabled = _getProperties.isFdeskEnabled,
          domain = _getProperties['sideNavValues.freshdesk_config.domain'];

      var fdeskDomain = isFdeskEnabled && domain;
      return fdeskDomain + fdeskTicketsSlug;
    }),
    isCTIAppEnabled: Ember.computed('sideNavValues', 'sideNavValues.mkp_cti_app', function () {
      return get(this, 'isLocalDevApp') || get(this, 'sideNavValues.mkp_cti_app') && this.get('sideNavValues.mkp_auth_config') && Ember.isPresent(Object.keys(this.get('sideNavValues.mkp_cti_app'))) && Ember.isPresent(Object.keys(this.get('sideNavValues.mkp_auth_config')));
    }),
    isChatAppEnabled: Ember.computed('sideNavValues.mkp_chat_app', 'sideNavValues', function () {
      return get(this, 'sideNavValues.mkp_chat_app') && this.get('sideNavValues.mkp_auth_config') && Ember.isPresent(Object.keys(this.get('sideNavValues.mkp_chat_app'))) && Ember.isPresent(Object.keys(this.get('sideNavValues.mkp_auth_config')));
    }),
    isLocalDevApp: Ember.computed(function () {
      if (typeof URLSearchParams === 'function') {
        var queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('dev') === 'true';
      }
    }),
    showCTIPopupWrapper: Ember.computed(function () {
      return window.currentProduct !== 'freshsales';
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('isBundleProduct')) {

        // initializing freshchat
        if (this.get('chatScriptInjected') === 'NOT_REQUIRED') {
          this.set('chatInitializeLoading', false);
        } else if (this.get('chatConfigCheck') && !this.get('fdMessagingEnabled')) {
          if (this.get('chatScriptInjected') === 'INJECTED') {
            this.freshChatInitializer(this.get('sideNavValues.freshchat_config'));
          }
        } else {
          this.set('chatInitializeLoading', false);
        }
        // initializing freshcaller
        if (this.get('callerScriptInjected') === 'NOT_REQUIRED') {
          this.set('callerInitializeLoading', false);
        } else if (this.get('callerConfigCheck')) {
          if (this.get('callerScriptInjected') === 'INJECTED') {
            this.freshcallerInitializer(this.get('sideNavValues.freshcaller_config'));
          }
        } else {
          this.set('callerInitializeLoading', false);
        }
        // initializing omnibar
        if (this.get('omnibarScriptInjected') === 'NOT_REQUIRED') {
          this.set('omnibarInitializeLoading', false);
        } else if (this.get('sideNavValues.freshid_config') && this.get('sideNavValues.freshcaller_config.disabled') !== true && this.get('normalState')) {
          if (this.get('omnibarScriptInjected') === 'INJECTED') {
            this.omnibarInitializer();
          }
        } else {
          this.set('omnibarInitializeLoading', false);
        }
      }
    },
    overwriteconfig: function overwriteconfig(transformationData, componentData) {
      var omnibarObj = Object.assign({}, transformationData, componentData);
      return omnibarObj;
    },
    willDestroyElement: function willDestroyElement() {
      this.removeCallerEvents();
    },
    removeCallerEvents: function removeCallerEvents() {
      window.removeEventListener('message', this.widgetActionHandler.bind(this));
    },
    alignChatPosition: function alignChatPosition() {
      if (!window.fcAgentWidget) {
        return;
      }
      var element = document.querySelector('#fc-widget');
      if (!element) {
        // when freshcaller is disabled
        window.fcAgentWidget.move({ 'leftOffset': 74 });
        return;
      }
      var height = element.style.height;
      if (height !== '0px') {
        window.fcAgentWidget.move({ 'leftOffset': 450 });
      } else {
        window.fcAgentWidget.move({ 'leftOffset': 74 });
      }
    },
    widgetActionHandler: function widgetActionHandler(_ref) {
      var _this = this;

      var data = _ref.data;

      // call_status(['in progress', 'completed', 'transferred']), currentCall
      // addTask - currentCall
      if (data.type === 'widget_ready') {
        this.set('callerInitializeLoading', false);
        var crmAbilities = this.get('sideNavValues.user_config.abilities') || [];
        if (window.FreshcallerWidgetInstance && window.FreshcallerWidgetInstance.postMessageToWidget) {
          // Use the new postMessageToWidget API to send events to the new caller widget going forward as it is more readable and simpler.
          window.FreshcallerWidgetInstance.postMessageToWidget({
            type: 'setAppConfig',
            payload: { crmAbilities: crmAbilities }
          });
        }
      }if (data.type === 'hide_widget') {
        Ember.$('.s360-caller').removeClass('active');
      } else if (data.type === 'show_widget') {
        Ember.$('.s360-caller').addClass('active');
      } else if (data.type === 'incoming_call') {
        window.callInProgress = true;
        Ember.set(this, 'callInProgress', true);
        setTimeout(function () {
          _this.alignChatPosition();
        }, 100);
      } else if (data.type === 'no_calls') {
        window.callInProgress = false;
        Ember.set(this, 'callInProgress', false);
        setTimeout(function () {
          _this.alignChatPosition();
        }, 100);
      } else if (data.type === 'call_status') {
        switch (data.status) {
          case 'in progress':
            window.callInProgress = true;
            Ember.set(this, 'callInProgress', true);
            break;
          case 'transferred':
          case 'completed':
            window.callInProgress = false;
            Ember.set(this, 'callInProgress', false);
            break;
        }
      }
    },
    fcContactEvent: function fcContactEvent() {
      var _this2 = this;

      /* jshint -W117 */
      fcAgentWidget.on('FS:routeMCRConctact', function (data) {
        /* jshint +W117 */
        _this2.routeMCRConctact(data.mcrContactId);
      });
    },
    routeMCRConctact: function routeMCRConctact(mcrId) {
      var _this3 = this;

      var aliase = this.get('s360Transformation').getAlias();
      var url = window.location.origin + '/' + aliase + '/sales/contacts/find_or_create_contact_from_mcr_id?contact_mcr_id=' + mcrId;
      this.get('s360Transformation').ajaxReq(url).then(function (response) {
        if (response && response.contact_id) {
          var contactUrl = window.location.origin + '/' + aliase + '/sales/contacts/' + response.contact_id;
          if (_this3.get('currentProduct').toLowerCase() === 'freshsales') {
            _this3.get('router').transitionTo('modules.contacts.show', response.contact_id);
          } else if (window.callInProgress) {
            _this3.callerModalAction(contactUrl);
          } else if (get(_this3, 'mkpInterface.isCTICallInProgress')) {
            window.open(contactUrl, '_blank');
          } else {
            window.location = contactUrl;
          }
        }
      });
    },
    omnibarInitializer: function omnibarInitializer() {
      var omnibarConfig = this.get('omnibarConfigJson') ? this.overwriteconfig(this.get('sideNavValues.freshid_config'), this.get('omnibarConfigJson')) : this.get('sideNavValues.freshid_config');
      Ember.set(this, 'omnibarConfigData', omnibarConfig);
      this.set('omnibarInitializeLoading', false);
    },
    freshChatInitializer: function freshChatInitializer(config) {
      var _this4 = this;

      var self = this;
      if (window.fcAgentWidget && config) {
        self.set('chatInitializeLoading', false);
        window.fcAgentWidget.init(config);
        // intialize events
        this.fcContactEvent();
        // this.fcOpenEvent();
        window.fcAgentWidget.on('widget:maximized', function () {
          Ember.$('.s360-chat').addClass('active');
          Ember.set(_this4, 'freshchatwidgetOpen', true);
          _this4.resetFcNotificationCount();
        });
        window.fcAgentWidget.on('widget:closed', function () {
          Ember.$('.s360-chat').removeClass('active');
          Ember.set(_this4, 'freshchatwidgetOpen', false);
        });
      } else if (!window.fcAgentWidget) {
        setTimeout(function () {
          self.set('chatInitializeLoading', false);
          _this4.freshChatInitializer(config);
        }, 500);
      }
    },

    // loading: true,
    freshcallerInitializer: function freshcallerInitializer(config) {
      var styleOptions = {
        left: ' left: 60px;',
        zindex: ' z-index: 9998;',
        height: ' height: 445px;',
        bottom: 'bottom: 150px;',
        width: ' width: 365px;'
      };
      var finalConfig = Object.assign(config, { styleOptions: styleOptions });
      var self = this;
      (function injectCaller() {
        if (window.FreshcallerWidget) {
          // self.removeCallerEvents();
          // post message injection
          window.addEventListener('message', self.widgetActionHandler.bind(self));
          // window.addEventListener('freshcaller-widget-ready', () => {
          // 	setTimeout(()=>{
          // 		set(self, 'callerLoading', false);
          // 	},1000);
          // });
          if (!window.FreshcallerWidgetInstance) {
            window.FreshcallerWidgetInstance = new window.FreshcallerWidget(finalConfig);
          }
        } else {
          setTimeout(function () {
            injectCaller();
          }, 500);
        }
      })();
    },
    toggleFreshchat: function toggleFreshchat() {
      if (this.get('freshchatwidgetOpen')) {
        /* jshint -W117 */
        fcAgentWidget.close();
        return;
      }
      this.alignChatPosition();
      fcAgentWidget.open();
      /* jshint +W117 */
    },

    /**
    * Opens crm/messaging/inbox
    * - When there is a call in progress, get the confirmation from user before proceeding
    * - This flow is applicable for all feeder products
    */
    openFDMessagingInbox: function openFDMessagingInbox() {
      var fdMessagingURL = get(this, 'fdMessagingURL');
      if (window.callInProgress) {
        this.callerModalAction(fdMessagingURL);
      } else if (get(this, 'mkpInterface.isCTICallInProgress')) {
        window.open(fdMessagingURL, '_blank');
      } else {
        window.location = fdMessagingURL;
      }
    },
    _handleOutsideClick: function _handleOutsideClick() {
      if (!window.FreshcallerWidgetInstance) {
        return;
      }
      var element = document.querySelector('#fc-widget');
      if (!element) {
        return;
      }
      var height = element.style.height;
      if (height !== '0px') {
        window.FreshcallerWidgetInstance.toggleDialer ? window.FreshcallerWidgetInstance.toggleDialer() : window.parent.postMessage({ type: 'hide_widget' }, '*');
      } else {
        window.parent.postMessage({ type: 'show_widget' }, '*');
      }
      return;
    },

    actions: {
      setApp: function setApp(app, value) {
        this.set(app, value);
        this.notifyPropertyChange(app);
      },
      onClickFreshChatIcon: function onClickFreshChatIcon() {
        var fdMessagingEnabled = get(this, 'fdMessagingEnabled');
        if (fdMessagingEnabled) {
          this.openFDMessagingInbox();
        } else {
          this.toggleFreshchat();
        }
      },
      toggleFreshcaller: function toggleFreshcaller() {
        var _this5 = this;

        this._handleOutsideClick();
        setTimeout(function () {
          _this5.alignChatPosition();
        }, 100);
      }
    }
  });
});