define('ember-crumbly/initializers/crumbly', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('component:bread-crumbs', 'applicationRoute', 'route:application');
  }

  exports['default'] = {
    name: 'crumbly',
    initialize: initialize
  };
});