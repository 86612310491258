define('ember-authority/helpers/has-privilege', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.hasPrivilege = hasPrivilege;

  function hasPrivilege(params, _ref) {
    var privilege = _ref.privilege;
    var user = _ref.user;

    var hash = params.hash || {};
    privilege = privilege || hash.privilege;
    user = user || hash.user;

    return user && user.get('abilities').indexOf(privilege) !== -1;
  }

  ;

  exports['default'] = _ember['default'].Helper.helper(hasPrivilege) || _ember['default'].Handlebars.makeBoundHelper(hasPrivilege);
});