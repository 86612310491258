define('ember-form/components/ui-select/component', ['exports', 'ember', 'ember-form/components/ui-select/template', 'ember-form/mixins/focus-field'], function (exports, _ember, _emberFormComponentsUiSelectTemplate, _emberFormMixinsFocusField) {
  'use strict';

  exports['default'] = _ember['default'].Select.extend(_emberFormMixinsFocusField['default'], {
    layout: _emberFormComponentsUiSelectTemplate['default'],
    classNames: ['form-control'],
    init: function init() {
      if (this.get('parentView.forAttribute')) {
        this.set('elementId', this.get('parentView.forAttribute'));
      }
      if (!this.get('optionValuePath')) {
        this.set('optionValuePath', 'content.value');
      }
      if (!this.get('optionLabelPath')) {
        this.set('optionLabelPath', 'content.label');
      }

      this._super(this);
    }
  });
});