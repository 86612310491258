define("ember-unified-navbar-360/templates/components/main-logo-workspace", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "unified360-valign");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "unified360-standard-loader-dimension unified360-hover-state unified360-opacity-0-5");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "main-logo main-workspace-logo unified360-valign");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el2 = dom.createElement("svg");
          dom.setAttribute(el2, "class", "unified360-icon switcher-icon");
          dom.setAttribute(el2, "viewBox", "0 0 32 32");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("use");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'onClick');
          morphs[1] = dom.createAttrMorph(element5, 'xlink:href', 'http://www.w3.org/1999/xlink');
          return morphs;
        },
        statements: [["attribute", "onClick", ["subexpr", "action", ["onIconClick", ["get", "selectedWorkspace.id", ["loc", [null, [7, 96], [7, 116]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [7, 118]]], 0, 0], 0, 0, 0, 0], ["attribute", "xlink:href", ["concat", ["#", ["get", "selectedWorkspace.icon", ["loc", [null, [9, 28], [9, 50]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 12
                },
                "end": {
                  "line": 37,
                  "column": 12
                }
              },
              "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              dom.setNamespace("http://www.w3.org/2000/svg");
              var el1 = dom.createElement("svg");
              dom.setAttribute(el1, "class", "unified360-icon");
              dom.setAttribute(el1, "viewBox", "0 0 32 32");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("use");
              dom.setAttributeNS(el2, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_check_small");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 6
              },
              "end": {
                "line": 40,
                "column": 6
              }
            },
            "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "menu-item-container");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el4 = dom.createElement("svg");
            dom.setAttribute(el4, "class", "unified360-icon");
            dom.setAttribute(el4, "viewBox", "0 0 32 32");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("use");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.setNamespace(null);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "menu-label");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element2, [1, 1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'onClick');
            morphs[1] = dom.createAttrMorph(element1, 'class');
            morphs[2] = dom.createAttrMorph(element3, 'xlink:href', 'http://www.w3.org/1999/xlink');
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
            morphs[4] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["attribute", "onClick", ["subexpr", "action", ["selectWorkspace", ["get", "workspace.id", ["loc", [null, [23, 47], [23, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [23, 61]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["menu-item ", ["subexpr", "if", [["subexpr", "s360-eql", [["get", "workspace.id", ["loc", [null, [24, 47], [24, 59]]], 0, 0, 0, 0], ["get", "selectedWorkspace.id", ["loc", [null, [24, 60], [24, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 37], [24, 81]]], 0, 0], "active-menu", ""], [], ["loc", [null, [24, 32], [24, 100]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "xlink:href", ["concat", ["#", ["get", "workspace.icon", ["loc", [null, [27, 36], [27, 50]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "workspace.name", ["loc", [null, [30, 16], [30, 34]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "s360-eql", [["get", "workspace.id", ["loc", [null, [33, 28], [33, 40]]], 0, 0, 0, 0], ["get", "selectedWorkspace.id", ["loc", [null, [33, 41], [33, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 18], [33, 62]]], 0, 0]], [], 0, null, ["loc", [null, [33, 12], [37, 19]]]]],
          locals: ["workspace"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 44,
              "column": 0
            }
          },
          "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "class", "unified360-icon unified360-valign unified360-workspace-ellipsis-vertical");
          dom.setAttribute(el1, "viewBox", "0 0 32 32");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("use");
          dom.setAttributeNS(el2, "http://www.w3.org/1999/xlink", "xlink:href", "#s360_ellipsis_vertical");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          dom.setNamespace(null);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "unified360-workspace-logo-submenus-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "unified360-workspace-logo-submenus-dropdown");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "class", "unified360-p-8 unified360-flex-container");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "availableWorkspace", ["loc", [null, [22, 14], [22, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 6], [40, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "modules/ember-unified-navbar-360/templates/components/main-logo-workspace.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "unified360-simple-main-logo");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "loading", ["loc", [null, [2, 8], [2, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [12, 9]]]], ["content", "yield", ["loc", [null, [14, 0], [14, 9]]], 0, 0, 0, 0], ["block", "if", [["get", "showSwitcher", ["loc", [null, [15, 6], [15, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [15, 0], [44, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});