define('ember-inject-script/utils/inject-script', ['exports'], function (exports) {
  'use strict';

  exports['default'] = injectScript;

  function injectScript(src) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
});