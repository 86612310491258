define('ember-form/components/ui-field/component', ['exports', 'ember', 'ember-form/components/ui-field/template', 'ember-form/components/ui-form/component'], function (exports, _ember, _emberFormComponentsUiFieldTemplate, _emberFormComponentsUiFormComponent) {
  'use strict';

  var computed = _ember['default'].computed;

  // EMBER::TODO:
  // To Move FieldType specific logic to the related component.js
  // Eg: Instead of handling set label logic && computed properties
  // here better move those thinks to label/component.js will make sense

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberFormComponentsUiFieldTemplate['default'],
    classNames: 'ui-field',
    isRequired: computed({
      get: function get() {
        var hasObject = this.get('formValidations')[this.get('name')];
        return hasObject && hasObject.hasOwnProperty('presence') ? true : false;
      }
    }),

    setLabel: (function () {
      if (!this.hasOwnProperty('label')) {
        return this.set('label', this.get('for'));
      } else if (this.hasOwnProperty('label') && !this.get('label')) {
        // If Label set to false then label won't render label
        return this.set('label', null);
      }
    }).on('init'),

    setupBindings: (function () {
      var fieldProp = this.get('for');
      this.bindValue = _ember['default'].Binding.from('formModel.' + fieldProp).to('value').connect(this);
    }).on('init'),

    setupField: (function () {
      var type = this.get('type');
      var templateValue = ['text', 'number', 'password'].contains(type) ? 'isBasicInput' : ('is_' + type).camelize();
      _ember['default'].defineProperty(this, templateValue, _ember['default'].computed({
        get: function get() {
          return true;
        }
      }));
    }).on('init'),

    skipLabel: computed.oneWay('isCheckbox', 'isRadio'),

    removeBindings: (function () {
      this.bindValue.disconnect(this);
    }).on('willDestroyElement'),

    id: computed('for', {
      get: function get() {
        if (this.get('label')) {
          return this.get('for') + '_' + this.get('elementId');
        }
        return this.get('for');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    name: computed.alias('for'),
    labelClass: 'control-label',
    inputClass: 'form-control',
    placeholder: null,
    form: computed({
      get: function get() {
        return this.nearestOfType(_emberFormComponentsUiFormComponent['default']);
      }
    }),
    formModel: computed.alias('form.for'),
    formValidations: computed.alias('formModel.validations'),
    formValidationErrors: computed.alias('formModel.errors'),
    hasValidation: computed('name', 'form', 'form.startValidate', 'errors', {
      get: function get() {
        if (Object.keys(this.get('formValidations')).length !== 0) {
          return this.get('name') in this.get('formValidations') && this.get('form.startValidate') && this.get('errors').length > 0;
        }
      }
    }),

    classNameBindings: ['hasValidation:has-error', 'isRequired:form-field-required'],

    errors: computed('value', {
      get: function get() {
        var name = this.get('for');
        return this.get('formValidationErrors.' + name);
      }
    }),

    sanitizedName: computed('name', {
      get: function get() {
        return this.sanitizeAttr(this.get('name'), 'name');
      }
    }),
    sanitizedID: computed('id', {
      get: function get() {
        return this.sanitizeAttr(this.get('id'));
      }
    }),
    sanitizedLabel: computed('label', {
      get: function get() {
        return this.sanitizeAttr(this.get('label'), 'label');
      }
    }),
    sanitizeAttr: function sanitizeAttr(value, attrName) {
      if (attrName === 'name') {
        return value.split('.').map(function (prop, idx) {
          if (idx === 0) {
            return prop.underscore();
          }
          return '[' + prop.underscore() + ']';
        }).join('');
      } else if (attrName === 'label') {
        return this.get('label') === this.get('for') ? value.underscore() : value;
      } else {
        return value.underscore();
      }
    }
  });
});