define('ember-unified-navbar-360/components/each-menu-item-expanded', ['exports', 'ember-unified-navbar-360/templates/components/each-menu-item-expanded'], function (exports, _emberUnifiedNavbar360TemplatesComponentsEachMenuItemExpanded) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberUnifiedNavbar360TemplatesComponentsEachMenuItemExpanded['default'],
    actions: {
      callerModalAction: function callerModalAction(url) {
        this.callerModalAction(url);
      }
    }
  });
});