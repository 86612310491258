define('ember-unified-navbar-360/persistence-store/migrations/index', ['exports', 'ember-unified-navbar-360/persistence-store/migrations/2023051401_create_store'], function (exports, _emberUnifiedNavbar360PersistenceStoreMigrations2023051401_create_store) {
  'use strict';

  var isNone = Ember.isNone;

  var MIGRATIONS = [{
    name: '2023051401_create_store',
    description: 'Added migration log table and sprite config table',
    migration: _emberUnifiedNavbar360PersistenceStoreMigrations2023051401_create_store['default']
  }];

  var executeMigrations = function executeMigrations(db, transaction, migrations, executedMigrations) {
    migrations.forEach(function (migrationSpec) {
      if (isNone(executedMigrations[migrationSpec.name])) {
        migrationSpec.migration(db);

        var migrationLogStore = transaction.objectStore('migration_log');

        migrationLogStore.add({
          name: migrationSpec.name,
          description: migrationSpec.description
        });
      }
    });
  };

  exports['default'] = {
    dbVersion: MIGRATIONS.length,
    migrate: function migrate(request) {
      var db = request.result;
      var transaction = request.transaction;
      var migrations = MIGRATIONS.map(function (m) {
        return m;
      });

      if (db.objectStoreNames.contains('migration_log')) {
        var migrationLogStore = transaction.objectStore('migration_log');
        var _request = migrationLogStore.getAll();

        _request.onsuccess = function () {
          var executedMigrations = {};

          _request.result.forEach(function (migrationDetails) {
            executedMigrations[migrationDetails.name] = migrationDetails.description;
          });
          executeMigrations(db, transaction, migrations, executedMigrations);
        };

        _request.onerror = function () {
          executeMigrations(db, transaction, migrations, {});
        };
      } else {
        executeMigrations(db, transaction, migrations, {});
      }
    }
  };
});