define('ember-unified-navbar-360/services/video-player', ['exports'], function (exports) {
  'use strict';

  var Evented = Ember.Evented;

  exports['default'] = Ember.Service.extend(Evented, {
    openVideoModal: function openVideoModal(videoProperties) {
      this.trigger('open-video-modal', videoProperties);
    },
    closeVideoModal: function closeVideoModal() {
      this.trigger('close-video-modal');
    }
  });
});